export const GENRE_CATEGORY_IDS =
  // QA를 위해 임시로 프로덕션인지 판단을 NETWORK_HOST_GRAPHQL를 사용해서 함
  process.env.NETWORK_HOST_GRAPHQL === 'prime-backoffice.prod.acon3d.com' ? [390, 389, 378, 387, 388, 391] : [396, 395, 392, 393, 394, 397];

export const GENRE_OPTIONS: { id: number; code: string; name: string }[] = [
  {
    id: GENRE_CATEGORY_IDS[0],
    code: GENRE_CATEGORY_IDS[0].toString(),
    name: 'document.genre.byName.modern.title',
  },
  {
    id: GENRE_CATEGORY_IDS[1],
    code: GENRE_CATEGORY_IDS[1].toString(),
    name: 'document.genre.byName.oriental.title',
  },
  {
    id: GENRE_CATEGORY_IDS[2],
    code: GENRE_CATEGORY_IDS[2].toString(),
    name: 'document.genre.byName.romance.title',
  },
  {
    id: GENRE_CATEGORY_IDS[3],
    code: GENRE_CATEGORY_IDS[3].toString(),
    name: 'document.genre.byName.action.title',
  },
  {
    id: GENRE_CATEGORY_IDS[4],
    code: GENRE_CATEGORY_IDS[4].toString(),
    name: 'document.genre.byName.sf.title',
  },
  {
    id: GENRE_CATEGORY_IDS[5],
    code: GENRE_CATEGORY_IDS[5].toString(),
    name: 'document.genre.byName.etc.title',
  },
];
