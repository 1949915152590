import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import { Title } from 'acon-mui/components/Board';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import GenreRow from './GenreRow';

export default () => {
  // 번역도구
  const { t } = useTranslation();
  // URL 파생 정보
  const { isDisp, status, setCategories } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    book: state.book,
    categoryGenre: state.categoryGenre,
    categories: state.categories,
    setCategories: state.setCategories,
    setBook: state.setBook,
  }));

  const originalData = useOriginalDocumentStore((state) => ({
    categories: state.categories,
  }));

  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setCategories(originalData?.categories);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };

  return (
    <Box>
      <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('document.genre.title'))}>
        {t('document.genre.setGenre')}
      </Title>
      <GenreRow disabled={disabled} />
    </Box>
  );
};
