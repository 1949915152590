import { BusinessTypeEnum, PartnerTypeEnum } from './type';

export const UserTypes = [
  {
    label: '개인',
    value: PartnerTypeEnum.Personal,
  },
  {
    label: '사업자',
    value: PartnerTypeEnum.Business,
  },
];

export const BusinessTypes = [
  {
    label: '과세 사업자 (일반과세자)',
    value: BusinessTypeEnum.Taxable,
  },
  {
    label: '과세 사업자 (간이과세자_세금계산서발행가능)',
    value: BusinessTypeEnum.InvoiceIssuable,
  },
  {
    label: '과세 사업자 (간이과세자_세금계산서발행불가)',
    value: BusinessTypeEnum.NonInvoiceable,
  },
  {
    label: '면세 사업자',
    value: BusinessTypeEnum.DutyFree,
  },
];
