import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

interface CategorySelectProps {
  label: string;
  value: string | undefined;
  options: { id: number; code: string; name: string }[] | undefined;
  disabled: boolean;
  loading: boolean;
  onChange: (e: SelectChangeEvent<string>) => void;
}

const GenreSelect: React.FC<CategorySelectProps> = ({ label, value, options, disabled, loading, onChange }) => {
  return (
    <FormControl
      size="small"
      sx={{
        '& label.Mui-focused': { display: 'none' },
        ...(value && { '& label': { display: 'none' } }),
      }}
    >
      <InputLabel disableAnimation={false}>{label}</InputLabel>
      <Select
        value={value || ''}
        disabled={disabled || loading || !(options && options.length > 0)}
        onChange={(e) => onChange(e)}
        sx={{ borderRadius: 0.5, '&.Mui-disabled fieldset': { borderStyle: 'dotted' } }}
      >
        <MenuItem value={''} disabled sx={{ display: 'none' }} />
        {options?.map((x) => (
          <MenuItem key={x.id} value={x.code}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GenreSelect;
