import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { statusState } from './SignUp.atoms';
import { Signup } from './style';
import Progress from './Progress';
import Form from './page/Form';

export default function SignUpForm() {
  const { i18n } = useTranslation();
  const [status] = useRecoilState(statusState);

  return (
    <Signup data-status={status} status={status} lang={i18n.language}>
      <Progress />
      <Form />
    </Signup>
  );
}
