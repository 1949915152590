import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export enum AcceptStatusEnum {
  Approve = 'approve',
  Leave = 'leave',
  Reject = 'reject',
  Request = 'request',
}

export type Acon3dBanner = {
  __typename?: 'Acon3dBanner';
  /** 배너 등록일자 (복수 배너시 정렬용) */
  created?: Maybe<Scalars['DateTime']>;
  /** 상품 링크 */
  href?: Maybe<Scalars['String']>;
  /** 배너 ID */
  id?: Maybe<Scalars['Int']>;
  /** 배너 이미지 주소 */
  imageUrl?: Maybe<Scalars['String']>;
  /** 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 표시 타입 */
  type?: Maybe<Scalars['String']>;
};

export type Acon3dBook = {
  __typename?: 'Acon3dBook';
  id?: Maybe<Scalars['Int']>;
  /** isbn */
  isbn?: Maybe<Scalars['String']>;
};

export type Acon3dBrand = {
  __typename?: 'Acon3dBrand';
  /** 브랜드 코드 */
  code?: Maybe<Scalars['String']>;
  /** 브랜드 ID */
  id?: Maybe<Scalars['Int']>;
  /** 브랜드 이름(번역) */
  name?: Maybe<Scalars['String']>;
};

export type Acon3dCategory = {
  __typename?: 'Acon3dCategory';
  /** 고도 카테고리 코드 */
  code?: Maybe<Scalars['String']>;
  /** 카테고리 ID */
  id?: Maybe<Scalars['Int']>;
  /** 카테고리 이름(번역) */
  name?: Maybe<Scalars['String']>;
};

export type Acon3dFunding = {
  __typename?: 'Acon3dFunding';
  deliveryDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  goalAmount: Scalars['Int'];
  soldAmount: Scalars['Int'];
  soldVolume: Scalars['Int'];
  startDate: Scalars['DateTime'];
  status: FundingStatus;
};

export type Acon3dLinkedProduct = {
  __typename?: 'Acon3dLinkedProduct';
  /** 확장자 */
  extensions: Array<Scalars['String']>;
  /** 상품 번호 */
  id: Scalars['Int'];
};

export type Acon3dModelConfig = {
  __typename?: 'Acon3dModelConfig';
  /** 모델컨피그 ID */
  id?: Maybe<Scalars['Int']>;
  /** 이름(번역) */
  name?: Maybe<Scalars['String']>;
};

export type Acon3dPackage = {
  __typename?: 'Acon3dPackage';
  children: Array<Acon3dProduct>;
  parent?: Maybe<Acon3dProduct>;
  type: Scalars['String'];
};

export type Acon3dProduct = {
  __typename?: 'Acon3dProduct';
  /** 상품의 응용프로그램들 */
  applications?: Maybe<Array<ApplicationEntity>>;
  /** 상품카드 뱃지 이름들(번역) */
  badgeNames: Array<Maybe<Scalars['String']>>;
  banners?: Maybe<Array<Acon3dBanner>>;
  /** 책 상품 정보 */
  book?: Maybe<Acon3dBook>;
  brand: Acon3dBrand;
  categories?: Maybe<Array<Acon3dCategory>>;
  category?: Maybe<CategoryOutputV4>;
  content?: Maybe<Scalars['String']>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 상품상세 이미지 URL */
  detailImageUrl?: Maybe<Scalars['String']>;
  /** 세트 상품을 허브에 정보 전달해주기 위한 docId */
  docId?: Maybe<Scalars['String']>;
  /** 확장자 */
  extensions: Array<Scalars['String']>;
  /** 펀딩 정보. null이면 펀딩 대상이 아님 */
  funding?: Maybe<Acon3dFunding>;
  /** 유저가 상품을 주문한 적이 있는지 여부 */
  hasOrdered?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** ACON ONLY 여부 */
  isAconOnly: Scalars['Boolean'];
  /** 성인상품 여부 */
  isAdultOnly: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  /** 클래스 상품 여부 */
  isLecture: Scalars['Boolean'];
  /** 세일 여부 */
  isOnSale: Scalars['Boolean'];
  /** 언어 */
  languageCode: LanguageCodeEnum;
  licenses: Array<Maybe<AssetLicensePublicOutput>>;
  /** 연관 상품, product_related 테이블 */
  linkedProducts?: Maybe<Array<Acon3dLinkedProduct>>;
  /** 대표 이미지 URL */
  mainImageUrl: Scalars['String'];
  modelConfigs?: Maybe<Array<Acon3dModelConfig>>;
  /** 리스트 노출 여부 */
  onDisplay: Scalars['Boolean'];
  /** 판매 여부 */
  onSale: Scalars['Boolean'];
  /** 판매 시작일 */
  onSaleFrom?: Maybe<Scalars['DateTime']>;
  /** 주문 수(인기순 정렬) */
  orderCount: Scalars['Int'];
  /** 세트 상품 정보. null이면 세트 상품에 해당되지 않음 */
  packages?: Maybe<Array<Maybe<Acon3dPackage>>>;
  /** 상품 정가 */
  price: Scalars['Int'];
  /** 진행 중인 프로모션 정보 */
  promotionEndDate?: Maybe<Scalars['DateTime']>;
  promotionInfo?: Maybe<PromotionOutput>;
  /** 우주최저가/혜성특가 등의 특수 프로모션에 해당될 경우 요청하는 필드 */
  promotions?: Maybe<Array<Acon3dPromotion>>;
  /** 상품 정가 (개정) */
  regularPrice?: Maybe<Scalars['Int']>;
  /** 출시일 */
  released?: Maybe<Scalars['DateTime']>;
  /** 리뷰 수 */
  reviewCount: Scalars['Int'];
  /** 상품 할인가 */
  salePrice: Scalars['Int'];
  /** 검색 일치 점수 */
  score?: Maybe<Scalars['Float']>;
  /** 서브 이미지 URL */
  subImageUrl: Scalars['String'];
  /** 번역된 상품명 */
  title: Scalars['String'];
  /** 갱신 일자 */
  updated?: Maybe<Scalars['DateTime']>;
  /** 조회 수 */
  viewCount: Scalars['Int'];
};

export type Acon3dPromotion = {
  __typename?: 'Acon3dPromotion';
  /** 프로모션 뱃지(번역본) */
  badges?: Maybe<Array<Acon3dPromotionBadge>>;
  /** 현재 차수 */
  currentRound?: Maybe<Scalars['Int']>;
  /** 프로모션 아이디(acon.promotion) */
  id: Scalars['Int'];
  /** 남은 일수 */
  remainingDays?: Maybe<Scalars['Int']>;
  /** 혜택 라운드별 상세 정보 */
  rounds?: Maybe<Array<Acon3dPromotionRound>>;
  /** 시작일자(예정 포함), acon.promotion.reserve_date */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type Acon3dPromotionBadge = {
  __typename?: 'Acon3dPromotionBadge';
  /** 프로모션 뱃지 내용 */
  content?: Maybe<Scalars['String']>;
  /** 프로모션 뱃지 ID */
  id?: Maybe<Scalars['Int']>;
  /** 프로모션 뱃지 언어 */
  languageCode?: Maybe<LanguageCodeEnum>;
  /** 프로모션 뱃지 타입: point | coupon | null */
  type?: Maybe<Scalars['String']>;
};

export type Acon3dPromotionRound = {
  __typename?: 'Acon3dPromotionRound';
  /** 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  roundNo?: Maybe<Scalars['Int']>;
  /** 할인가 */
  salePrice?: Maybe<Scalars['Int']>;
  /** 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type AconNotification = {
  __typename?: 'AconNotification';
  /** acon notification 고유번호 */
  id: Scalars['Int'];
  /** 고도몰 회원번호 */
  memNo: Scalars['Int'];
  /** notification message */
  message?: Maybe<Scalars['String']>;
  /** notification type */
  type: Scalars['String'];
};

export type AconSnsMemberInput = {
  accessToken: Scalars['String'];
  /** y or n */
  agreementInfoFl?: InputMaybe<Scalars['String']>;
  cellPhone?: InputMaybe<Scalars['String']>;
  countryName?: InputMaybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  /** y or n */
  maillingFl?: InputMaybe<Scalars['String']>;
  /** y or n */
  privateApprovalFl?: InputMaybe<Scalars['String']>;
  relatedJob?: InputMaybe<Array<Scalars['String']>>;
  /** y or n */
  smsFl?: InputMaybe<Scalars['String']>;
  snsType: SnsTypeEnum;
};

export type AdminMultiDocument = {
  __typename?: 'AdminMultiDocument';
  assetId?: Maybe<Scalars['Int']>;
  brandName: Scalars['String'];
  /** 최종 상태변환 일시 */
  changed?: Maybe<Scalars['DateTime']>;
  game?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  imgsrc?: Maybe<Scalars['Boolean']>;
  isAdultOnly?: Maybe<Scalars['Boolean']>;
  lecture?: Maybe<Scalars['Boolean']>;
  /** 아직 담당자가 지정되지 않았을 수 있으므로 nullable */
  manager?: Maybe<Scalars['String']>;
  /** 노출 시작 시각. null이면 미노출 */
  onSaleFrom?: Maybe<Scalars['DateTime']>;
  /** 검토/수정요청 일시 */
  requested?: Maybe<Scalars['DateTime']>;
  status: Document_Status;
  titles: Array<DocumentTitle>;
  /** 전체 다큐먼트 수 */
  total: Scalars['Int'];
  webtoon?: Maybe<Scalars['Boolean']>;
};

export type AdminSingleDocumentInput = {
  applications?: InputMaybe<Array<Scalars['Int']>>;
  /** 고도몰상품번호 */
  assetId?: InputMaybe<Scalars['Int']>;
  /** 전자책 상품의 정보 */
  book?: InputMaybe<DocumentBookInput>;
  /** 브랜드 ID */
  brandId?: InputMaybe<Scalars['Int']>;
  categories?: InputMaybe<Array<DocumentCategoryInput>>;
  categoryGenre?: InputMaybe<Array<DocumentCategoryInput>>;
  changeHistories?: InputMaybe<Array<InputMaybe<DocumentChangeHistoryInput>>>;
  completed?: InputMaybe<Scalars['DateTime']>;
  contentBody?: InputMaybe<Scalars['String']>;
  contentHead?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<DocumentCopyrightInput>;
  created?: InputMaybe<Scalars['DateTime']>;
  extensions?: InputMaybe<Array<Scalars['Int']>>;
  fileComponents?: InputMaybe<Array<InputMaybe<DocumentFileComponentInput>>>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  fileUpdates?: InputMaybe<Array<InputMaybe<DocumentFileUpdateInput>>>;
  game?: InputMaybe<Scalars['Boolean']>;
  /** 다큐먼트 ID */
  id: Scalars['String'];
  imgsrc?: InputMaybe<Scalars['Boolean']>;
  /** 산업분야 목록; id만; */
  industries?: InputMaybe<Array<DocumentIndustryInput>>;
  isAdultOnly?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  language?: InputMaybe<Language_Code>;
  lecture?: InputMaybe<Scalars['Boolean']>;
  /** 라이선스 옵션 목록 */
  licenseOptions?: InputMaybe<Array<LicenseTypeOptionInput>>;
  mCompleted?: InputMaybe<Scalars['DateTime']>;
  mRequested?: InputMaybe<Scalars['DateTime']>;
  mainCoordination?: InputMaybe<ImageCropCoordinationInput>;
  mainImage?: InputMaybe<Scalars['String']>;
  mainOrigin?: InputMaybe<Scalars['String']>;
  memos?: InputMaybe<Array<InputMaybe<DocumentMemoInput>>>;
  message?: InputMaybe<Scalars['String']>;
  originalLanguage?: InputMaybe<Language_Code>;
  price?: InputMaybe<Scalars['Float']>;
  priceChanged?: InputMaybe<Scalars['DateTime']>;
  requested?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Document_Status>;
  subCoordination?: InputMaybe<ImageCropCoordinationInput>;
  subImage?: InputMaybe<Scalars['String']>;
  subOrigin?: InputMaybe<Scalars['String']>;
  /** 태그 목록; id만; */
  tags?: InputMaybe<Array<DocumentTagInput>>;
  title?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  webtoon?: InputMaybe<Scalars['Boolean']>;
};

export type AdminSingleDocumentOutput = {
  __typename?: 'AdminSingleDocumentOutput';
  /** 응용프로그램 목록 */
  applications: Array<Scalars['Int']>;
  /** 고도몰상품번호 */
  assetId?: Maybe<Scalars['Int']>;
  book?: Maybe<DocumentBookOutput>;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Int']>;
  brandNames?: Maybe<Array<Maybe<SingleDocumentBrandName>>>;
  /** 카테고리 정보 */
  categories: Array<CategoryOutputV4>;
  categoryGenre?: Maybe<Scalars['String']>;
  changeHistories?: Maybe<Array<Maybe<DocumentChangeHistoryOutput>>>;
  completed?: Maybe<Scalars['DateTime']>;
  contentBody?: Maybe<Scalars['String']>;
  contentHead?: Maybe<Scalars['String']>;
  copyright?: Maybe<DocumentCopyrightOutput>;
  created?: Maybe<Scalars['DateTime']>;
  /** 확장자 목록 */
  extensions: Array<Scalars['Int']>;
  fileComponents?: Maybe<Array<Maybe<DocumentFileComponentOutput>>>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  fileUpdates?: Maybe<Array<Maybe<DocumentFileUpdateOutput>>>;
  game?: Maybe<Scalars['Boolean']>;
  /** 다큐먼트 ID */
  id: Scalars['String'];
  imgsrc?: Maybe<Scalars['Boolean']>;
  /** 산업분야 목록 */
  industries: Array<IndustryOutput>;
  isAdultOnly?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  language?: Maybe<Language_Code>;
  lecture?: Maybe<Scalars['Boolean']>;
  /** 라이선스 옵션 목록 */
  licenseOptions?: Maybe<Array<LicenseTypeOptionOutput>>;
  mCompleted?: Maybe<Scalars['DateTime']>;
  mRequested?: Maybe<Scalars['DateTime']>;
  mainCoordination?: Maybe<ImageCropCoordinationOutput>;
  mainImage?: Maybe<Scalars['String']>;
  mainOrigin?: Maybe<Scalars['String']>;
  memos?: Maybe<Array<Maybe<DocumentMemoOutput>>>;
  message?: Maybe<Scalars['String']>;
  originalLanguage?: Maybe<Language_Code>;
  price?: Maybe<Scalars['Float']>;
  priceChanged?: Maybe<Scalars['DateTime']>;
  promotion?: Maybe<OpenrunPromotionAdminOutput>;
  requested?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Document_Status>;
  subCoordination?: Maybe<ImageCropCoordinationOutput>;
  subImage?: Maybe<Scalars['String']>;
  subOrigin?: Maybe<Scalars['String']>;
  /** 태그 목록 */
  tags: Array<TagOutput>;
  title?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  webtoon?: Maybe<Scalars['Boolean']>;
};

export type AdminUpdatePartnerBrandInput = {
  brandId?: InputMaybe<Scalars['Int']>;
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  godoBrandCd?: InputMaybe<Scalars['String']>;
  isExistBrand?: InputMaybe<Scalars['Boolean']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  created?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type AdminUserModel = {
  __typename?: 'AdminUserModel';
  /** 생성 일자 */
  created?: Maybe<Scalars['DateTime']>;
  /** 관리자 이메일 */
  email: Scalars['String'];
  /** 고유 ID */
  id: Scalars['String'];
  /** 관리자 명 */
  name: Scalars['String'];
  /** 수정 일자 */
  updated?: Maybe<Scalars['DateTime']>;
};

export type ApplicationEntity = {
  __typename?: 'ApplicationEntity';
  /** 응용프로그램 ID */
  id: Scalars['Int'];
  /** 응용프로그램 이름 */
  name: Scalars['String'];
};

export type ApplicationOutput = {
  __typename?: 'ApplicationOutput';
  /** 응용프로그램 갯수; 일반적인 쿼리에서는 요청할 일이 없으며, query productApplications()에서 사용 */
  count: Scalars['Int'];
  /** 응용프로그램 ID */
  id: Scalars['Int'];
  /** 응용프로그램 이름 */
  name: Scalars['String'];
};

export type ApplicationPaginatedOutput = {
  __typename?: 'ApplicationPaginatedOutput';
  data: Array<ApplicationOutput>;
  pagination: PaginationOutput;
};

export type ApplyItems = {
  __typename?: 'ApplyItems';
  /** 혜택 적용 설정 값 */
  value?: Maybe<Scalars['String']>;
};

export type ApplyType = {
  __typename?: 'ApplyType';
  /** 혜택 적용 설정 코드 */
  code?: Maybe<Scalars['String']>;
  /** 혜택 적용 설정 코드 ID */
  id?: Maybe<Scalars['Float']>;
  /** 혜택 적용 설정 코드 명 */
  name?: Maybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  brand: BrandEntity;
  categoryGenre: Scalars['String'];
  document?: Maybe<SingleDocumentOutput>;
  extensions: Array<Scalars['Int']>;
  funding?: Maybe<AssetFunding>;
  i18n?: Maybe<AssetI18n>;
  i18ns: Array<AssetI18n>;
  id: Scalars['Int'];
  isAdultOnly: Scalars['Boolean'];
  onSaleFrom?: Maybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  salePrice: Scalars['Float'];
  title: Scalars['String'];
};

export type AssetApplicationOutput = {
  __typename?: 'AssetApplicationOutput';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AssetCategoryOutput = {
  __typename?: 'AssetCategoryOutput';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AssetExtensionOutput = {
  __typename?: 'AssetExtensionOutput';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AssetFunding = {
  __typename?: 'AssetFunding';
  deliveryDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  goalAmount: Scalars['Int'];
  soldAmount: Scalars['Int'];
  soldVolume: Scalars['Int'];
  startDate: Scalars['DateTime'];
  status: FundingStatus;
};

export type AssetFundingInput = {
  assetId: Scalars['Int'];
  /** 상품 전달일 */
  deliveryDate: Scalars['DateTime'];
  /** 펀딩 종료일 */
  endDate: Scalars['DateTime'];
  /** 펀딩 목표 금액 */
  goalAmount: Scalars['Int'];
  /** 펀딩 시작일 */
  startDate: Scalars['DateTime'];
};

export type AssetI18n = {
  __typename?: 'AssetI18n';
  copyright: DocumentCopyrightOutput;
  fileComponents: Array<AssetI18nFileComponent>;
  fileUpdates: Array<AssetI18nFileUpdate>;
  language: Language_Code;
  title: Scalars['String'];
};

export type AssetI18nFileComponent = {
  __typename?: 'AssetI18nFileComponent';
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
};

export type AssetI18nFileUpdate = {
  __typename?: 'AssetI18nFileUpdate';
  date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AssetI18nOutput = {
  __typename?: 'AssetI18nOutput';
  contentBody?: Maybe<Scalars['String']>;
  contentHead?: Maybe<Scalars['String']>;
  copyright: DocumentCopyrightOutput;
  fileComponents: Array<AssetI18nFileComponent>;
  keywords?: Maybe<Array<Scalars['String']>>;
  language: Scalars['String'];
  title: Scalars['String'];
};

export type AssetIndustryOutput = {
  __typename?: 'AssetIndustryOutput';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AssetLicenseOutput = {
  __typename?: 'AssetLicenseOutput';
  id: Scalars['Int'];
  licenseTypeId: Scalars['Int'];
  price: Scalars['Float'];
};

export type AssetLicensePublicOutput = {
  __typename?: 'AssetLicensePublicOutput';
  /** 라이선스 타입 코드 */
  code: Scalars['String'];
  /** 고유 아이디 */
  id: Scalars['Int'];
  /** 라이선스 타입 아이디 */
  licenseTypeId: Scalars['Int'];
  /** 라이선스의 가격 */
  price: Scalars['Int'];
  /** 해당 라이선스 사용 가능 수. 0 인 경우 무제한 */
  usableCount: Scalars['Int'];
};

export type AssetOutput = {
  __typename?: 'AssetOutput';
  applications: Array<AssetApplicationOutput>;
  brandId: Scalars['Int'];
  categories: Array<AssetCategoryOutput>;
  categoryGenre: Scalars['String'];
  created: Scalars['DateTime'];
  extensions: Array<AssetExtensionOutput>;
  fileName: Scalars['String'];
  filePath: Scalars['String'];
  game?: Maybe<Scalars['Boolean']>;
  i18n: AssetI18nOutput;
  id: Scalars['Int'];
  imgsrc?: Maybe<Scalars['Boolean']>;
  industries: Array<AssetIndustryOutput>;
  isAdultOnly: Scalars['Boolean'];
  language: Language_Code;
  lecture?: Maybe<Scalars['Boolean']>;
  licenses: Array<AssetLicenseOutput>;
  mainImage: Scalars['String'];
  onSaleFrom?: Maybe<Scalars['DateTime']>;
  orderCount: Scalars['Int'];
  price: Scalars['Float'];
  promotionEnd?: Maybe<Scalars['DateTime']>;
  promotionName?: Maybe<Scalars['String']>;
  promotionStart?: Maybe<Scalars['DateTime']>;
  released?: Maybe<Scalars['DateTime']>;
  salePrice: Scalars['Float'];
  subImage: Scalars['String'];
  tags: Array<AssetTagOutput>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
  webtoon?: Maybe<Scalars['Boolean']>;
};

export type AssetTagOutput = {
  __typename?: 'AssetTagOutput';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AuthType = {
  __typename?: 'AuthType';
  type: Scalars['String'];
};

export enum Brands_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Brands_Input_Sort_Key {
  CreatedAt = 'CREATED_AT',
}

export enum Brand_Output_Brand_Vip_Type {
  Vip = 'VIP',
  Vvip = 'VVIP',
}

export enum Brand_Output_Update_Output_Type {
  Acon3DExclusive = 'ACON3D_EXCLUSIVE',
  Discount = 'DISCOUNT',
  Funding = 'FUNDING',
  NewProduct = 'NEW_PRODUCT',
  Openrun = 'OPENRUN',
  TwinkleSale = 'TWINKLE_SALE',
  VipOrVvip = 'VIP_OR_VVIP',
}

export type BadgeI18n = {
  __typename?: 'BadgeI18n';
  /** 언어 코드 */
  lang: Scalars['String'];
  /** language table 고유번호 */
  langId?: Maybe<Scalars['Float']>;
  /** 뱃지 내용 */
  text: Scalars['String'];
};

export type BadgeI18nInput = {
  /** 언어 코드 */
  lang: Scalars['String'];
  /** 뱃지 내용 */
  text: Scalars['String'];
};

export type BadgeI18nModel = {
  __typename?: 'BadgeI18nModel';
  /** badge table 고유번호 */
  badgeId: Scalars['Float'];
  /** 뱃지 고유 ID */
  id: Scalars['Float'];
  /** language table 고유번호 */
  langId: Scalars['Float'];
  language?: Maybe<Language>;
  /** 뱃지 내용 */
  text: Scalars['String'];
};

export type BadgeProductModel = {
  __typename?: 'BadgeProductModel';
  /** badge table 고유번호 */
  badgeId: Scalars['Float'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['String'];
  /** 뱃지 고유 ID */
  id: Scalars['Float'];
};

export type BadgeReturn = {
  __typename?: 'BadgeReturn';
  /** 뱃지 노출날짜 : 끝날짜 */
  endDate: Scalars['DateTime'];
  /** 고도몰 상품 번호 배열 */
  godoGoodsNos: Array<Scalars['String']>;
  /** badge table 고유번호 */
  id?: Maybe<Scalars['Float']>;
  /** 자동 생성 여부 */
  isAutoCreate?: Maybe<Scalars['Boolean']>;
  /** 뱃지 노출날짜 : 시작날짜 */
  startDate: Scalars['DateTime'];
  /** 상태 */
  status?: Maybe<Scalars['String']>;
  /** 상품 뱃지 문구 */
  texts: Array<BadgeI18n>;
};

export type BandBanner = {
  __typename?: 'BandBanner';
  /** 배경 색 */
  backgroundColor: Scalars['String'];
  /** 닫기 색 */
  closeColor: Scalars['String'];
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 띠 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 띠 배너 클릭시 새창 여부 */
  isBlankHref: Scalars['Boolean'];
  /** 띠 배너 종료 노출 타입 */
  isClosedShow: BandBannerClosed;
  /** 배너를 표시할 다국어몰 */
  language?: Maybe<Language>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 띠 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 띠 배너 타입 */
  type?: Maybe<BandBannerType>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type BandBannerClosed = {
  __typename?: 'BandBannerClosed';
  /** 띠 배너 종료 노출 타입 코드 */
  code: Scalars['String'];
  /** 띠 배너 종료 노출 타입 ID */
  id: Scalars['Float'];
  /** 띠 배너 종료 노출 타입 명 */
  name: Scalars['String'];
};

export type BandBannerItems = {
  __typename?: 'BandBannerItems';
  /** 배경 색 */
  backgroundColor: Scalars['String'];
  /** 닫기 색 */
  closeColor: Scalars['String'];
  /** 종료 노출 타입 */
  closedShow: Scalars['String'];
  /** 종료일자 */
  endDate: Scalars['DateTime'];
  /** 링크 URL */
  href: Scalars['String'];
  /** 띠 배너 텍스트 */
  id: Scalars['Int'];
  /** 새창 띄우기 여부 */
  isBlank: Scalars['Boolean'];
  /** 시작일자 */
  startDate: Scalars['DateTime'];
  /** 띠 배너 텍스트 */
  text: Scalars['String'];
};

export type BandBannerType = {
  __typename?: 'BandBannerType';
  /** 띠 배너 타입 코드 */
  code: Scalars['Float'];
  /** 띠 배너 타입 ID */
  id: Scalars['Float'];
  /** 띠 배너 타입 명 */
  name: Scalars['String'];
};

export type Bank = {
  __typename?: 'Bank';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type BankOutput = {
  __typename?: 'BankOutput';
  /** 고유 아이디 */
  id: Scalars['Int'];
  /** 은행명 */
  name: Scalars['String'];
};

export type Banner = {
  __typename?: 'Banner';
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFile>;
  /** 배너를 표시할 다국어몰 */
  language?: Maybe<Language>;
  /** 배너를 표시 또는 숨길 상품들 */
  selectedProducts?: Maybe<Array<Product>>;
  /** 선택된 상품만 표시 또는 선택된 상품만 숨기기 */
  showOnlyForSelectedProducts?: Maybe<Scalars['Boolean']>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 배너타입(표시위치) */
  type?: Maybe<BannerType>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type BannerClone = {
  __typename?: 'BannerClone';
  /** 배너를 표시 또는 숨길 상품들 */
  assets?: Maybe<Array<Asset>>;
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFileClone>;
  languageCode: LanguageCodeEnum;
  /** 선택된 상품만 표시 또는 선택된 상품만 숨기기 */
  showOnlyForSelectedProducts?: Maybe<Scalars['Boolean']>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 배너타입(표시위치) */
  type?: Maybe<BannerTypeClone>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type BannerInfo = {
  __typename?: 'BannerInfo';
  /** 배너 목록 */
  bannerList: Array<BannerItem>;
  /** 슬라이드 효과 */
  effect: Scalars['String'];
  /** 배너 높이 */
  height: Scalars['Int'];
  /** 네비게이션 버튼 여부 */
  isNaviButton: Scalars['Boolean'];
  /** 배너 사이즈에 따른 px or % (true : px, false: %) */
  isPx: Scalars['Boolean'];
  /** 사이드 버튼 여부 */
  isSideButton: Scalars['Boolean'];
  /** 네비게이션 버튼 활성화 되었을 때의 색상 */
  naviButtonActiveColor: Scalars['String'];
  /** 네비게이션 버튼 비활성화 되었을 때의 색상 */
  naviButtonColor: Scalars['String'];
  /** 네비게이션 버튼 크기 */
  naviButtonSize: Scalars['Int'];
  /** 네비게이션 버튼 유형 */
  naviButtonType: Scalars['String'];
  /** 사이드 버튼 색상 */
  sideButtonColor: Scalars['String'];
  /** 슬라이드 속도 */
  speed: Scalars['Int'];
  /** 슬라이드 시간 */
  time: Scalars['Int'];
  /** 배너 너비 */
  width: Scalars['Int'];
};

export type BannerItem = {
  __typename?: 'BannerItem';
  /** 색상 */
  color?: Maybe<Scalars['String']>;
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** a 태그 옵션의 _blank 여부 */
  isBlank: Scalars['Boolean'];
  /** 링크 URL */
  linkUrl: Scalars['String'];
  /** 기기 타입 */
  type: Scalars['String'];
};

export type BannerType = {
  __typename?: 'BannerType';
  /** 배너 타입 코드(b1, b2, ...) */
  code: Scalars['String'];
  /** 배너 안에 표시되는 내용의 타입(text, image, ...) */
  displayType: Scalars['String'];
  /** 배너 타입 id */
  id: Scalars['Float'];
  /** 배너 타입에 따른 표시 위치 */
  name: Scalars['String'];
};

export type BannerTypeClone = {
  __typename?: 'BannerTypeClone';
  /** 배너 타입 코드(b1, b2, ...) */
  code: Scalars['String'];
  /** 배너 안에 표시되는 내용의 타입(text, image, ...) */
  displayType: Scalars['String'];
  /** 배너 타입 id */
  id: Scalars['Int'];
  /** 배너 타입에 따른 표시 위치 */
  name: Scalars['String'];
};

export type BenefitApply = {
  __typename?: 'BenefitApply';
  /** 포인트 혜택 적용 타입 코드 */
  code: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 혜택 적용 타입 명 */
  name: Scalars['String'];
};

export type BenefitApplyClone = {
  __typename?: 'BenefitApplyClone';
  /** 포인트 혜택 적용 타입 코드 */
  code: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 혜택 적용 타입 명 */
  name: Scalars['String'];
};

export type BenefitBurden = {
  __typename?: 'BenefitBurden';
  /** 부담도 */
  burden?: Maybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** sno */
  sno: Scalars['String'];
};

export type BenefitLimit = {
  __typename?: 'BenefitLimit';
  /** 브랜드 */
  brand?: Maybe<Brand>;
  /** 브랜드 id */
  brandId?: Maybe<Scalars['Float']>;
  /** 카테고리 */
  category?: Maybe<Category>;
  /** 카테고리 */
  categoryId?: Maybe<Scalars['Float']>;
  /** 제한 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 상품 번호들 */
  goodsNo?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 제한 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 구분 (brand, category, goods) */
  type: Scalars['String'];
};

export type BenefitList = {
  __typename?: 'BenefitList';
  /** 할인률 */
  discountRate?: Maybe<Scalars['Int']>;
  /** 종료 일자 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 차수 */
  roundNo?: Maybe<Scalars['Int']>;
  /** 할인 가격 */
  salePrice?: Maybe<Scalars['String']>;
  /** 시작 일자 */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type BenefitType = {
  __typename?: 'BenefitType';
  /** 포인트 혜택 타입 코드 */
  code: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 혜택 타입 명 */
  name: Scalars['String'];
};

export type BenefitTypeClone = {
  __typename?: 'BenefitTypeClone';
  /** 포인트 혜택 타입 코드 */
  code: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 혜택 타입 명 */
  name: Scalars['String'];
};

export enum BestProductType {
  All = 'all',
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

export type Brand = {
  __typename?: 'Brand';
  /** 고도몰 브랜드코드 */
  godoBrandCd?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 게임 브랜드 여부 */
  isGameBrand: Scalars['Boolean'];
  /** 웹툰 브랜드 여부 */
  isWebtoonBrand: Scalars['Boolean'];
  item?: Maybe<Array<BrandI18n>>;
  name: Scalars['String'];
  /** 상품 타입 */
  productType?: Maybe<ProductType>;
};

export type BrandItemArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
};

export type BrandCategoryReturn = {
  __typename?: 'BrandCategoryReturn';
  code: Scalars['String'];
  isLink: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type BrandClone = {
  __typename?: 'BrandClone';
  brandPickIds: Array<Scalars['Int']>;
  godoBrandCd?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  introduction?: Maybe<Scalars['String']>;
  introductionLang?: Maybe<LanguageCodeEnum>;
  modified?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  photo?: Maybe<UploadFileClone>;
  showBrandReview: Scalars['Boolean'];
  useBrandPick: Scalars['Boolean'];
};

export type BrandEntity = {
  __typename?: 'BrandEntity';
  brandPickCount: Scalars['Int'];
  brandPickIds: Array<Scalars['Int']>;
  godoBrandCd?: Maybe<Scalars['String']>;
  i18ns: Array<BrandI18nEntity>;
  id: Scalars['Int'];
  introduction?: Maybe<Scalars['String']>;
  introductionLang?: Maybe<LanguageCodeEnum>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<UploadFileClone>;
  showBrandReview: Scalars['Boolean'];
  useBrandPick: Scalars['Boolean'];
};

export type BrandI18n = {
  __typename?: 'BrandI18n';
  /** 부모 브랜드 ID  */
  brandId: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  language?: Maybe<Language>;
  name: Scalars['String'];
};

export type BrandI18nClone = {
  __typename?: 'BrandI18nClone';
  /** 고유 ID */
  id: Scalars['Float'];
  introduction?: Maybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  name?: Maybe<Scalars['String']>;
};

export type BrandI18nEntity = {
  __typename?: 'BrandI18nEntity';
  introduction?: Maybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  name?: Maybe<Scalars['String']>;
};

export type BrandI18nOutput = {
  __typename?: 'BrandI18nOutput';
  brandId: Scalars['Int'];
  introduction?: Maybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  name?: Maybe<Scalars['String']>;
};

export type BrandOutput = {
  __typename?: 'BrandOutput';
  /** 브랜드 코드(6자리); nullable; */
  code?: Maybe<Scalars['String']>;
  /** 브랜드 코드(6자리); nullable; */
  godoBrandCd?: Maybe<Scalars['String']>;
  i18ns: Array<BrandI18nOutput>;
  /** 브랜드 ID */
  id: Scalars['Int'];
  /** 브랜드 ACON3D 전속 여부 */
  isAcon3DExclusive: Scalars['Boolean'];
  /** 브랜드 이름 */
  name: Scalars['String'];
  photoInfo?: Maybe<FileOutput>;
  productCount: Scalars['Int'];
  update?: Maybe<BrandOutputUpdateOutput>;
  vipInfo?: Maybe<Brand_Output_Brand_Vip_Type>;
};

export type BrandOutputUpdateOutput = {
  __typename?: 'BrandOutputUpdateOutput';
  /** 타입 */
  type: Brand_Output_Update_Output_Type;
  /** type === "DISCOUNT"인 경우만 해당, 최대 할인율 */
  value?: Maybe<Scalars['Int']>;
};

export type BrandPaginatedOutput = {
  __typename?: 'BrandPaginatedOutput';
  data: Array<BrandOutput>;
  pagination: PaginationOutput;
};

export type BrandPaginatedResponse = {
  __typename?: 'BrandPaginatedResponse';
  count: Scalars['Int'];
  rows: Array<BrandEntity>;
};

export type BrandPick = {
  __typename?: 'BrandPick';
  assets: Array<Asset>;
  isOn: Scalars['Boolean'];
};

export type BrandProductsWithCategoriesOutput = {
  __typename?: 'BrandProductsWithCategoriesOutput';
  /** 카테고리 정보 */
  categories: Array<Acon3dCategory>;
  /** 상품 번호(ProductNo) */
  id: Scalars['Int'];
};

export type BrandPromotionOutput = {
  __typename?: 'BrandPromotionOutput';
  /** 취소일자 */
  canceledAt?: Maybe<Scalars['DateTime']>;
  /** 생성(등록)일자, 디폴트소팅필드 */
  createdAt: Scalars['DateTime'];
  /** acon.brand_promotion.id */
  id: Scalars['Int'];
  items: Array<PromotionItemOutput>;
  promotion: PromotionOutput;
  /** 프로모션 id (acon.promote.id) */
  promotionId: Scalars['Int'];
};

export type BrandPublicOutput = {
  __typename?: 'BrandPublicOutput';
  /** 브랜드 코드 */
  code: Scalars['String'];
  /** 브랜드 ID */
  id: Scalars['Int'];
  /** 독점 여부 */
  isExclusive: Scalars['Boolean'];
  /** 브랜드 이름(번역) */
  name: Scalars['String'];
};

export type BrandsInput_Condition = {
  /** 이름(EQUAL 조건) */
  exactName?: InputMaybe<Scalars['String']>;
  /** 이름(LIKE 조건) */
  name?: InputMaybe<Scalars['String']>;
  /** 승인 상태 */
  statuses?: InputMaybe<Array<AcceptStatusEnum>>;
  /** 테스트 브랜드 포함 여부 */
  withTest?: InputMaybe<Scalars['Boolean']>;
};

export type BrandsInput_Sort = {
  /** 정렬 방법 */
  criterion: Brands_Input_Sort_Criterion;
  /** 정렬 기준 필드 */
  key: Brands_Input_Sort_Key;
};

export enum Categories_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Categories_Input_Sort_Key {
  Code = 'CODE',
}

export enum Clips_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Clips_Sort_Key {
  CreatedAt = 'CREATED_AT',
}

export type CancelBrandPromotionInput = {
  /** BrandPromotion의 id */
  id: Scalars['Int'];
};

export type CancelInquiryInput = {
  /** Inquiry ID */
  id: Scalars['Int'];
};

export type CartInfo = {
  __typename?: 'CartInfo';
  /** 중복 상품 여부 */
  duplicationGoods: Scalars['Boolean'];
  /** 상품 이미지 */
  goodsImage: Scalars['String'];
  /** 상품 명 */
  goodsNm: Scalars['String'];
  /** 상품 번호 */
  goodsNo: Scalars['String'];
  /** 중복 구매 상품 여부 */
  isOverlap: Scalars['Boolean'];
  /** 회원 할인 정보 */
  memberDcInfo: Scalars['String'];
  /** 포인트 정보 */
  mileageInfo: Scalars['String'];
  /** 옵션 번호 */
  optionSno: Scalars['String'];
  /** 구매 불가 여부 */
  orderPossible: Scalars['Boolean'];
  /** 결제 수단 설정 여부 */
  payLimitFl: Scalars['Boolean'];
  /** 상품 금액 정보 */
  price: OrderPrice;
  /** 금액 정보 */
  priceInfo: Scalars['String'];
  product?: Maybe<Acon3dProduct>;
  /** 장바구니 일련번호 */
  sno: Scalars['String'];
};

export type CartItem = {
  __typename?: 'CartItem';
  /** 브랜드 코드 */
  brandCd: Scalars['String'];
  /** 브랜드 명 */
  brandName: Scalars['String'];
  /** 확장자들 */
  extensions: Array<Scalars['String']>;
  /** 상품번호 */
  goodsNo: Scalars['Int'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 리스트 표시 여부 * 신규추가 */
  isEndSales: Scalars['Boolean'];
  /** 주문 여부 */
  isOrder: Scalars['Boolean'];
  /** 판매 여부 */
  isSale: Scalars['Boolean'];
  /** 지급되어야 할 최대 포인트 */
  point: Scalars['Int'];
  /** 판매가 */
  price: Scalars['Int'];
  /** 정가 */
  realPrice: Scalars['Int'];
  /** sno */
  sno: Scalars['Int'];
  /** 상품 제목 */
  title: Scalars['String'];
};

export type CartOutput = {
  __typename?: 'CartOutput';
  productNo: Scalars['Int'];
};

export type CashItem = {
  __typename?: 'CashItem';
  /** 잔여 캐시 액수 */
  afterDeposit: Scalars['Int'];
  /** 캐시 지급 내용 */
  contents: Scalars['String'];
  /** 캐시 지급 액수 */
  deposit: Scalars['Int'];
  /** 결제 수단 */
  payMethod?: Maybe<Scalars['String']>;
  /** 캐시 지급 일자 */
  regDate: Scalars['String'];
  /** 결제 상태 */
  status?: Maybe<Scalars['String']>;
  /** 가상계좌 정보 */
  vBank?: Maybe<VBank>;
};

export type CashList = {
  __typename?: 'CashList';
  /** 에이콘 캐시 내역 목록 */
  items: Array<CashItem>;
  /** 에이콘 캐시 내역 전체 갯수 */
  totalCount: Scalars['Int'];
};

export type CategoriesInput_Condition = {
  /** 카테고리 코드 배열 */
  codes: Array<Scalars['String']>;
  /** true일 경우 isVisible: true인 카테고리만 반환 */
  visibleOnly?: InputMaybe<Scalars['Boolean']>;
};

export type CategoriesInput_Sort = {
  criterion: Categories_Input_Sort_Criterion;
  key: Categories_Input_Sort_Key;
};

export type Category = {
  __typename?: 'Category';
  creator?: Maybe<Scalars['Float']>;
  godoCateCd?: Maybe<Scalars['String']>;
  godoSno?: Maybe<Scalars['Float']>;
  i18n: Array<CategoryI18n>;
  /** 카테고리 고유 ID */
  id: Scalars['Float'];
  memo?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['Float']>;
  sortNo?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  /** 대분류 */
  type: CategoryTypeEnum;
  updater?: Maybe<Scalars['Float']>;
};

export type CategoryAtom = {
  __typename?: 'CategoryAtom';
  /** 카테고리 코드 */
  code: Scalars['String'];
  /** 카테고리 이름 */
  name: Scalars['String'];
};

export type CategoryClone = {
  __typename?: 'CategoryClone';
  /** 등록 관리자 ID */
  creator?: Maybe<Scalars['Int']>;
  /** 고도몰 카테고리 코드 */
  godoCateCd?: Maybe<Scalars['String']>;
  /** 고도몰 카테고리 sno(시리얼 넘버) */
  godoSno?: Maybe<Scalars['Int']>;
  i18n: CategoryI18nClone;
  /** 카테고리 번역 상세 */
  i18ns?: Maybe<Array<CategoryI18nClone>>;
  id: Scalars['Int'];
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 부모 카테고리 ID */
  parent?: Maybe<Scalars['Int']>;
  /** 정렬용 번호 */
  sortNo?: Maybe<Scalars['Int']>;
  /** 상태: liveon/standby */
  state: Scalars['String'];
  /** 대분류: toonstyle/realistic */
  type: CategoryTypeEnum;
  /** 갱신 관리자 ID */
  updater?: Maybe<Scalars['Int']>;
};

export type CategoryGoods = {
  cateCd: Scalars['String'];
  goodsNo: Array<Scalars['Float']>;
};

export type CategoryI18n = {
  __typename?: 'CategoryI18n';
  anchor?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['Float']>;
  file?: Maybe<CategoryI18nFile>;
  /** 고유 ID */
  id: Scalars['Float'];
  isBlankAnchor?: Maybe<Scalars['Float']>;
  isShowResultCount: Scalars['Float'];
  language?: Maybe<Language>;
  languageCode: Scalars['String'];
  map?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updater?: Maybe<Scalars['Float']>;
};

export type CategoryI18nClone = {
  __typename?: 'CategoryI18nClone';
  /** 허브 카테고리 배너 경로: 링크 */
  anchor?: Maybe<Scalars['String']>;
  categoryId: Scalars['Int'];
  /** 허브 카테고리 배너 색상 */
  color?: Maybe<Scalars['String']>;
  /** 등록 관리자 ID */
  creator?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** 링크 새 창으로 열기 여부 */
  isBlankAnchor?: Maybe<Scalars['Boolean']>;
  /** 상품 목록 결과 표시 여부 */
  isShowResultCount: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  /** 허브 카테고리 배너 경로: 이미지맵 */
  map?: Maybe<Scalars['String']>;
  /** 카테고리 이름(번역) */
  name: Scalars['String'];
  /** 갱신 관리자 ID */
  updater?: Maybe<Scalars['Int']>;
};

export type CategoryI18nFile = {
  __typename?: 'CategoryI18nFile';
  categoryI18nId: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  uploadFile?: Maybe<UploadFile>;
  uploadFileId: Scalars['Float'];
};

export type CategoryMolecule = {
  __typename?: 'CategoryMolecule';
  /** 1차 카테고리 */
  primary: CategoryAtom;
  /** 2차 카테고리  */
  secondaries: Array<CategoryAtom>;
};

export type CategoryNode = {
  __typename?: 'CategoryNode';
  /** 카테고리 코드 */
  code: Scalars['String'];
  /** 카테고리 아이디 */
  id: Scalars['Int'];
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 상위 카테고리 */
  parent?: Maybe<Scalars['Int']>;
};

export type CategoryOutput = {
  __typename?: 'CategoryOutput';
  code: Scalars['String'];
  name: Scalars['String'];
  /** 하위 카테고리 */
  subs?: Maybe<Array<Maybe<CategoryOutput>>>;
};

export type CategoryOutputV2 = {
  __typename?: 'CategoryOutputV2';
  categories: Array<CategoryMolecule>;
  /** 카테고리 분류 장르별=GENRE, 주제별=THEME */
  type: Scalars['String'];
};

export type CategoryOutputV4 = {
  __typename?: 'CategoryOutputV4';
  children: Array<CategoryOutputV4>;
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CategoryPublicOutput = {
  __typename?: 'CategoryPublicOutput';
  /** 고도 카테고리 코드 */
  code: Scalars['String'];
  /** 카테고리 ID */
  id: Scalars['Int'];
  /** 카테고리 이름(번역) */
  name: Scalars['String'];
};

export enum CategoryTypeEnum {
  Realistic = 'realistic',
  Toonstyle = 'toonstyle',
}

/** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
export type ChangePenNameInput = {
  /** null | personToTeam | teamToPerson */
  changeType?: InputMaybe<ChangeTypeEnum>;
  /** 기존 필명으로 작품을 사용함 여부 */
  isOldPenNameRetained?: InputMaybe<Scalars['Boolean']>;
  /** 필명/작품명 */
  penName: Scalars['String'];
  /** 팀/회사명 */
  teamName?: InputMaybe<Scalars['String']>;
};

export enum ChangeTypeEnum {
  PersonToTeam = 'personToTeam',
  TeamToPerson = 'teamToPerson',
}

export type CheckHasAdultProducts_Condition = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 검색어 */
  input?: InputMaybe<Scalars['String']>;
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']>>;
};

export type ChildCategoriesInput = {
  languageCode: LanguageCodeEnum;
  parentCateCd?: InputMaybe<Scalars['String']>;
};

export type Clip = {
  __typename?: 'Clip';
  created?: Maybe<Scalars['DateTime']>;
  godoGoodsNo: Scalars['Int'];
  godoMemNo: Scalars['Int'];
  id: Scalars['Int'];
  product: ProductOutput;
};

export type ClipProductArgs = {
  language: LanguageCodeEnum;
};

export type ClipInquiryInput = {
  /** Inquiry ID */
  inquiryId: Scalars['Int'];
  /** Is currently clipped */
  isClipped: Scalars['Boolean'];
};

export type CompleteOpenrunPromotionInput = {
  /** document id(uuid) */
  id: Scalars['String'];
  /** 프로모션 시작일 */
  since: Scalars['DateTime'];
};

export type CouponApplyCategory = {
  __typename?: 'CouponApplyCategory';
  /** 카테고리 명 */
  name: Scalars['String'];
  /** 카테고리 번호 */
  no: Scalars['String'];
};

export type CouponApplyGoods = {
  __typename?: 'CouponApplyGoods';
  /** 상품명 */
  goodsNm: Scalars['String'];
  /** 상품 번호 */
  goodsNo: Scalars['String'];
};

export type CouponBanner = {
  __typename?: 'CouponBanner';
  /** 조건1 */
  conditionFirst: Scalars['String'];
  /** 조건2 */
  conditionSecond: Scalars['String'];
  /** 쿠폰 번호 */
  couponNo: Scalars['Int'];
  /** 노출 종료 시간 */
  endDate: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFile>;
  /** 언어 정보 */
  language: Language;
  /** 노출 시작 시간 */
  startDate: Scalars['DateTime'];
  /** 제목 */
  title: Scalars['String'];
};

export type CouponBenefit = {
  __typename?: 'CouponBenefit';
  /** 쿠폰 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<CouponBenefitApply>>;
  /** 쿠폰 혜택 적용 설정 */
  applyType?: Maybe<BenefitApply>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<CouponBenefitBoardInfo>>;
  /** 쿠폰 혜택 생성일 */
  created: Scalars['DateTime'];
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 쿠폰 혜택을 적용할 다국어몰 */
  language?: Maybe<Language>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<CouponBenefitOrderInfo>>;
  /** 쿠폰 헤택 유형 */
  type?: Maybe<BenefitType>;
  /** 쿠폰 혜택 수정일 */
  updated: Scalars['DateTime'];
};

export type CouponBenefitApply = {
  __typename?: 'CouponBenefitApply';
  couponBenefitId: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 적용 설정 값 */
  value: Scalars['String'];
};

export type CouponBenefitBoardInfo = {
  __typename?: 'CouponBenefitBoardInfo';
  couponBenefitId: Scalars['Float'];
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type CouponBenefitItem = {
  __typename?: 'CouponBenefitItem';
  /** 쿠폰 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<ApplyItems>>;
  /** 쿠폰 혜택 적용 설정 */
  applyType?: Maybe<ApplyType>;
  /** 고도몰 쿠폰 명 */
  godoCouponName?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 등록일 */
  godoCouponRegDt?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Int'];
  /** 쿠폰 혜택을 적용할 다국어몰 */
  language?: Maybe<Lang>;
  /** 쿠폰 헤택 유형 */
  type?: Maybe<Type>;
};

export type CouponBenefitOrderInfo = {
  __typename?: 'CouponBenefitOrderInfo';
  couponBenefitId: Scalars['Float'];
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type CouponBurden = {
  __typename?: 'CouponBurden';
  /** 부담도 */
  burden?: Maybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** sno */
  sno: Scalars['String'];
};

export type CouponItem = {
  __typename?: 'CouponItem';
  /** 쿠폰 사용 가능 카테고리 목록 */
  couponApplyCategory?: Maybe<Array<CouponApplyCategory>>;
  /** 쿠폰 사용 가능 상품 목록 */
  couponApplyGoods?: Maybe<Array<CouponApplyGoods>>;
  /** 쿠폰 사용 가능 타입 (category, brand, goods) */
  couponApplyProductType: Scalars['String'];
  /** 쿠폰 혜택 */
  couponBenefit: Scalars['Int'];
  /** 쿠폰 혜택 타입 (percent, fix) */
  couponBenefitType: Scalars['String'];
  /** 쿠폰 종류(sale, add, deposit) */
  couponKindType: Scalars['String'];
  /** 쿠폰 최대 혜택 */
  couponMaxBenefit: Scalars['Int'];
  /** 최소 주문 금액 */
  couponMinOrderPrice: Scalars['Int'];
  /** 최소 주문 금액 기준 (product, order), default: product */
  couponMinOrderType?: Maybe<Scalars['String']>;
  /** 쿠폰 ID */
  couponNo: Scalars['Int'];
  /** 쿠폰 사용 가능 종료 시간 */
  endDate: Scalars['DateTime'];
  /** 쿠폰 중복 사용 가능 여부 */
  isCouponApplyDuplicate: Scalars['Boolean'];
  /** 쿠폰 최대 혜택 제한 존재 여부 */
  isCouponMaxBenefit: Scalars['Boolean'];
  /** 유저 쿠폰 ID */
  memberCouponNo: Scalars['Int'];
  /** 쿠폰 사용가능 상태 (YES, USE_CART, USE_ORDER, EXPIRATION_START_PERIOD, EXPIRATION_END_PERIOD) */
  memberCouponUsable?: Maybe<Scalars['String']>;
  /** 쿠폰명 */
  name: Scalars['String'];
  /** 쿠폰 발급된 시간 */
  regDt: Scalars['DateTime'];
  /** 쿠폰 사용 가능 시작 시간 */
  startDate: Scalars['DateTime'];
};

export type CouponItems = {
  __typename?: 'CouponItems';
  /** 고도몰 쿠폰 명 */
  godoCouponName?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 등록일 */
  godoCouponRegDt?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 남은 수량 */
  remaining?: Maybe<Scalars['Int']>;
};

export type CouponList = {
  __typename?: 'CouponList';
  /** 쿠폰 내역 목록 */
  items: Array<CouponItem>;
  /** 쿠폰 내역 전체 갯수 */
  totalCount: Scalars['Int'];
};

export type CreateApplicationInput = {
  name: Scalars['String'];
};

export type CreateClipInput = {
  godoGoodsNo: Scalars['Int'];
};

export type CreateEventInput = {
  endDate: Scalars['DateTime'];
  /** 이벤트가 진행되는 몰들을 ,로 구분하여 입력합니다. ex) ko,en,zh */
  malls: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type CreateExtensionConfigInput = {
  name: Scalars['String'];
};

export type CreateGiftInput = {
  benefitDeposit: Scalars['Int'];
  imageUrl?: InputMaybe<Scalars['String']>;
  isOpen: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  price: Scalars['Int'];
  title: Scalars['String'];
};

export type CreateInquiryAnswerInput = {
  /** Answer content */
  answer: Scalars['String'];
  /** Inquiry ID */
  inquiryId: Scalars['Int'];
};

export type CreateInquiryMemoInput = {
  /** Inquiry ID */
  inquiryId: Scalars['Int'];
  /** Memo content */
  memo: Scalars['String'];
};

export type CreateOrderMemoInput = {
  memo: Scalars['String'];
  orderId: Scalars['String'];
};

export type CreatePartnerBrandInput = {
  /** 중국어 표시이름 */
  cnName?: InputMaybe<Scalars['String']>;
  /** 영어 표시이름 */
  enName?: InputMaybe<Scalars['String']>;
  /** 고도몰 브랜드 코드 */
  godoBrandCd?: InputMaybe<Scalars['String']>;
  /** 일본어 표시이름 */
  jpName?: InputMaybe<Scalars['String']>;
  /** 한국어 표시이름 */
  koName?: InputMaybe<Scalars['String']>;
};

export type CreatePartnerInput = {
  /** 계정(이메일) */
  account: Scalars['String'];
  /** 회사명 */
  companyName?: InputMaybe<Scalars['String']>;
  /** 연락처 */
  contact: Scalars['String'];
  /** 추가 연락처 */
  contactAdditional?: InputMaybe<Scalars['String']>;
  /** 가입언어 */
  joinLanguage: LanguageCodeEnum;
  /** 이름 */
  name: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  reference?: InputMaybe<Array<Scalars['String']>>;
  /** 정산정보 */
  settle: CreatePartnerSettleInput;
  snsUrl?: InputMaybe<Scalars['String']>;
  /** 사업자 구분 */
  subType?: InputMaybe<Scalars['String']>;
  /** 사용자 구분(개인, 회사) */
  type: PartnerTypeEnum;
};

export type CreatePartnerSettleFileInput = {
  bankBook?: InputMaybe<StoredFileInput>;
  idCard?: InputMaybe<StoredFileInput>;
  limitedTaxApl?: InputMaybe<StoredFileInput>;
  sign?: InputMaybe<StoredFileInput>;
};

export type CreatePartnerSettleInput = {
  /** 은행 구분 아이디 */
  bank?: InputMaybe<Scalars['Int']>;
  /** 계좌번호 */
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  /** 예금주 */
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  /** 은행 이름 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 국가코드 */
  country: Scalars['String'];
  /** 개인식별번호(주민번호) */
  nationalIdNumber?: InputMaybe<Scalars['String']>;
  /** 계약서 언어 */
  signLang: LanguageCodeEnum;
};

export type CreatePopupInput = {
  /** 버튼 문구 */
  buttonText?: InputMaybe<Scalars['String']>;
  /** 연결 URL */
  connectedUrl?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean'];
  /** 팝업 이미지 아이디 */
  image?: InputMaybe<PopupUploadFileInput>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** 일시 정지용 */
  isStopped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<LanguageCodeEnum>;
  location?: InputMaybe<PopupLocationV2>;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** 하위 페이지 url */
  subLocations: Array<PopupSubLocationInput>;
  subText?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  urls: Array<CreatePopupUrlInput>;
};

export type CreatePopupUrlInput = {
  id?: InputMaybe<Scalars['Int']>;
  popupId?: InputMaybe<Scalars['Int']>;
  subLocation?: InputMaybe<PopupSubLocationEnum>;
  urls: Array<UrlsInput>;
};

export type CurrentPackageOutput = {
  __typename?: 'CurrentPackageOutput';
  brand: Scalars['String'];
  children: PackageChildren;
  parent: Asset;
};

export enum Document_Status {
  Complete = 'COMPLETE',
  CompleteOnOpen = 'COMPLETE_ON_OPEN',
  Denied = 'DENIED',
  Draft = 'DRAFT',
  DraftOnOpen = 'DRAFT_ON_OPEN',
  InReview = 'IN_REVIEW',
  InReviewOnOpen = 'IN_REVIEW_ON_OPEN',
  OnSale = 'ON_SALE',
  OnSaleOnOpen = 'ON_SALE_ON_OPEN',
  Rejected = 'REJECTED',
  RejectedOnOpen = 'REJECTED_ON_OPEN',
  Requested = 'REQUESTED',
  RequestedOnOpen = 'REQUESTED_ON_OPEN',
}

export type DeleteExtensionConfigInput = {
  id: Scalars['Int'];
};

export type DeleteGiftInput = {
  id: Scalars['Int'];
};

export type DeleteOpenrunPromotionInput = {
  /** 브랜드 ID */
  brandId: Scalars['Int'];
  /** document id(uuid) */
  id: Scalars['String'];
};

export type DeletePopupInput = {
  id: Scalars['Int'];
};

export type DeletePromotionConceptInput = {
  id: Scalars['Int'];
};

export type DeletePromotionInput = {
  id: Scalars['Int'];
};

export type DeletePromotionTwinkleAssetInput = {
  /** acon.promote.id */
  promotionId: Scalars['Int'];
  /** acon.promote_item.id */
  promotionItemId: Scalars['Int'];
};

export type DescendantsOfRegularCategoryInput = {
  languageCode: LanguageCodeEnum;
  regularCategoryType: RegularCategoryType;
};

export type DisplayAssetInfoOutput = {
  __typename?: 'DisplayAssetInfoOutput';
  title: Scalars['String'];
};

export type DisplayAssetWithItemsOutput = {
  __typename?: 'DisplayAssetWithItemsOutput';
  assets: ProductPaginatedOutput;
  displayInfo: DisplayAssetInfoOutput;
};

export type DisplayBrandInfoOutput = {
  __typename?: 'DisplayBrandInfoOutput';
  title: Scalars['String'];
};

export type DisplayBrandWithItemsOutput = {
  __typename?: 'DisplayBrandWithItemsOutput';
  brands: BrandPaginatedOutput;
  displayInfo: DisplayBrandInfoOutput;
};

export type DisplayPromotionItem = {
  __typename?: 'DisplayPromotionItem';
  /** 작가명 */
  brandName: Scalars['String'];
  /** 항목 ID */
  id: Scalars['Int'];
  /** 진행기간 */
  periods: Array<Periods>;
  status: Array<Scalars['String']>;
  /** 작품명 */
  title: Scalars['String'];
};

export type DocumentBookInput = {
  isbn: Scalars['String'];
};

export type DocumentBookOutput = {
  __typename?: 'DocumentBookOutput';
  isbn: Scalars['String'];
};

export type DocumentCategoryInput = {
  code: Scalars['String'];
};

export type DocumentChangeHistoryInput = {
  date: Scalars['DateTime'];
  message?: InputMaybe<Scalars['String']>;
  person: Scalars['String'];
  status: Document_Status;
};

export type DocumentChangeHistoryOutput = {
  __typename?: 'DocumentChangeHistoryOutput';
  date: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  person: Scalars['String'];
  status: Document_Status;
};

export type DocumentCommonInput = {
  /** 파일변경 정보 */
  fileUpdate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
};

export type DocumentCompleteInput = {
  documentCommonInput: DocumentCommonInput;
  documentFundingInput?: InputMaybe<DocumentFundingInput>;
};

export type DocumentCopyrightInput = {
  commercialSources?: InputMaybe<Scalars['String']>;
  hasRealLogo?: InputMaybe<Scalars['String']>;
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  warehouseSources?: InputMaybe<Scalars['String']>;
};

export type DocumentCopyrightOutput = {
  __typename?: 'DocumentCopyrightOutput';
  commercialSources?: Maybe<Scalars['String']>;
  hasRealLogo?: Maybe<Scalars['String']>;
  isOriginal?: Maybe<Scalars['Boolean']>;
  warehouseSources?: Maybe<Scalars['String']>;
};

export type DocumentFileComponentInput = {
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
};

export type DocumentFileComponentOutput = {
  __typename?: 'DocumentFileComponentOutput';
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type DocumentFileUpdateInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DocumentFileUpdateOutput = {
  __typename?: 'DocumentFileUpdateOutput';
  date?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type DocumentFundingInput = {
  /** 상품 전달일 */
  deliveryDate: Scalars['DateTime'];
  /** 펀딩 종료일 */
  endDate: Scalars['DateTime'];
  /** 펀딩 목표 금액 */
  goalAmount: Scalars['Int'];
  /** 펀딩 시작일 */
  startDate: Scalars['DateTime'];
};

export type DocumentIndustryInput = {
  id: Scalars['Int'];
};

export type DocumentMemoInput = {
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  manager?: InputMaybe<Scalars['String']>;
  memoId?: InputMaybe<Scalars['Int']>;
};

export type DocumentMemoOutput = {
  __typename?: 'DocumentMemoOutput';
  content?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  memoId?: Maybe<Scalars['Int']>;
};

export type DocumentTagInput = {
  id: Scalars['Int'];
};

export type DocumentTitle = {
  __typename?: 'DocumentTitle';
  language?: Maybe<Language_Code>;
  title?: Maybe<Scalars['String']>;
};

export enum DocumentsOrderColumn {
  Created = 'CREATED',
  Requested = 'REQUESTED',
  StatusChanged = 'STATUS_CHANGED',
}

export type DocumentsOrderCriteria = {
  documentsOrderColumn?: InputMaybe<DocumentsOrderColumn>;
  orderBy?: InputMaybe<OrderBy>;
};

export type DownloadCount = {
  __typename?: 'DownloadCount';
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 부여된 다운로드 횟수 */
  givenCount: Scalars['Int'];
  /** 상품 번호 */
  goodsNo: Scalars['Int'];
  /** id */
  id: Scalars['Int'];
  logs?: Maybe<Array<DownloadLog>>;
  /** 주문 번호 */
  orderNo: Scalars['String'];
  /** 수정자 */
  updator: Scalars['Int'];
  /** 유저 id */
  userId: Scalars['String'];
  /** 유저 고유 번호 */
  userNo: Scalars['Int'];
  /** 유저 분류 (personal, enterprise, admin) */
  userType: Scalars['String'];
};

export type DownloadCountInfo = {
  __typename?: 'DownloadCountInfo';
  /** goods number */
  goodsNo: Scalars['Int'];
  /** maximum download count */
  limit: Scalars['Int'];
  /** order number */
  orderNo: Scalars['Float'];
  /** currently remained download count */
  remained: Scalars['Int'];
  /** user ID */
  userId: Scalars['String'];
};

export type DownloadLog = {
  __typename?: 'DownloadLog';
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 생성자 */
  creator?: Maybe<Scalars['Int']>;
  /** 다운로드 카운트 id */
  downloadCountId: Scalars['Float'];
  /** 제공된 다운로드 url */
  downloadUrl: Scalars['String'];
  /** 상품 번호 */
  goodsNo: Scalars['Int'];
  /** id */
  id: Scalars['Int'];
  /** 다운로드 횟수 차감 여부 */
  isDeduction: Scalars['Boolean'];
  /** 제공된 모델 파일 id */
  modelFileId: Scalars['Int'];
  /** 주문 번호 */
  orderNo: Scalars['String'];
  /** 요청 ip */
  requestIp: Scalars['String'];
  /** 유저 id */
  userId: Scalars['String'];
  /** 유저 고유 번호 */
  userNo: Scalars['Int'];
};

export type DownloadLogResponse = {
  __typename?: 'DownloadLogResponse';
  /** 다운로드 카운터 객체 */
  downloadCounts: Array<DownloadCount>;
  /** 가져온 갯수 */
  totalCount: Scalars['Int'];
};

export enum EPromotionDiscountType {
  Fixed = 'FIXED',
  Percent = 'PERCENT',
}

export enum EPromotionStatus {
  Draft = 'DRAFT',
  Ended = 'ENDED',
  InProgress = 'IN_PROGRESS',
  Ready = 'READY',
}

export enum ESearchPromotionKey {
  AssetId = 'ASSET_ID',
  AssetTitle = 'ASSET_TITLE',
  BrandName = 'BRAND_NAME',
  PromotionTitle = 'PROMOTION_TITLE',
}

export type EventBannerItems = {
  __typename?: 'EventBannerItems';
  /** 첫번째 배너 내용 텍스트 */
  bannerTextFirst: Scalars['String'];
  /** 두번쨰 배너 내용 텍스트 */
  bannerTextSecond: Scalars['String'];
  /** 세번째 배너 내용 텍스트 */
  bannerTextThird: Scalars['String'];
  /** 노출 종료 일자 */
  endDate: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Int'];
  /** 배너 이미지 */
  image: UploadFile;
  /** 언어 */
  language: Language;
  /** 정렬순서 */
  order: Scalars['Int'];
  /** 링크 설정 텍스트 */
  settingLink: Scalars['String'];
  /** 노출 시작 일자 */
  startDate: Scalars['DateTime'];
};

export type ExtensionConfig = {
  __typename?: 'ExtensionConfig';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ExtensionOutput = {
  __typename?: 'ExtensionOutput';
  /** 확장자 갯수; 일반적인 쿼리에서는 요청할 일이 없으며, query productExtensions()에서 사용; */
  count: Scalars['Int'];
  /** 확장자 ID */
  id: Scalars['Int'];
  /** 확장자 이름 */
  name: Scalars['String'];
};

export type ExtensionPaginatedOutput = {
  __typename?: 'ExtensionPaginatedOutput';
  data: Array<ExtensionOutput>;
  pagination: PaginationOutput;
};

export type Faq = {
  __typename?: 'Faq';
  /** FAQ 내용(바디) */
  body?: Maybe<Scalars['String']>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 고정글인 경우 고정 순서(번호) */
  fixed?: Maybe<Scalars['Float']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** FAQ를 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** FAQ 제목 */
  title?: Maybe<Scalars['String']>;
  /** FAQ 타입(노하우, 가이드, ...) */
  type?: Maybe<FaqType>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type FaqAconModel = {
  __typename?: 'FaqAconModel';
  /** ACONFAQ 내용(바디) */
  body: Scalars['String'];
  /** 생성일 */
  created?: Maybe<Scalars['DateTime']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** ACONFAQ를 표시할 다국어몰 */
  language: Language;
  /** 표시 순서(번호) */
  order: Scalars['Float'];
  /** ACONFAQ 질문형 제목 (에이콘 상세 페이지에서 노출) */
  subtitle: Scalars['String'];
  /** ACONFAQ 제목 */
  title: Scalars['String'];
  /** ACONFAQ 타입(노하우, 가이드, ...) */
  type: FaqAconTypeModel;
  /** 수정일 */
  updated?: Maybe<Scalars['DateTime']>;
};

export type FaqAconTypeI18nModel = {
  __typename?: 'FaqAconTypeI18nModel';
  id: Scalars['Float'];
  language: Language;
  name?: Maybe<Scalars['String']>;
};

export type FaqAconTypeModel = {
  __typename?: 'FaqAconTypeModel';
  /** ACONFAQ 타입 코드(usage, product, ...) */
  code: Scalars['String'];
  /** ACONFAQ 타입의 각 언어별 이름 */
  i18n?: Maybe<Array<FaqAconTypeI18nModel>>;
  /** ACONFAQ 타입 id */
  id: Scalars['Float'];
};

export type FaqCreatorModel = {
  __typename?: 'FaqCreatorModel';
  /** 내용 */
  body: Scalars['String'];
  /** 생성일 */
  created?: Maybe<Scalars['DateTime']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 언어 */
  lang: Scalars['String'];
  /** 표시 순서(번호) */
  order: Scalars['Float'];
  /** 질문형 제목 */
  subtitle: Scalars['String'];
  /** 제목 */
  title: Scalars['String'];
  /** ACONFAQ 타입(노하우, 가이드, ...) */
  type: FaqCreatorTypeModel;
  /** 수정일 */
  updated?: Maybe<Scalars['DateTime']>;
};

export type FaqCreatorTypeI18nModel = {
  __typename?: 'FaqCreatorTypeI18nModel';
  id: Scalars['Float'];
  lang: Scalars['String'];
  name: Scalars['String'];
};

export type FaqCreatorTypeModel = {
  __typename?: 'FaqCreatorTypeModel';
  /** code */
  code: Scalars['String'];
  /** ACONFAQ 타입의 각 언어별 이름 */
  i18n?: Maybe<Array<FaqCreatorTypeI18nModel>>;
  /** id */
  id: Scalars['Float'];
};

export type FaqType = {
  __typename?: 'FaqType';
  /** FAQ 타입 코드(knowhow, guide, ...) */
  code: Scalars['String'];
  /** FAQ 타입 id */
  id: Scalars['Float'];
  /** FAQ 타입 이름(노하우, 가이드, ...) */
  name: Scalars['String'];
};

export type FetchPromotionsSearchInput = {
  conceptId?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<ESearchPromotionKey>;
  /** string | number | null */
  keyword?: InputMaybe<Scalars['JSON']>;
  since?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<EPromotionStatus>;
  until?: InputMaybe<Scalars['DateTime']>;
};

export type FileInput = {
  key: Scalars['String'];
  name: Scalars['String'];
  size?: InputMaybe<Scalars['Float']>;
};

export type FileOutput = {
  __typename?: 'FileOutput';
  /** 파일명 */
  fileName: Scalars['String'];
  id: Scalars['Int'];
  /** 파일 위치 */
  path?: Maybe<Scalars['String']>;
  /** 파일 크기 */
  size?: Maybe<Scalars['Int']>;
};

export enum FundingStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  Unready = 'UNREADY',
}

export type Get = {
  __typename?: 'Get';
  id: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId: Scalars['Float'];
};

export type GetBrandOrderProductItemsAssetInput = {
  /** 상품코드(아이디) */
  assetId?: InputMaybe<Scalars['Int']>;
};

export type GetBrandOrderProductItemsInput = {
  /** 상품 검색 조건 */
  asset?: InputMaybe<GetBrandOrderProductItemsAssetInput>;
  /** 무료 상품 여부 */
  isFree?: InputMaybe<Scalars['Boolean']>;
  /** 환불(취소)여부 */
  isRefunded?: InputMaybe<Scalars['Boolean']>;
  /** 주문 검색 조건 */
  order?: InputMaybe<GetBrandOrderProductItemsOrderInput>;
};

export type GetBrandOrderProductItemsOrderInput = {
  /** 라이선스 검색 조건 */
  license?: InputMaybe<GetBrandOrderProductItemsOrderLicenseInput>;
  /** 기간 검색 조건 */
  period?: InputMaybe<GetBrandOrderProductItemsOrderPeriodInput>;
};

export type GetBrandOrderProductItemsOrderLicenseInput = {
  /** 라이선스 검색 대상 */
  field?: InputMaybe<GetOrderLicenseNameField>;
  /** 라이선스 검색 값 */
  name?: InputMaybe<Scalars['String']>;
  /** 라이선스 타입 */
  type?: InputMaybe<OrderLicenseType>;
};

export type GetBrandOrderProductItemsOrderPeriodInput = {
  /** 기간 검색 대상 */
  field: GetOrderProductPeriodField;
  /** 시작일시 */
  since: Scalars['DateTime'];
  /** 종료일시 */
  until: Scalars['DateTime'];
};

export type GetClipsProductsCondition = {
  /** 상품 판매 상태 */
  isOnSale?: InputMaybe<Scalars['Boolean']>;
  /** 할인 여부 */
  isPromote?: InputMaybe<Scalars['Boolean']>;
};

export type GetClipsSort = {
  /** 정렬 방법 */
  criterion: Clips_Sort_Criterion;
  /** 정렬 기준 키 */
  key: Clips_Sort_Key;
};

export enum GetOrderLicenseNameField {
  Author = 'author',
  Project = 'project',
}

export enum GetOrderProductPeriodField {
  CreatedAt = 'createdAt',
  SettledAt = 'settledAt',
}

export type Gift = {
  __typename?: 'Gift';
  benefitDeposit: Scalars['Int'];
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isOpen: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  paybackPoint: Scalars['Int'];
  price: Scalars['Int'];
  title: Scalars['String'];
};

export type GodoBanner = {
  __typename?: 'GodoBanner';
  /** 링크 */
  anchor?: Maybe<Scalars['String']>;
  /** 링크 새 창으로 열기 여부 */
  anchorTarget: Scalars['Boolean'];
  /** 색상 */
  color?: Maybe<Scalars['String']>;
  /** 이미지 경로 */
  image?: Maybe<Scalars['String']>;
  /** 이미지 맵 */
  map?: Maybe<Scalars['String']>;
};

export type GodoCategory = {
  __typename?: 'GodoCategory';
  /** 고도몰 카테고리 코드 */
  id: Scalars['String'];
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 다국어 데이터 */
  trans: Array<GodoTrans>;
  /** 카테고리 대분류 */
  type?: Maybe<Scalars['String']>;
};

export type GodoCategoryOutput = {
  __typename?: 'GodoCategoryOutput';
  /** 카테고리 코드 */
  code: Scalars['String'];
  /**
   * 링크 여부
   * @deprecated 사용하지 않음. 필요해질 경우 DB에 별도의 필드를 만들 것
   */
  isLink: Scalars['Boolean'];
  /**
   * 표시 여부
   * @deprecated 사용하지 않음. 필요해질 경우 DB에 별도의 필드를 만들 것
   */
  isVisible: Scalars['Boolean'];
  /** 카테고리 이름 */
  name: Scalars['String'];
};

export type GodoCosmicDeal = {
  __typename?: 'GodoCosmicDeal';
  /** 혜택명 */
  benefitNm: Scalars['String'];
  /** 할인금액 */
  goodsPrice: Scalars['Int'];
  /** 혜택할인 종료기간 */
  periodDiscountEnd: Scalars['DateTime'];
  /** 혜택할인 시작기간 */
  periodDiscountStart: Scalars['DateTime'];
  /** sno */
  sno: Scalars['Int'];
};

export type GodoDisplayCategory = {
  __typename?: 'GodoDisplayCategory';
  /** 카테고리 코드 */
  code: Scalars['String'];
  /** 링크 여부 */
  isLink: Scalars['Boolean'];
  /** 표시 여부 */
  isVisible: Scalars['Boolean'];
  /** 카테고리 이름 */
  name: Scalars['String'];
};

export type GodoDisplayProductModel = {
  __typename?: 'GodoDisplayProductModel';
  /** 고도 표시 ID  */
  godoDisplayId: Scalars['Float'];
  /** 상품 번호 */
  goodsNo: Scalars['Int'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 정렬 순서 */
  sortNo: Scalars['Int'];
};

export type GodoMainGropTrans = {
  __typename?: 'GodoMainGropTrans';
  /** 상태 */
  lang: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
};

export type GodoMainGroup = {
  __typename?: 'GodoMainGroup';
  /** 이름 */
  name: Scalars['String'];
  /** 상태 */
  sno: Scalars['Int'];
  /** 하위 항목들 */
  trans: Array<GodoMainGropTrans>;
};

export type GodoPopupInfo = {
  __typename?: 'GodoPopupInfo';
  /** 오늘 하루 보지 않음 기능 사용여부 */
  content: Scalars['String'];
  /** 팝업 높이(ex: 100px / 50%) */
  height: Scalars['String'];
  /** 오늘 하루 보지 않음 기능 사용여부 */
  isUseTodayHide: Scalars['Boolean'];
  /** 오늘하루 보지않음 텍스트 위치 */
  todayHideAlign: Scalars['String'];
  /** 오늘하루 보지않음 배경색상 */
  todayHideBgColor: Scalars['String'];
  /** 오늘하루 보지않음 폰트색상 */
  todayHideTextColor: Scalars['String'];
  /** 팝업 너비 (ex: 100px / 50%) */
  width: Scalars['String'];
};

export type GodoProductBrand = {
  __typename?: 'GodoProductBrand';
  brandCd: Scalars['String'];
  /** 정렬 순서 (거의 쓰지 않음) */
  fixSort: Scalars['Int'];
  godoProductId: Scalars['Int'];
  /** 상품 정렬 순서 */
  goodsSort: Scalars['Int'];
  id: Scalars['Int'];
};

export type GodoProductBrandModel = {
  __typename?: 'GodoProductBrandModel';
  brandCd: Scalars['String'];
  fixSort: Scalars['Float'];
  /** 고도 상품 ID  */
  godoProductId: Scalars['Float'];
  goodsSort: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
};

export type GodoProductCategory = {
  __typename?: 'GodoProductCategory';
  cateCd: Scalars['String'];
  categoryDetail?: Maybe<CategoryClone>;
  fixSort: Scalars['Int'];
  godoProductId: Scalars['Int'];
  goodsSort: Scalars['Int'];
  id: Scalars['Int'];
};

export type GodoProductCategoryModel = {
  __typename?: 'GodoProductCategoryModel';
  cateCd: Scalars['String'];
  categoryDetail?: Maybe<Category>;
  fixSort: Scalars['Float'];
  /** 고도 상품 ID  */
  godoProductId: Scalars['Float'];
  goodsSort: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
};

export type GodoTrans = {
  __typename?: 'GodoTrans';
  /** 배너 데이터 */
  banner: GodoBanner;
  /** 언어 코드 */
  lang: Scalars['String'];
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 검색 결과 창 표시 여부 */
  visibleResultStats: Scalars['Boolean'];
};

export type GodoUserFriend = {
  __typename?: 'GodoUserFriend';
  /** 추천 코드 */
  code: Scalars['String'];
  /** 고도몰 사용자 ID */
  godoMemNo: Scalars['Int'];
  /** 코드 */
  godoUserInvitationCode: GodoUserInvitationCode;
  /** UUID */
  id: Scalars['String'];
  /** 구매여부 */
  isOrder: Scalars['Boolean'];
  /** 사용자 언어 */
  langCode: Scalars['String'];
};

export type GodoUserInvitationCode = {
  __typename?: 'GodoUserInvitationCode';
  /** 추천 코드 */
  code: Scalars['String'];
  /** 친구들 */
  friends?: Maybe<Array<GodoUserFriend>>;
  /** 고도몰 사용자 ID */
  godoMemNo: Scalars['Int'];
  /** 언어코드 */
  langCode: Scalars['String'];
};

export type GoodsBuyers = {
  __typename?: 'GoodsBuyers';
  /** 상품 id */
  goodsNo: Scalars['Float'];
  /** 주문자 그룹 이름 */
  groupNm: Scalars['String'];
  /** 주문 정보 메일 */
  orderEmail: Scalars['String'];
  /** 주문 정보 이름 */
  orderName: Scalars['String'];
  /** 주문 id */
  orderNo: Scalars['String'];
};

export type GqlRegional = {
  __typename?: 'GqlRegional';
  EN?: Maybe<Scalars['String']>;
  JA?: Maybe<Scalars['String']>;
  KO?: Maybe<Scalars['String']>;
  ZH?: Maybe<Scalars['String']>;
};

export type GqlRegionalInput = {
  EN: Scalars['String'];
  JA: Scalars['String'];
  KO: Scalars['String'];
  ZH: Scalars['String'];
};

export type GqlRegionalInputNullable = {
  EN?: InputMaybe<Scalars['String']>;
  JA?: InputMaybe<Scalars['String']>;
  KO?: InputMaybe<Scalars['String']>;
  ZH?: InputMaybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  auth: Array<GroupAuth>;
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type GroupAuth = {
  __typename?: 'GroupAuth';
  authType: AuthType;
  id: Scalars['Float'];
  menu: Menu;
};

export type GroupOutput = {
  __typename?: 'GroupOutput';
  /** ID */
  id: Scalars['Int'];
  /** 그룹명 */
  name: Scalars['String'];
};

export type HideAssetsInput = {
  /** asset id를 배열로 */
  assetIds: Array<Scalars['Int']>;
};

export type HubAdminPost = {
  __typename?: 'HubAdminPost';
  /** 고정글인 경우 순서 */
  anchoringOrder?: Maybe<Scalars['Int']>;
  body: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  next?: Maybe<HubAdminPost>;
  prev?: Maybe<HubAdminPost>;
  title: Scalars['String'];
  typeId: Scalars['Int'];
  typeName: Scalars['String'];
};

export type HubAdminPosts = {
  __typename?: 'HubAdminPosts';
  /** 고정글 */
  anchors: Array<HubAdminPost>;
  /** 일반글 */
  regulars: Array<HubAdminPost>;
};

export type HubAssetOutput = {
  __typename?: 'HubAssetOutput';
  id: Scalars['Int'];
  price: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export type IGodoOrderGoodsNo = {
  __typename?: 'IGodoOrderGoodsNo';
  /** 고도몰 상품번호 */
  goodsNo: Scalars['String'];
  /** 가상계좌 여부 */
  isVirtualAccount: Scalars['Boolean'];
  /** 고도몰 주문번호 */
  orderNo: Scalars['String'];
  /** 주문 상태 */
  orderStatus: OrderStatusEnum;
  /** 주문 생성날짜 */
  regDt: Scalars['String'];
};

export enum Industries_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Industries_Input_Sort_Key {
  Id = 'ID',
}

export type ImageCropCoordinationInput = {
  height: Scalars['Float'];
  width: Scalars['Float'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type ImageCropCoordinationOutput = {
  __typename?: 'ImageCropCoordinationOutput';
  height: Scalars['Float'];
  width: Scalars['Float'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type ImageKeys = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type IndustriesInput_Condition = {
  /** ID 배열 */
  ids?: InputMaybe<Array<Scalars['Int']>>;
};

export type IndustriesInput_Sort = {
  /** 요청 정렬 방법 */
  criterion: Industries_Input_Sort_Criterion;
  /** 요청 정렬 기준 */
  key: Industries_Input_Sort_Key;
};

export type IndustryOutput = {
  __typename?: 'IndustryOutput';
  /** 산업분야 ID; asset_industry.id; */
  id: Scalars['Int'];
  /** 산업분야 이름 */
  name: Scalars['String'];
};

export type IndustryPaginatedOutput = {
  __typename?: 'IndustryPaginatedOutput';
  data: Array<IndustryOutput>;
  pagination: PaginationOutput;
};

export type Inquiry = {
  __typename?: 'Inquiry';
  /** 문의 답변 */
  answer?: Maybe<InquiryAnswer>;
  /** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
  changePenName?: Maybe<InquiryChangePenName>;
  content: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  godoMemNo?: Maybe<Scalars['String']>;
  godoUserId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inquiryAcon3dProduct?: Maybe<Acon3dProduct>;
  /** 결제-주문/결제(G1) */
  inquiryOrders?: Maybe<Array<Maybe<InquiryOrder>>>;
  /** 상품상세문의(F1) */
  inquiryProduct?: Maybe<TempAconProduct>;
  /** 스크랩 여부 */
  isClipped?: Maybe<Scalars['Boolean']>;
  /** 회원 여부 */
  isMember: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  /** 관리자 메모 */
  memos: Array<InquiryMemo>;
  refundAccount?: Maybe<InquiryRefundAccount>;
  /** 상태: unread | read | answered */
  status: InquiryStatusEnum;
  /** 문의유형 소분류 */
  subType?: Maybe<InquirySubTypeEnum>;
  /** 문의유형 대분류 */
  type: InquiryTypeEnum;
  updated?: Maybe<Scalars['DateTime']>;
  uploadFiles: Array<UploadFile>;
};

export type InquiryInquiryAcon3dProductArgs = {
  languageCode?: InputMaybe<LanguageCodeEnum>;
};

export type InquiryAnswer = {
  __typename?: 'InquiryAnswer';
  adminUser: AdminUser;
  answer: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type InquiryChangePenName = {
  __typename?: 'InquiryChangePenName';
  /** 변경할 사용권 유형: null(ChangeMyself) | personToTeam | teamToPerson */
  changeType?: Maybe<ChangeTypeEnum>;
  /** 기존 필명으로 작품을 사용함 여부 */
  isOldPenNameRetained: Scalars['Boolean'];
  /** 필명/작품명 */
  penName: Scalars['String'];
  /** 팀/회사명 */
  teamName?: Maybe<Scalars['String']>;
};

export type InquiryInput = {
  /** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
  changePenName?: InputMaybe<ChangePenNameInput>;
  /** 기본값은 빈 문자열 */
  content?: InputMaybe<Scalars['String']>;
  /** 답변 받을 이메일 */
  email?: InputMaybe<Scalars['String']>;
  /** 결제-주문/결제(G1), 해당하지 않으면 null */
  inquiryOrders?: InputMaybe<Array<InputMaybe<InquiryOrderInput>>>;
  /** 상품상세문의(F1) */
  inquiryProduct?: InputMaybe<InquiryProductInput>;
  languageCode: LanguageCodeEnum;
  /** 결제-취소/환불(H1-H3) */
  refundAccount?: InputMaybe<RefundAccountInput>;
  /** 문의 소분류 */
  subType?: InputMaybe<InquirySubTypeEnum>;
  /** 세금계산서(B2) */
  taxes?: InputMaybe<TaxesInput>;
  /** 문의 대분류 */
  type: InquiryTypeEnum;
  /** 첨부파일. 없으면 빈 배열을 보냅니다 */
  uploadFiles: Array<Scalars['Int']>;
};

export type InquiryMemo = {
  __typename?: 'InquiryMemo';
  adminUser: AdminUser;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  memo: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type InquiryOrder = {
  __typename?: 'InquiryOrder';
  godoGoodsDetails: Array<InquiryOrderGoodsDetail>;
  godoOrderNo: Scalars['String'];
  id: Scalars['Int'];
};

export type InquiryOrderGoodsDetail = {
  __typename?: 'InquiryOrderGoodsDetail';
  godoGoodsNo: Scalars['String'];
  id: Scalars['Int'];
  orderStatus: Scalars['Float'];
};

/** Subtype of InquiryOrderInput */
export type InquiryOrderGoodsDetailInput = {
  godoGoodsNo: Scalars['String'];
  orderStatus: OrderStatusEnum;
};

/** 결제-주문/결제(G1) */
export type InquiryOrderInput = {
  godoGoodsDetails: Array<InquiryOrderGoodsDetailInput>;
  godoOrderNo: Scalars['String'];
};

/** 상품상세문의(F1) */
export type InquiryProductInput = {
  /** 상품 고도몰 번호 */
  goodsNo: Scalars['Int'];
};

export type InquiryRefundAccount = {
  __typename?: 'InquiryRefundAccount';
  accountHolder: Scalars['String'];
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
};

export enum InquiryStatusEnum {
  Answered = 'answered',
  Cancelled = 'cancelled',
  Read = 'read',
  Unread = 'unread',
}

export enum InquirySubTypeEnum {
  Abler = 'abler',
  AblurError = 'ablurError',
  AblurOld = 'ablurOld',
  AblurOther = 'ablurOther',
  AblurProgram = 'ablurProgram',
  AblurRefund = 'ablurRefund',
  AblurSubscribe = 'ablurSubscribe',
  CancelAndRefund = 'cancelAndRefund',
  ChangeOrderLicense = 'changeOrderLicense',
  ChangePenName = 'changePenName',
  CopyrightAndOrderLicense = 'copyrightAndOrderLicense',
  Improvement = 'improvement',
  OrderAndPayment = 'orderAndPayment',
  Other = 'other',
  ProductError = 'productError',
  ProductInfo = 'productInfo',
  SiteError = 'siteError',
}

export enum InquiryTypeEnum {
  Ablur = 'ablur',
  CollaborationProposal = 'collaborationProposal',
  Payment = 'payment',
  Product = 'product',
  Service = 'service',
  Taxes = 'taxes',
  Utilization = 'utilization',
}

export type IssueEventCouponInput = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export enum KeywordTypeEnum {
  Contents = 'contents',
  Goods = 'goods',
  User = 'user',
}

export enum Language_Code {
  En = 'EN',
  Ja = 'JA',
  Ko = 'KO',
  Zh = 'ZH',
}

export type Lang = {
  __typename?: 'Lang';
  /** 언어 코드 */
  code?: Maybe<Scalars['String']>;
  /** 언어 ID */
  id?: Maybe<Scalars['Float']>;
};

export type Language = {
  __typename?: 'Language';
  /** 언어 코드(ko, en, cn, jp) */
  code: Scalars['String'];
  /** 언어 id */
  id: Scalars['Float'];
  /** 언어 이름(한국어, 영어, ...) */
  name: Scalars['String'];
};

export type LanguageClone = {
  __typename?: 'LanguageClone';
  /** 언어 코드(ko, en, cn, jp) */
  code: Scalars['String'];
  /** 언어 id */
  id: Scalars['Float'];
  /** 언어 이름(한국어, 영어, ...) */
  name: Scalars['String'];
};

export enum LanguageCodeEnum {
  En = 'en',
  Ja = 'ja',
  Ko = 'ko',
  Zh = 'zh',
}

export type LanguageTextInput = {
  lang: LanguageCodeEnum;
  text: Scalars['String'];
};

export type LicenseClone = {
  __typename?: 'LicenseClone';
  companyName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  linkedOrderProductItems?: Maybe<Array<OrderProductItemClone>>;
  memNo: Scalars['Int'];
  name: Scalars['String'];
  order: OrderProductClone;
  orderId: Scalars['String'];
  status: LicenseStatus;
  type: LicenseType;
};

export enum LicenseStatus {
  Cancel = 'cancel',
  Confirm = 'confirm',
  Ready = 'ready',
}

export enum LicenseType {
  Company = 'company',
  Personal = 'personal',
}

export type LicenseTypeOptionInput = {
  /** 라이선스 타입 아이디 */
  licenseTypeId: Scalars['Int'];
  /** 라이선스의 가격 */
  price: Scalars['Float'];
};

export type LicenseTypeOptionOutput = {
  __typename?: 'LicenseTypeOptionOutput';
  /** 라이선스 타입 아이디 */
  licenseTypeId: Scalars['Int'];
  /** 라이선스의 가격 */
  price: Scalars['Float'];
};

export type LicenseTypeOutput = {
  __typename?: 'LicenseTypeOutput';
  /** 라이선스 타입 코드 */
  code: Scalars['String'];
  /** 고유 아이디 */
  id: Scalars['Int'];
  /** 해당 라이선스 사용 가능 수. 0 인 경우 무제한 */
  usableCount: Scalars['Int'];
};

export type LicenseUpdateHistory = {
  __typename?: 'LicenseUpdateHistory';
  adminUser: AdminUser;
  /** 데이터 전체 JSON */
  contents?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  orderId: Scalars['String'];
};

export type ListDocumentOutput = {
  __typename?: 'ListDocumentOutput';
  assetId?: Maybe<Scalars['Int']>;
  created: Scalars['DateTime'];
  game?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  imgsrc?: Maybe<Scalars['Boolean']>;
  isAdultOnly?: Maybe<Scalars['Boolean']>;
  language: Language_Code;
  lecture?: Maybe<Scalars['Boolean']>;
  mainImage?: Maybe<Scalars['String']>;
  requested?: Maybe<Scalars['DateTime']>;
  status: Partner_Document_Status;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  webtoon?: Maybe<Scalars['Boolean']>;
};

export type MainGroup = {
  __typename?: 'MainGroup';
  /** 고도몰 sno */
  godoSno: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  item?: Maybe<Array<MainGroupI18n>>;
  name: Scalars['String'];
};

export type MainGroupGoods = {
  goodsNo: Array<Scalars['Float']>;
  sno: Scalars['Float'];
};

export type MainGroupI18n = {
  __typename?: 'MainGroupI18n';
  /** 고유 ID */
  id: Scalars['Float'];
  language?: Maybe<Language>;
  /** 부모 메인그룹 ID  */
  mainGroupId: Scalars['Float'];
  name: Scalars['String'];
};

export type MainProductListV2Return = {
  __typename?: 'MainProductListV2Return';
  /** 상품 리스트 */
  productList: Array<Scalars['Int']>;
  /** 고도몰 메인구좌 관리번호 */
  sno: Scalars['String'];
  /** 경로 */
  source: Scalars['String'];
  title: Scalars['String'];
};

export type MemberDepositDetailOutput = {
  __typename?: 'MemberDepositDetailOutput';
  giftDeposit: Scalars['Float'];
  purchaseDeposit: Scalars['Float'];
};

export type MemberGiftHistory = {
  __typename?: 'MemberGiftHistory';
  changeAmount: Scalars['Int'];
  created: Scalars['DateTime'];
  giftResidualAmount: Scalars['Int'];
  memNo: Scalars['Int'];
  orderProduct?: Maybe<OrderProductClone>;
  totalResidualAmount: Scalars['Int'];
};

export type Menu = {
  __typename?: 'Menu';
  name: Scalars['String'];
};

export type ModelConfig = {
  __typename?: 'ModelConfig';
  i18n: Array<ModelConfigI18n>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 이름 */
  name: Scalars['String'];
};

export type ModelConfigI18n = {
  __typename?: 'ModelConfigI18n';
  /** 고유 ID */
  id: Scalars['Float'];
  language?: Maybe<Language>;
  /** 모델구성 id */
  modelConfigId: Scalars['Float'];
  name: Scalars['String'];
};

export type ModelConfigI18nClone = {
  __typename?: 'ModelConfigI18nClone';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  abort: Scalars['Boolean'];
  acceptTermRenew: Scalars['Boolean'];
  adminStoreBrandIntroductions: Array<BrandI18nClone>;
  adminStoreBrandPhoto?: Maybe<UploadFileClone>;
  attendanceEvent: Scalars['Boolean'];
  augustAttendanceEvent: Scalars['Boolean'];
  authenticate: Scalars['Boolean'];
  cancelBrandPromotion: Scalars['Boolean'];
  /** Cancel one inquiry */
  cancelInquiry: Inquiry;
  changePartnerSettleStatus: Scalars['Boolean'];
  changePasswordPartner: UserOutput;
  changeReviewStatus: Scalars['Boolean'];
  /** Clip/Unclip an Inquiry */
  clipInquiry: Inquiry;
  complete: Scalars['Boolean'];
  completeOpenrunPromotion: OpenrunPromotionAdminOutput;
  createAconNotification?: Maybe<AconNotification>;
  createAdminPartner: Scalars['Boolean'];
  createApplication: ApplicationEntity;
  createBadge: Scalars['Boolean'];
  createBandBanner: BandBanner;
  createBanner: BannerClone;
  /** 사업자 정보가 맞는지 확인하고 등록하는 메소드 입니다. */
  createBusiness: Scalars['Boolean'];
  createCategory: Category;
  createClip?: Maybe<Clip>;
  createCouponBenefit: CouponBenefit;
  createEvent: Scalars['Boolean'];
  /** 이벤트 배너를 생성하는 메소드입니다. */
  createEventBanner: Scalars['Boolean'];
  createExtensionConfig: ExtensionConfig;
  createFaq: Faq;
  createFaqAcon: FaqAconModel;
  createFaqCreator: FaqCreatorModel;
  /** 친구를 추가하는 뮤테이션 */
  createFriend: Scalars['Boolean'];
  createGift: Gift;
  createInquiry: Inquiry;
  createInquiryAnswer: Inquiry;
  createInquiryMemo: Inquiry;
  createNotice: Notice;
  createOrderMemo: OrderAdminMemo;
  createPartner: UserOutput;
  createPointBenefit: PointBenefit;
  createPopup: PopupV2;
  createTermAgree: Scalars['Boolean'];
  decemberAttendanceEvent: Scalars['Boolean'];
  deleteAconNotification: Scalars['Int'];
  deleteAconfaq: Scalars['Boolean'];
  deleteBadge: Scalars['Boolean'];
  deleteBandBanner: Scalars['Boolean'];
  deleteBanner: Scalars['Boolean'];
  deleteCartCache: Scalars['Boolean'];
  deleteCategory: Scalars['Boolean'];
  deleteCouponBenefit: Scalars['Boolean'];
  deleteDocument: Scalars['Boolean'];
  /** 이벤트 배너를 삭제하는 메소드 입니다. */
  deleteEventBanner: Scalars['Boolean'];
  deleteExtensionConfig: Scalars['Int'];
  deleteFaq: Scalars['Boolean'];
  deleteFaqAcon: Scalars['Boolean'];
  deleteFaqCreator: Scalars['Boolean'];
  deleteGift: Scalars['Boolean'];
  deleteMainGroup: Scalars['Boolean'];
  deleteMemo: Scalars['Boolean'];
  deleteMyReview: Scalars['Boolean'];
  deleteNotice: Scalars['Boolean'];
  deleteOpenrunPromotion: Scalars['Boolean'];
  deletePointBenefit: Scalars['Boolean'];
  deletePopup: Scalars['Int'];
  deletePromotion: Scalars['Boolean'];
  deletePromotionConcept: Scalars['Boolean'];
  deletePromotionItem: Scalars['Boolean'];
  deleteReviewReply: Scalars['Boolean'];
  deny: Scalars['Boolean'];
  februaryAttendanceEvent: Scalars['Boolean'];
  fixFaq: Faq;
  fixNotice: Notice;
  godoPasswordChange: Scalars['Boolean'];
  hideAssets: Scalars['Boolean'];
  increaseDownloadCount?: Maybe<DownloadCount>;
  issueEventCoupon: Scalars['Boolean'];
  julyAttendanceEvent: Scalars['Boolean'];
  juneAttendanceEvent: Scalars['Boolean'];
  leavePartner: Scalars['Boolean'];
  manuallyRegisterPromotion?: Maybe<PromotionOutput>;
  mayAttendanceEvent: Scalars['Boolean'];
  memo: Scalars['Boolean'];
  memoV2: DocumentMemoOutput;
  modify: Scalars['Boolean'];
  modifyMemo: Scalars['Boolean'];
  modifyMemoV2: DocumentMemoOutput;
  octoberAttendanceEvent: Scalars['Boolean'];
  pack: Scalars['Boolean'];
  participateChristmasEvent: ParticipateChristmasEventOutput;
  passwordChange: Scalars['Boolean'];
  passwordChangeForAnon: Scalars['Boolean'];
  passwordChangeSendEmail: Scalars['Boolean'];
  /** 장바구니엝 제품을 추가하는 메소드 */
  productAddCart: Scalars['Boolean'];
  /** 장바구니에 상품 목록을 추가하는 메소드 입니다. */
  productItemsAddCart: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  registerAssetFunding: AssetFunding;
  registerBrandPromotion: BrandPromotionOutput;
  registerOpenrunPromotion: OpenrunPromotionHubOutput;
  registerPartnerMemo: UserMemoOutput;
  registerPromotion: PromotionOutput;
  registerReviewReply: ReviewReply;
  registerReviewV2?: Maybe<Review>;
  registerSnsMember: Scalars['Boolean'];
  registerSnsMemberV2: RegisterSnsMemberOutput;
  reject: Scalars['Boolean'];
  rejectOpenrunPromotion: OpenrunPromotionAdminOutput;
  remakeContract: Scalars['Boolean'];
  removeClip: Scalars['Int'];
  removeClips: Scalars['Int'];
  removePromotions: Scalars['Boolean'];
  reorderAconfaq: FaqAconModel;
  /** 이벤트 배너 순서를 변경하는 메소드 입니다. */
  reorderEventBanner: Scalars['Boolean'];
  reorderFaqAcon: FaqAconModel;
  reorderFaqCreator: FaqCreatorModel;
  request: Scalars['Boolean'];
  requestPartnerRevision: Scalars['Boolean'];
  requestPartnerSettle: Scalars['Boolean'];
  retry: Scalars['Boolean'];
  revise: Scalars['Boolean'];
  save: Scalars['Boolean'];
  saveCategory: Scalars['Boolean'];
  saveConfig: Scalars['Boolean'];
  saveContentBody: Scalars['Boolean'];
  saveContentHead: Scalars['Boolean'];
  saveCopyright: Scalars['Boolean'];
  saveFile: Scalars['Boolean'];
  saveMainImage: Scalars['Boolean'];
  savePromotion: PromotionOutput;
  saveSubImage: Scalars['Boolean'];
  saveTitle: Scalars['Boolean'];
  scrapReview?: Maybe<Review>;
  sendAuthenticationCode: Scalars['Boolean'];
  septemberAttendanceEvent: Scalars['Boolean'];
  setBenefitBurdenItem: Scalars['Boolean'];
  setBrand: Scalars['Boolean'];
  setCouponBurdenItem: Scalars['Boolean'];
  setFavoriteReview?: Maybe<Scalars['Int']>;
  setMainGroup: Scalars['Boolean'];
  /** 생품의 생세페이지 - 파일엝서 띰이터 기록용으로 호출하는 mutation 메소드입니다. */
  setProductFileView: Scalars['Boolean'];
  setSearchKeyword: Scalars['Boolean'];
  showAssets: Scalars['Boolean'];
  storeBrandIntroduction: Array<BrandI18nClone>;
  storeBrandPhoto: UploadFileClone;
  storePromotionConcept: PromotionConceptOutput;
  suspendPromotion: PromotionOutput;
  syncBrands: Scalars['Boolean'];
  syncCategory: Array<Category>;
  syncExchangeRate: Scalars['Boolean'];
  syncMainGroups: Scalars['Boolean'];
  updateAconfaq: FaqAconModel;
  updateAdminBrandPick: BrandClone;
  updateAdminBrandReview: BrandClone;
  updateApplication: Scalars['Int'];
  updateAssetFunding: AssetFunding;
  updateBadge: Scalars['Boolean'];
  updateBandBanner: BandBanner;
  updateBanner: BannerClone;
  updateBrandPick: BrandClone;
  updateBrandReview: BrandClone;
  updateCategory: Scalars['Boolean'];
  updateCouponBenefit: CouponBenefit;
  /** 이벤트 배너를 수정하는 메소드입니다. */
  updateEventBanner: Scalars['Boolean'];
  updateExtensionConfig: ExtensionConfig;
  updateFaq: Faq;
  updateFaqAcon: FaqAconModel;
  updateFaqCreator: FaqCreatorModel;
  updateGift: Gift;
  updateGodoHitCount: Scalars['Boolean'];
  /** Modify the inquiry answer */
  updateInquiryAnswer: InquiryAnswer;
  updateNotice: Notice;
  /** 주문 저작권 정보를 수정하는 함수입니다. */
  updateOrderLicense: Scalars['Boolean'];
  updatePartnerAdmin: UserOutput;
  updatePartnerSettleAdmin: UserSettleOutput;
  updatePopup: PopupV2;
  updatePromotion: PromotionOutput;
  updatePromotionConcept: PromotionConceptOutput;
  updateReviewReply: ReviewReply;
  updateReviewV2?: Maybe<Review>;
  uploadBlobStorage: UploadFile;
  uploadEditorImage: Scalars['String'];
  uploadGoodsAtCategoryBatch?: Maybe<Scalars['String']>;
  uploadGoodsAtMainGroupBatch?: Maybe<Scalars['String']>;
};

export type MutationAbortArgs = {
  input: DocumentCommonInput;
};

export type MutationAcceptTermRenewArgs = {
  planId?: InputMaybe<Scalars['Int']>;
};

export type MutationAdminStoreBrandIntroductionsArgs = {
  brandId: Scalars['Int'];
  introductions: Array<LanguageTextInput>;
};

export type MutationAdminStoreBrandPhotoArgs = {
  brandId: Scalars['Int'];
  photo?: InputMaybe<ProfilePhoto>;
};

export type MutationAttendanceEventArgs = {
  eventId: Scalars['Float'];
  isWeekend?: InputMaybe<Scalars['Boolean']>;
  languageCode: Scalars['String'];
};

export type MutationAugustAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type MutationAuthenticateArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type MutationCancelBrandPromotionArgs = {
  input: CancelBrandPromotionInput;
};

export type MutationCancelInquiryArgs = {
  input: CancelInquiryInput;
};

export type MutationChangePartnerSettleStatusArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  status: PartnerSettleStatusEnum;
};

export type MutationChangePasswordPartnerArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type MutationChangeReviewStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type MutationClipInquiryArgs = {
  input: ClipInquiryInput;
};

export type MutationCompleteArgs = {
  input: DocumentCompleteInput;
};

export type MutationCompleteOpenrunPromotionArgs = {
  input: CompleteOpenrunPromotionInput;
};

export type MutationCreateAconNotificationArgs = {
  memNo?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type MutationCreateAdminPartnerArgs = {
  account: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};

export type MutationCreateBadgeArgs = {
  endDate: Scalars['DateTime'];
  godoGoodsNos: Array<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  isAutoCreate?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
  texts: Array<BadgeI18nInput>;
};

export type MutationCreateBandBannerArgs = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  closeColor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  href?: InputMaybe<Scalars['String']>;
  isBlankHref?: InputMaybe<Scalars['Boolean']>;
  isClosedShowId?: InputMaybe<Scalars['Int']>;
  langId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateBannerArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  href?: InputMaybe<Scalars['String']>;
  imageKeys?: InputMaybe<ImageKeys>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  selectedProductsId?: InputMaybe<Array<Scalars['Int']>>;
  showOnlyForSelectedProducts?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateBusinessArgs = {
  bizName: Scalars['String'];
  bizNum: Scalars['String'];
  openDate: Scalars['String'];
  representativeName: Scalars['String'];
};

export type MutationCreateCategoryArgs = {
  creator?: InputMaybe<Scalars['Float']>;
  memo: Scalars['String'];
  name: Scalars['String'];
  parentCategoryId?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  type: Scalars['String'];
  updater?: InputMaybe<Scalars['Float']>;
};

export type MutationCreateClipArgs = {
  createClipInput: CreateClipInput;
};

export type MutationCreateCouponBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']>>;
  applyTypeId?: InputMaybe<Scalars['Int']>;
  godoCouponNo?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
};

export type MutationCreateEventBannerArgs = {
  bannerTextFirst: Scalars['String'];
  bannerTextSecond: Scalars['String'];
  bannerTextThird: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<ImageKeys>;
  langId: Scalars['Int'];
  settingLink: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type MutationCreateExtensionConfigArgs = {
  input: CreateExtensionConfigInput;
};

export type MutationCreateFaqArgs = {
  body?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateFaqAconArgs = {
  body?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  typeId: Scalars['Int'];
};

export type MutationCreateFaqCreatorArgs = {
  body?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  typeId: Scalars['Int'];
};

export type MutationCreateFriendArgs = {
  code: Scalars['String'];
  godoMemNo: Scalars['Int'];
  langCode: Scalars['String'];
};

export type MutationCreateGiftArgs = {
  createGiftInput: CreateGiftInput;
};

export type MutationCreateInquiryArgs = {
  input: InquiryInput;
};

export type MutationCreateInquiryAnswerArgs = {
  input: CreateInquiryAnswerInput;
};

export type MutationCreateInquiryMemoArgs = {
  input: CreateInquiryMemoInput;
};

export type MutationCreateNoticeArgs = {
  body?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateOrderMemoArgs = {
  input: CreateOrderMemoInput;
};

export type MutationCreatePartnerArgs = {
  brand: CreatePartnerBrandInput;
  file: CreatePartnerSettleFileInput;
  user: CreatePartnerInput;
};

export type MutationCreatePointBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']>>;
  applyTypeId?: InputMaybe<Scalars['Int']>;
  defaultApply?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  langId?: InputMaybe<Scalars['Int']>;
  payPoint?: InputMaybe<Scalars['String']>;
  pointUnit?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationCreatePopupArgs = {
  createPopupInput: CreatePopupInput;
};

export type MutationCreateTermAgreeArgs = {
  sign: FileInput;
  term: FileInput;
};

export type MutationDecemberAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type MutationDeleteAconNotificationArgs = {
  type: Array<Scalars['String']>;
};

export type MutationDeleteAconfaqArgs = {
  id: Scalars['Int'];
  oldOrder?: InputMaybe<Scalars['Int']>;
};

export type MutationDeleteBadgeArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteBandBannerArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteBannerArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['Float'];
};

export type MutationDeleteCouponBenefitArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type MutationDeleteDocumentArgs = {
  document_id: Scalars['String'];
};

export type MutationDeleteEventBannerArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteExtensionConfigArgs = {
  input: DeleteExtensionConfigInput;
};

export type MutationDeleteFaqArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteFaqAconArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteFaqCreatorArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteGiftArgs = {
  deleteGiftInput: DeleteGiftInput;
};

export type MutationDeleteMainGroupArgs = {
  mainGroupId: Scalars['Int'];
};

export type MutationDeleteMemoArgs = {
  input: DocumentMemoInput;
};

export type MutationDeleteMyReviewArgs = {
  id: Scalars['Int'];
  languageCode: Scalars['String'];
};

export type MutationDeleteNoticeArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteOpenrunPromotionArgs = {
  input: DeleteOpenrunPromotionInput;
};

export type MutationDeletePointBenefitArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type MutationDeletePopupArgs = {
  deletePopupInput: DeletePopupInput;
};

export type MutationDeletePromotionArgs = {
  input: DeletePromotionInput;
};

export type MutationDeletePromotionConceptArgs = {
  input: DeletePromotionConceptInput;
};

export type MutationDeletePromotionItemArgs = {
  input: DeletePromotionTwinkleAssetInput;
};

export type MutationDeleteReviewReplyArgs = {
  id: Scalars['Int'];
};

export type MutationDenyArgs = {
  input: DocumentCommonInput;
};

export type MutationFebruaryAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type MutationFixFaqArgs = {
  fixed?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export type MutationFixNoticeArgs = {
  fixed?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export type MutationGodoPasswordChangeArgs = {
  key: Scalars['String'];
  password: Scalars['String'];
};

export type MutationHideAssetsArgs = {
  input: HideAssetsInput;
};

export type MutationIncreaseDownloadCountArgs = {
  id: Scalars['Int'];
};

export type MutationIssueEventCouponArgs = {
  issueEventCouponInput: IssueEventCouponInput;
};

export type MutationJulyAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type MutationJuneAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type MutationLeavePartnerArgs = {
  id: Scalars['Int'];
};

export type MutationManuallyRegisterPromotionArgs = {
  id: Scalars['Int'];
  passcode: Scalars['String'];
};

export type MutationMayAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type MutationMemoArgs = {
  input: DocumentMemoInput;
};

export type MutationMemoV2Args = {
  input: DocumentMemoInput;
};

export type MutationModifyArgs = {
  input: DocumentCommonInput;
};

export type MutationModifyMemoArgs = {
  input: DocumentMemoInput;
};

export type MutationModifyMemoV2Args = {
  input: DocumentMemoInput;
};

export type MutationOctoberAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type MutationPackArgs = {
  input: PackageInput;
};

export type MutationParticipateChristmasEventArgs = {
  languageCode: Scalars['String'];
};

export type MutationPasswordChangeArgs = {
  key?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type MutationPasswordChangeForAnonArgs = {
  account: Scalars['String'];
  brandName: Scalars['String'];
};

export type MutationPasswordChangeSendEmailArgs = {
  lang: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationProductAddCartArgs = {
  goodsNo: Scalars['String'];
};

export type MutationProductItemsAddCartArgs = {
  goodsNos: Array<Scalars['String']>;
};

export type MutationReadNotificationArgs = {
  type?: InputMaybe<Scalars['String']>;
};

export type MutationRegisterAssetFundingArgs = {
  input: AssetFundingInput;
};

export type MutationRegisterBrandPromotionArgs = {
  input: RegisterBrandPromotionInput;
};

export type MutationRegisterOpenrunPromotionArgs = {
  input: RegisterOpenrunPromotionInput;
};

export type MutationRegisterPartnerMemoArgs = {
  data: RegisterPartnerMemoInput;
  id: Scalars['Int'];
};

export type MutationRegisterPromotionArgs = {
  input: RegisterPromotionInput;
};

export type MutationRegisterReviewReplyArgs = {
  contents: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  languageCode: Scalars['String'];
  reviewId: Scalars['Int'];
};

export type MutationRegisterReviewV2Args = {
  input: RegisterReviewInput;
};

export type MutationRegisterSnsMemberArgs = {
  input: AconSnsMemberInput;
};

export type MutationRegisterSnsMemberV2Args = {
  input: AconSnsMemberInput;
};

export type MutationRejectArgs = {
  input: DocumentCommonInput;
};

export type MutationRejectOpenrunPromotionArgs = {
  input: RejectOpenrunPromotionInput;
};

export type MutationRemakeContractArgs = {
  userId: Scalars['Int'];
};

export type MutationRemoveClipArgs = {
  removeClipInput: RemoveClipInput;
};

export type MutationRemoveClipsArgs = {
  removeClipsInput: RemoveClipsInput;
};

export type MutationRemovePromotionsArgs = {
  ids: Array<Scalars['Int']>;
};

export type MutationReorderAconfaqArgs = {
  id: Scalars['Int'];
  langId?: InputMaybe<Scalars['Int']>;
  newOrder?: InputMaybe<Scalars['Int']>;
  oldOrder?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationReorderEventBannerArgs = {
  id: Scalars['Int'];
  newOrder: Scalars['Int'];
  oldOrder: Scalars['Int'];
};

export type MutationReorderFaqAconArgs = {
  id: Scalars['Int'];
  lang?: InputMaybe<Scalars['String']>;
  newOrder: Scalars['Int'];
  oldOrder: Scalars['Int'];
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationReorderFaqCreatorArgs = {
  id: Scalars['Int'];
  lang?: InputMaybe<Scalars['String']>;
  newOrder: Scalars['Int'];
  oldOrder: Scalars['Int'];
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationRequestArgs = {
  input: DocumentCommonInput;
};

export type MutationRequestPartnerRevisionArgs = {
  user: RequestPartnerRevisionInput;
};

export type MutationRequestPartnerSettleArgs = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  bankBook?: InputMaybe<StoredFileInput>;
  bankId?: InputMaybe<Scalars['Int']>;
  bankName?: InputMaybe<Scalars['String']>;
  idCard?: InputMaybe<StoredFileInput>;
  limitedTaxApl?: InputMaybe<StoredFileInput>;
};

export type MutationRetryArgs = {
  input: DocumentCommonInput;
};

export type MutationReviseArgs = {
  input: DocumentCommonInput;
};

export type MutationSaveArgs = {
  input: SingleDocumentInput;
};

export type MutationSaveCategoryArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveConfigArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveContentBodyArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveContentHeadArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveCopyrightArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveFileArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveMainImageArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSavePromotionArgs = {
  input: SavePromotionInput;
};

export type MutationSaveSubImageArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveTitleArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationScrapReviewArgs = {
  id: Scalars['Int'];
};

export type MutationSendAuthenticationCodeArgs = {
  email: Scalars['String'];
  lang: Scalars['String'];
};

export type MutationSeptemberAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type MutationSetBenefitBurdenItemArgs = {
  burden?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type MutationSetBrandArgs = {
  brandId: Scalars['Int'];
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
};

export type MutationSetCouponBurdenItemArgs = {
  burden?: InputMaybe<Scalars['Int']>;
  sno?: InputMaybe<Scalars['Int']>;
};

export type MutationSetFavoriteReviewArgs = {
  reviewId: Scalars['Int'];
};

export type MutationSetMainGroupArgs = {
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
  mainGroupId: Scalars['Int'];
};

export type MutationSetProductFileViewArgs = {
  goodsNo: Scalars['String'];
  lang: Scalars['String'];
};

export type MutationSetSearchKeywordArgs = {
  creator?: InputMaybe<Scalars['Float']>;
  keyword: Scalars['String'];
  lang: Scalars['String'];
  updater?: InputMaybe<Scalars['Float']>;
};

export type MutationShowAssetsArgs = {
  input: ShowAssetsInput;
};

export type MutationStoreBrandIntroductionArgs = {
  introduction: Scalars['String'];
  lang: LanguageCodeEnum;
};

export type MutationStoreBrandPhotoArgs = {
  photo?: InputMaybe<ProfilePhoto>;
};

export type MutationStorePromotionConceptArgs = {
  input: StorePromotionConceptInput;
};

export type MutationSuspendPromotionArgs = {
  input: SuspendPromotionInput;
};

export type MutationUpdateAconfaqArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  langId?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateAdminBrandPickArgs = {
  assetIds: Array<Scalars['Int']>;
  brandId: Scalars['Int'];
  isOn: Scalars['Boolean'];
};

export type MutationUpdateAdminBrandReviewArgs = {
  brandId: Scalars['Int'];
  isOn: Scalars['Boolean'];
};

export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

export type MutationUpdateAssetFundingArgs = {
  input: AssetFundingInput;
};

export type MutationUpdateBadgeArgs = {
  endDate: Scalars['DateTime'];
  godoGoodsNos: Array<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  isAutoCreate?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
  texts: Array<BadgeI18nInput>;
};

export type MutationUpdateBandBannerArgs = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  closeColor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  href?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isBlankHref?: InputMaybe<Scalars['Boolean']>;
  isClosedShowId?: InputMaybe<Scalars['Int']>;
  langId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateBannerArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  href?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  imageKeys?: InputMaybe<ImageKeys>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  selectedProductsId?: InputMaybe<Array<Scalars['Int']>>;
  showOnlyForSelectedProducts?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateBrandPickArgs = {
  assetIds: Array<Scalars['Int']>;
  isOn: Scalars['Boolean'];
};

export type MutationUpdateBrandReviewArgs = {
  isOn: Scalars['Boolean'];
};

export type MutationUpdateCategoryArgs = {
  creator?: InputMaybe<Scalars['Float']>;
  i18n: Array<SetCategoryI18nInput>;
  id: Scalars['Float'];
  memo: Scalars['String'];
  parent?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  type?: InputMaybe<CategoryTypeEnum>;
  updater?: InputMaybe<Scalars['Float']>;
};

export type MutationUpdateCouponBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']>>;
  applyTypeId?: InputMaybe<Scalars['Int']>;
  godoCouponNo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  langId?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateEventBannerArgs = {
  bannerTextFirst: Scalars['String'];
  bannerTextSecond: Scalars['String'];
  bannerTextThird: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<ImageKeys>;
  langId: Scalars['Int'];
  settingLink: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type MutationUpdateExtensionConfigArgs = {
  input: UpdateExtensionConfigInput;
};

export type MutationUpdateFaqArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  langId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateFaqAconArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lang: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  typeId: Scalars['Int'];
};

export type MutationUpdateFaqCreatorArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lang: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  typeId: Scalars['Int'];
};

export type MutationUpdateGiftArgs = {
  updateGiftInput: UpdateGiftInput;
};

export type MutationUpdateGodoHitCountArgs = {
  goodsNo: Scalars['String'];
};

export type MutationUpdateInquiryAnswerArgs = {
  input: UpdateInquiryAnswerInput;
};

export type MutationUpdateNoticeArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  langId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateOrderLicenseArgs = {
  adminEmail?: InputMaybe<Scalars['String']>;
  names: Array<Scalars['String']>;
  orderNo: Scalars['Float'];
  projects?: InputMaybe<Array<OrderLicenseProjects>>;
  type: Scalars['String'];
};

export type MutationUpdatePartnerAdminArgs = {
  data: UpdatePartnerAdminInput;
  id: Scalars['Int'];
};

export type MutationUpdatePartnerSettleAdminArgs = {
  data: UpdatePartnerSettleAdminInput;
  id: Scalars['Int'];
};

export type MutationUpdatePopupArgs = {
  updatePopupInput: UpdatePopupInput;
};

export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput;
};

export type MutationUpdatePromotionConceptArgs = {
  input: UpdatePromotionConceptInput;
};

export type MutationUpdateReviewReplyArgs = {
  contents: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  languageCode: Scalars['String'];
  reviewId: Scalars['Int'];
};

export type MutationUpdateReviewV2Args = {
  input: UpdateReviewInput;
};

export type MutationUploadBlobStorageArgs = {
  inputs: UploadBlobStorageInput;
};

export type MutationUploadEditorImageArgs = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type MutationUploadGoodsAtCategoryBatchArgs = {
  data: Array<CategoryGoods>;
};

export type MutationUploadGoodsAtMainGroupBatchArgs = {
  data: Array<MainGroupGoods>;
};

export type MyOrderReturn = {
  __typename?: 'MyOrderReturn';
  /** 최대/잔여 다운로드 횟수 정보 */
  downloadCountInfo?: Maybe<DownloadCountInfo>;
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Int'];
  /** 고도몰 주문번호 */
  godoOrderNo: Scalars['Float'];
  /** 몰 언어 정보 */
  languageCode: LanguageCodeEnum;
  /** 회원 번호 */
  memNo: Scalars['Int'];
  /** 상품 주문 상태 */
  orderGoodsStatus: OrderDisplayStatusEnum;
  /** 결제 가격 */
  price: Scalars['Int'];
  /** 상품 카드 정보 */
  product?: Maybe<Acon3dProduct>;
  /** 주문 체결 날짜 */
  regDt: Scalars['String'];
  /** 리뷰 작성 여부 */
  reviewId?: Maybe<Scalars['Int']>;
  /** 회원 아이디 */
  userId: Scalars['String'];
};

export type Notice = {
  __typename?: 'Notice';
  /** 공지사항 내용(바디) */
  body?: Maybe<Scalars['String']>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 고정글인 경우 고정 순서(번호) */
  fixed?: Maybe<Scalars['Float']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 공지사항을 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** 공지사항 제목 */
  title?: Maybe<Scalars['String']>;
  /** 공지사항 타입(공지, 이벤트, 일반, ...) */
  type?: Maybe<NoticeType>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type NoticeType = {
  __typename?: 'NoticeType';
  /** 공지사항 타입 코드(notice, event, normal, ...) */
  code: Scalars['String'];
  /** 공지사항 타입 id */
  id: Scalars['Float'];
  /** 공지사항 타입 이름(공지, 이벤트, 일반, ...) */
  name: Scalars['String'];
};

export enum Ordered_Products_Input_Condition_Order_State {
  Confirmed = 'CONFIRMED',
  Settled = 'SETTLED',
}

export enum Ordered_Products_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Ordered_Products_Input_Sort_Key {
  CreatedAt = 'CREATED_AT',
  OrderCount = 'ORDER_COUNT',
}

export type OpenrunPromotionAdminOutput = {
  __typename?: 'OpenrunPromotionAdminOutput';
  /** 오픈런 신청 도큐먼트 ID (document.id) */
  id: Scalars['String'];
  /** 거절 사유, 승인상태가 거절시 필수 */
  rejectReason?: Maybe<Scalars['String']>;
  /** 오픈런 승인 상태(승인, 진행중, 거절, 심사중, 종료...) */
  status: OpenrunStatusEnum;
};

export type OpenrunPromotionHubOutput = {
  __typename?: 'OpenrunPromotionHubOutput';
  /** 오픈런 신청 상품 ID (asset.id) */
  assetId?: Maybe<Scalars['Int']>;
  /** 오픈런 신청 상품명 */
  assetName?: Maybe<Scalars['String']>;
  /** 오픈런 신청 도큐먼트 ID (document.id) */
  id: Scalars['String'];
  /** 거절 사유, 승인상태가 거절시 필수 */
  rejectReason?: Maybe<Scalars['String']>;
  since?: Maybe<Scalars['DateTime']>;
  /** 오픈런 승인 상태(승인(completed), 진행중(opened), 거절(rejected), 심사중(in_review), 종료(closed)...) */
  status: OpenrunStatusEnum;
  until?: Maybe<Scalars['DateTime']>;
};

export type OpenrunPromotionHubPaginatedOutput = {
  __typename?: 'OpenrunPromotionHubPaginatedOutput';
  data: Array<OpenrunPromotionHubOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
  /** 오픈런 상태별 총 개수 */
  stateSpecificTotal: Array<StateSpecificTotal>;
};

export enum OpenrunStatusEnum {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  InReview = 'IN_REVIEW',
  Opened = 'OPENED',
  Rejected = 'REJECTED',
}

export type OrderAdminMemo = {
  __typename?: 'OrderAdminMemo';
  adminUser: AdminUser;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  memo: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type OrderAdminRefundModalReturn = {
  __typename?: 'OrderAdminRefundModalReturn';
  orderNo: Scalars['String'];
  refundCash: Scalars['Int'];
  refundPoint: Scalars['Int'];
  refundPrice: Scalars['Int'];
  settleKind: Scalars['String'];
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderCouponItems = {
  __typename?: 'OrderCouponItems';
  /** 쿠폰 사용 가능 카테고리 목록 */
  couponApplyCategory?: Maybe<Array<CouponApplyCategory>>;
  /** 쿠폰 사용 가능 상품 목록 */
  couponApplyGoods?: Maybe<Array<CouponApplyGoods>>;
  /** 쿠폰 사용 가능 타입 (category, brand, goods) */
  couponApplyProductType: Scalars['String'];
  /** 쿠폰 혜택 */
  couponBenefit: Scalars['Int'];
  /** 쿠폰 혜택 타입 (percent, fix) */
  couponBenefitType: Scalars['String'];
  /** 쿠폰 종류(sale, add, deposit) */
  couponKindType: Scalars['String'];
  /** 쿠폰 최대 혜택 */
  couponMaxBenefit: Scalars['Int'];
  /** 최소 주문 금액 */
  couponMinOrderPrice: Scalars['Int'];
  /** 최소 주문 금액 기준 (product, order), default: product */
  couponMinOrderType?: Maybe<Scalars['String']>;
  /** 쿠폰 ID */
  couponNo: Scalars['Int'];
  /** 쿠폰 할인 가격 (주문 쿠폰) */
  discountPrice?: Maybe<Scalars['Int']>;
  /** 쿠폰 사용 가능 종료 시간 */
  endDate: Scalars['DateTime'];
  /** 사용 가능 여부 */
  isAvailable: Scalars['Boolean'];
  /** 쿠폰 중복 사용 가능 여부 */
  isCouponApplyDuplicate: Scalars['Boolean'];
  /** 쿠폰 최대 혜택 제한 존재 여부 */
  isCouponMaxBenefit: Scalars['Boolean'];
  /** 상품 적용 쿠폰 여부 */
  isProductCoupon: Scalars['Boolean'];
  /** 유저 쿠폰 ID */
  memberCouponNo: Scalars['Int'];
  /** 쿠폰명 */
  name: Scalars['String'];
  /** 적용 가능한 상품 목록 */
  products?: Maybe<Array<OrderCouponProducts>>;
  /** 쿠폰 발급된 시간 */
  regDt: Scalars['DateTime'];
  /** 쿠폰 사용 가능 시작 시간 */
  startDate: Scalars['DateTime'];
};

export type OrderCouponProducts = {
  __typename?: 'OrderCouponProducts';
  /** 상품 할인 가격 */
  discountPrice: Scalars['String'];
  /** 상품 고유 번호 */
  godoGoodsNo: Scalars['String'];
};

export type OrderCouponReturn = {
  __typename?: 'OrderCouponReturn';
  appliedProductName?: Maybe<Scalars['String']>;
  couponBenefit: Scalars['String'];
  couponName: Scalars['String'];
  couponType: Scalars['String'];
};

export type OrderData = {
  __typename?: 'OrderData';
  /** 추가 필드 사용 여부 (y, n) */
  addFieldConf: Scalars['String'];
  /** 장바구니 추가 상품 개수 */
  cartAddGoodsCnt: Scalars['Int'];
  /** 장바구니 상품 개수 */
  cartGoodsCnt: Scalars['Int'];
  /** 주문 상품 정보 */
  cartInfo: Array<CartInfo>;
  /** 쿠폰 적용 타입 (all, member, coupon ...) */
  chooseCouponMemberUseType: Scalars['String'];
  /** 마일리지 / 쿠폰 선택 (y, n) */
  chooseMileageCoupon: Scalars['String'];
  /** 이메일 타입 */
  emailDomain: Array<Scalars['String']>;
  isGlobal: Scalars['Boolean'];
  /** 상품쿠폰 주문서 제한여부 */
  productCouponChangeLimitType: Scalars['String'];
  /** token */
  token: Scalars['String'];
  /** 상품 쿠폰 총 할인 금액 */
  totalCouponGoodsDcPrice: Scalars['Int'];
  /** 상품 쿠폰 총 적립 마일리지 */
  totalCouponGoodsMileage: Scalars['Int'];
  /** 회원 총 할인 금액 */
  totalMemberDcPrice: Scalars['Int'];
  /** 그룹별 회원 중복 총 할인 금액 */
  totalMemberOverlapDcPrice: Scalars['Int'];
  /** 총 결제 금액 (예정) */
  totalSettlePrice: Scalars['Int'];
  /** 회원 정보 */
  userInfo: UserInfo;
};

export enum OrderDisplayStatusEnum {
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
  Completed = 'COMPLETED',
  DepositStandby = 'DEPOSIT_STANDBY',
  InPreparation = 'IN_PREPARATION',
  OnDelivery = 'ON_DELIVERY',
  PaymentAborted = 'PAYMENT_ABORTED',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentTried = 'PAYMENT_TRIED',
  RefundAsked = 'REFUND_ASKED',
  RefundCompleted = 'REFUND_COMPLETED',
  ReturnAsked = 'RETURN_ASKED',
}

export type OrderDto = {
  __typename?: 'OrderDto';
  /** 상품 금액 */
  amount: Scalars['Int'];
  /** 주문 시 입력한 전화번호 */
  cellPhone?: Maybe<Scalars['String']>;
  /** 주문 시 입력한 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 고도몰 장바구니 sno 배열 */
  godoCartSno: Array<Scalars['String']>;
  /** 고도몰 주문번호 */
  godoOrderId: Scalars['String'];
  /** 주문번호 */
  id: Scalars['String'];
  /** 주문 몰 */
  langCode: Scalars['String'];
  /** 주문 몰 */
  memNo: Scalars['Int'];
  /** 주문 시 입력한 이름 */
  name?: Maybe<Scalars['String']>;
  /** 결제 정보 */
  payment?: Maybe<OrderPaymentDto>;
  /** 주문 상태 */
  status?: Maybe<Scalars['String']>;
};

export type OrderEventHistory = {
  __typename?: 'OrderEventHistory';
  createdAt: Scalars['DateTime'];
  event: OrderEventTypeEnum;
  godoGoodsNo?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
};

export type OrderEventHistoryOutput = {
  __typename?: 'OrderEventHistoryOutput';
  /** 이벤트 발생일시 */
  createdAt: Scalars['DateTime'];
  /** 발생한 이벤트 */
  event: OrderEventTypeEnum;
  /** 상품번호(전체 주문에 대한 히스토리인 경우 0) */
  godoGoodsNo?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** 주문 id */
  orderId: Scalars['String'];
};

export enum OrderEventTypeEnum {
  Confirm = 'confirm',
  Download = 'download',
  Refund = 'refund',
  Settle = 'settle',
}

export type OrderGift = {
  __typename?: 'OrderGift';
  amount: Scalars['Float'];
  code: Scalars['String'];
  created: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
  gift: Gift;
  id: Scalars['String'];
  inputRecipientCellPhone: Scalars['String'];
  inputRecipientName: Scalars['String'];
  langCode: Scalars['String'];
  memberGiftHistories: Array<MemberGiftHistory>;
  orderPayment: OrderPayment;
  recipientMemNo?: Maybe<Scalars['Float']>;
  recipientUserId?: Maybe<Scalars['String']>;
  redemptionDate?: Maybe<Scalars['DateTime']>;
  sendMessage: Scalars['String'];
  senderMemNo: Scalars['Float'];
  senderName: Scalars['String'];
  senderUserId: Scalars['String'];
  status: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type OrderGoodsPriceInfoArgs = {
  goodsNos: Array<Scalars['Int']>;
  orderNo: Scalars['String'];
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  /** 에러 메세지 */
  errorMessage?: Maybe<Scalars['String']>;
  /** 성공 여부 */
  isSuccess?: Maybe<Scalars['Boolean']>;
  /** 주문 데이터 */
  orderData?: Maybe<OrderData>;
};

export type OrderLicenseAuthorOutput = {
  __typename?: 'OrderLicenseAuthorOutput';
  id: Scalars['Int'];
  name: Scalars['String'];
  orderLicenseId: Scalars['Int'];
};

export type OrderLicenseModel = {
  __typename?: 'OrderLicenseModel';
  /** 고유 ID */
  id: Scalars['Float'];
  /** 필명 정보 */
  names?: Maybe<Array<OrderLicenseNameModel>>;
  /** 주문번호 */
  orderNo: Scalars['String'];
  /** 프로젝트 정보 */
  projects?: Maybe<Array<OrderLicenseProjectModel>>;
  /** 유형 */
  type: Scalars['String'];
  /** 사용권 버전 */
  versions?: Maybe<Array<OrderLicenseVersionModel>>;
};

export type OrderLicenseNameModel = {
  __typename?: 'OrderLicenseNameModel';
  /** 고유 ID */
  id: Scalars['Float'];
  /** 필명 OR 작품 / 프로젝트명 */
  name: Scalars['String'];
  /** 주문의 사용권 ID  */
  orderLicenseId: Scalars['Float'];
};

export type OrderLicenseOutput = {
  __typename?: 'OrderLicenseOutput';
  /** 창작자/회사명 */
  authors: Array<OrderLicenseAuthorOutput>;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  /** 주문번호 */
  orderNo: Scalars['String'];
  /** 작품 */
  project: Array<OrderLicenseProjectOutput>;
  /** 라이선트 종류 */
  type: OrderLicenseType;
};

export type OrderLicenseProjectGoodsModel = {
  __typename?: 'OrderLicenseProjectGoodsModel';
  /** 상품번호 */
  goodsNo: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 주문 사용권 프로젝트의 ID */
  orderLicenseProjectId: Scalars['Float'];
};

export type OrderLicenseProjectModel = {
  __typename?: 'OrderLicenseProjectModel';
  /** 프로젝트 상품들 */
  goods?: Maybe<Array<OrderLicenseProjectGoodsModel>>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 작품 / 프로젝트 명 */
  name: Scalars['String'];
  /** 주문 사용권 ID */
  orderLicenseId: Scalars['Float'];
};

export type OrderLicenseProjectOutput = {
  __typename?: 'OrderLicenseProjectOutput';
  /** 적용 상품 코드 */
  goodsNos: Array<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  orderLicenseId: Scalars['Int'];
};

export type OrderLicenseProjects = {
  /** 상품번호 */
  goodsNo: Array<Scalars['String']>;
  /** 필명 */
  name: Scalars['String'];
};

export enum OrderLicenseType {
  Company = 'company',
  Enterprise_0 = 'enterprise_0',
  Enterprise_5 = 'enterprise_5',
  Personal = 'personal',
}

export type OrderLicenseVersionModel = {
  __typename?: 'OrderLicenseVersionModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 데이터 전체 JSON */
  contents?: Maybe<Scalars['String']>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 버전 Major */
  major?: Maybe<Scalars['Float']>;
  /** 버전 Minor */
  minor?: Maybe<Scalars['Float']>;
  /** 주문 사용권 ID */
  orderLicenseId: Scalars['Float'];
  /** 수정 일자 */
  updated: Scalars['DateTime'];
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  confirmDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  payMethod: Scalars['String'];
  receiptUrl?: Maybe<Scalars['String']>;
  vbankCode?: Maybe<Scalars['String']>;
  vbankHolder?: Maybe<Scalars['String']>;
  vbankIssuedAt?: Maybe<Scalars['DateTime']>;
  vbankName?: Maybe<Scalars['String']>;
  vbankNum?: Maybe<Scalars['String']>;
};

export type OrderPaymentDto = {
  __typename?: 'OrderPaymentDto';
  /** 입금 완료 일자 */
  confirmDate?: Maybe<Scalars['DateTime']>;
  /** 결제 통화 단위 정보 */
  currency: Scalars['String'];
  /** 결제 수단 */
  payMethod: Scalars['String'];
  /** 영수증 확인 URL (현금영수증, 매출전표) */
  receiptUrl?: Maybe<Scalars['String']>;
  /** 가상계좌 은행 코드 */
  vbankCode?: Maybe<Scalars['String']>;
  /** 가상계좌 예금자 명 */
  vbankHolder?: Maybe<Scalars['String']>;
  /** 가상계좌 송금 일자 */
  vbankIssuedAt?: Maybe<Scalars['String']>;
  /** 가상계좌 입금 은행 */
  vbankName?: Maybe<Scalars['String']>;
  /** 가상계좌 입금 계좌 번호 */
  vbankNum?: Maybe<Scalars['String']>;
};

export type OrderPaymentReturn = {
  __typename?: 'OrderPaymentReturn';
  /** 에이콘 자체 할인 금액 */
  aconDiscountPrice: Scalars['Int'];
  couponDiscountPrice: Scalars['Int'];
  /** 결제 금액 */
  payPrice: Scalars['Int'];
  /** 총 할인금액 */
  totalDiscountPrice: Scalars['Int'];
  /** 상품금액 */
  totalPrice: Scalars['Int'];
  useCash: Scalars['Int'];
  usePoint: Scalars['Int'];
};

export type OrderPrice = {
  __typename?: 'OrderPrice';
  /** 쿠폰 할인 금액 */
  couponGoodsDcPrice: Scalars['Float'];
  /** 정가 */
  fixedPrice: Scalars['Float'];
  /** 상품 할인 금액 */
  goodsDcPrice: Scalars['Float'];
  /** 상품별 가격 (상품 가격 + 옵션 가격 + 텍스트 옵션 가격 + 추가 상품 가격) */
  goodsPriceSubtotal: Scalars['Float'];
  /** 상품 합계 금액 */
  goodsPriceSum: Scalars['Float'];
  /** 회원 할인 금액 */
  memberDcPrice: Scalars['Float'];
  /** 회원 중복 할인 금액 */
  memberOverlapDcPrice: Scalars['Float'];
};

export type OrderProductClone = {
  __typename?: 'OrderProductClone';
  amount: Scalars['Float'];
  cellPhone: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  godoOrderId: Scalars['String'];
  id: Scalars['String'];
  langCode: LanguageCodeEnum;
  licenseUpdateHistories: Array<LicenseUpdateHistory>;
  licenses?: Maybe<Array<LicenseClone>>;
  memId: Scalars['String'];
  memNo: Scalars['Int'];
  memos?: Maybe<Array<OrderAdminMemo>>;
  name: Scalars['String'];
  orderProductItems: Array<OrderProductItemClone>;
  status: OrderStatus;
  useAconCash: Scalars['Int'];
  useGiftDeposit: Scalars['Int'];
  usePoint: Scalars['Int'];
};

export type OrderProductItemAggregation = {
  __typename?: 'OrderProductItemAggregation';
  /** 판매 금액 총합 */
  amount: Scalars['Int'];
  /** 환불 건수 */
  refundedCount: Scalars['Int'];
};

export type OrderProductItemClone = {
  __typename?: 'OrderProductItemClone';
  acon3dProduct: Acon3dProduct;
  fixedPrice: Scalars['Int'];
  godoGoodsNo: Scalars['Int'];
  goodsDcPrice: Scalars['Int'];
  id: Scalars['Int'];
  isCanceled: Scalars['Boolean'];
  isDownloaded: Scalars['Boolean'];
  isRefunded: Scalars['Boolean'];
  price: Scalars['Int'];
  salePrice: Scalars['Int'];
};

export type OrderProductItemOutput = {
  __typename?: 'OrderProductItemOutput';
  /** 프로모션 브랜드 부담도 */
  benefitBurden: Scalars['Int'];
  /** 프로모션 브랜드 부담 금액 */
  benefitBurdenPrice: Scalars['Int'];
  /** 상품 쿠폰 브랜드 부담도 */
  couponGoodsBurden: Scalars['Int'];
  /** 상품 쿠폰 브랜드 부담 금액 */
  couponGoodsBurdenPrice: Scalars['Int'];
  /** 상품 쿠폰 할인 금액 */
  couponGoodsDcPrice: Scalars['Int'];
  /** 주문 쿠폰 브랜드 부담도 */
  couponOrderBurden: Scalars['Int'];
  /** 주문 쿠폰 브랜드 부담 금액, 현재 항상 0 */
  couponOrderBurdenPrice: Scalars['Int'];
  /** 주문 쿠폰 할인 n분 금액 */
  divisionCouponOrderDcPrice: Scalars['Int'];
  /** 상품코드(assetId) */
  godoGoodsNo: Scalars['Int'];
  /** 프로모션 할인 금액 */
  goodsDcPrice: Scalars['Int'];
  id: Scalars['Int'];
  /** 결제 미완료 상태에서 취소 여부 */
  isCanceled: Scalars['Boolean'];
  /** 다운로드 여부 */
  isDownloaded: Scalars['Boolean'];
  /** 환불 여부 */
  isRefunded: Scalars['Boolean'];
  order: OrderProductOutput;
  /** 결제금액 */
  price: Scalars['Int'];
  product: ProductOutput;
  /** 환불 일시 */
  refundedAt?: Maybe<Scalars['DateTime']>;
  /** 판매금액 */
  salePrice: Scalars['Int'];
  /** 브랜드 부담 총액 */
  totalBurdenPrice: Scalars['Int'];
};

export type OrderProductItemOutputProductArgs = {
  lang: LanguageCodeEnum;
};

export type OrderProductItemPaginatedOutput = {
  __typename?: 'OrderProductItemPaginatedOutput';
  /** 집계 항목 */
  aggregation: OrderProductItemAggregation;
  data: Array<OrderProductItemOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type OrderProductOutput = {
  __typename?: 'OrderProductOutput';
  /** 결제금액 */
  amount: Scalars['Float'];
  /** 구매자 전화번호 */
  cellPhone?: Maybe<Scalars['String']>;
  /** 주문자 국가 */
  country?: Maybe<Scalars['String']>;
  /** 주문일시 */
  createdAt: Scalars['DateTime'];
  /** 구매자 이메일 */
  email: Scalars['String'];
  /** 주문번호 */
  godoOrderId: Scalars['String'];
  histories: Array<OrderEventHistoryOutput>;
  /** 주문 ID */
  id: Scalars['String'];
  /** 언어코드 */
  langCode: LanguageCodeEnum;
  license: OrderLicenseOutput;
  /** 구매자 아이디 */
  memId: Scalars['String'];
  /** 구매자 고유 번호 */
  memNo: Scalars['Int'];
  /** 구매자 명 */
  name: Scalars['String'];
  /** 주문 상태 */
  status: OrderStatus;
  /** 사용 예치금 */
  useAconCash: Scalars['Int'];
  /** 사용 선물 예치금 */
  useGiftDeposit: Scalars['Int'];
  /** 사용 포인트 */
  usePoint: Scalars['Int'];
};

export type OrderProductOutputHistoriesArgs = {
  events?: InputMaybe<Array<OrderEventTypeEnum>>;
};

export type OrderProductReturn = {
  __typename?: 'OrderProductReturn';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['Int'];
  /** 상품 명 */
  title: Scalars['String'];
};

export enum OrderStatus {
  Cancel = 'cancel',
  Confirm = 'confirm',
  Fail = 'fail',
  PartialCancel = 'partialCancel',
  Ready = 'ready',
  Settled = 'settled',
  Waiting = 'waiting',
}

export enum OrderStatusEnum {
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
  Completed = 'COMPLETED',
  DepositStandby = 'DEPOSIT_STANDBY',
  InPreparation = 'IN_PREPARATION',
  OnDelivery = 'ON_DELIVERY',
  PaymentAborted = 'PAYMENT_ABORTED',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentTried = 'PAYMENT_TRIED',
  RefundAsked = 'REFUND_ASKED',
  RefundCompleted = 'REFUND_COMPLETED',
  ReturnAsked = 'RETURN_ASKED',
}

export type OrderViewDto = {
  __typename?: 'OrderViewDto';
  /** 은행 */
  bank: Scalars['String'];
  /** 계좌 */
  bankAccount: Scalars['String'];
  /** 예금주 */
  bankHolder: Scalars['String'];
  /** 입금자 */
  bankSender: Scalars['String'];
  cash?: Maybe<Scalars['String']>;
  /** 주문 상세 사용권들 */
  license?: Maybe<OrderLicenseModel>;
  /** 주문 정보 */
  order?: Maybe<OrderDto>;
  /** 주문일자 */
  orderDate: Scalars['DateTime'];
  /** 주문 상세 상품들 */
  orderProducts: Array<OrderViewProductDto>;
  /** pg사 실패사유 */
  pgFailReason: Scalars['String'];
  pgSettleCd: Array<Scalars['String']>;
  pgSettleNm: Array<Scalars['String']>;
  /** 영수증 */
  receipt?: Maybe<OrderViewReceiptDto>;
  receiptFl: Scalars['String'];
  settleKind: Scalars['String'];
  /** 결제방법 */
  settleMethod: Scalars['String'];
  /** 결제 */
  settleName: Scalars['String'];
  /** 결제금액 */
  settlePrice: Scalars['Int'];
  /** 영수증 */
  settleReceipt: Scalars['String'];
  /** 주문상태 */
  status: Scalars['String'];
  tax?: Maybe<Scalars['String']>;
  /** 배송비-쿠폰 할인금 */
  totalCouponDeliveryDcPrice: Scalars['Int'];
  /** 주문-쿠폰 할인금 */
  totalCouponGoodsDcPrice: Scalars['Int'];
  /** 상품-쿠폰 할인금 */
  totalCouponOrderDcPrice: Scalars['Int'];
  /** 총 할인금액 */
  totalGoodsDcPrice: Scalars['Int'];
  /** 상품 총 합계 */
  totalGoodsPrice: Scalars['Int'];
  /** 사용된 예치금 */
  useDeposit: Scalars['Int'];
  /** 사용된 마일리지 */
  useMileage: Scalars['Int'];
  /** 사용자 정보 */
  user: OrderViewUserInfoDto;
};

export type OrderViewProductDto = {
  __typename?: 'OrderViewProductDto';
  /** 상품번호 */
  goodsNo: Scalars['Float'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 판매 금액 */
  price: Scalars['Float'];
  product?: Maybe<Acon3dProduct>;
  /**
   * 정가
   * @deprecated realPrice -> price, price -> salePrice로 대체
   */
  realPrice: Scalars['Float'];
  /** 판매가 */
  salePrice: Scalars['Int'];
  /** 상태 */
  status: Scalars['String'];
  statusStr: Scalars['String'];
};

export type OrderViewReceiptDto = {
  __typename?: 'OrderViewReceiptDto';
  cashFl?: Maybe<Scalars['String']>;
  limitDateFl?: Maybe<Scalars['String']>;
  particularFl?: Maybe<Scalars['String']>;
  periodDay?: Maybe<Scalars['String']>;
  periodFl?: Maybe<Scalars['String']>;
  taxFl?: Maybe<Scalars['String']>;
  taxInvoiceLimitDate?: Maybe<Scalars['String']>;
};

export type OrderViewUserInfoDto = {
  __typename?: 'OrderViewUserInfoDto';
  /** 휴대폰 번호 */
  cellPhone: Scalars['String'];
  /** 이메일 */
  email: Scalars['String'];
  /** 사용자 이름 */
  name: Scalars['String'];
};

export type OrderedProductsInput_Condition = {
  /** 성인상품 여부; null일 경우 모든 종류의 상품을 리턴; */
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
  /** 상품판매 여부; null일 경우 모든 상품 리턴; */
  isOnSale?: InputMaybe<Scalars['Boolean']>;
  /** 주문 상태 배열 */
  orderStates?: InputMaybe<Array<Ordered_Products_Input_Condition_Order_State>>;
  /** 상품판매가 최저(이상) */
  salePriceAbove?: InputMaybe<Scalars['Float']>;
  /** 상품판매가 최고(미만) */
  salePriceUnder?: InputMaybe<Scalars['Float']>;
  /** 주문 일자 범위 시작일 */
  since: Scalars['DateTime'];
  /** 주문 일자 범위 종료일 */
  until: Scalars['DateTime'];
};

export type OrderedProductsInput_Sort = {
  criterion: Ordered_Products_Input_Sort_Criterion;
  key: Ordered_Products_Input_Sort_Key;
};

export enum Partner_Document_Status {
  Denied = 'DENIED',
  Draft = 'DRAFT',
  DraftOnOpen = 'DRAFT_ON_OPEN',
  InReview = 'IN_REVIEW',
  InReviewOnOpen = 'IN_REVIEW_ON_OPEN',
  OnSale = 'ON_SALE',
  Rejected = 'REJECTED',
  RejectedOnOpen = 'REJECTED_ON_OPEN',
  Suspended = 'SUSPENDED',
}

export enum Preset_Brands_Input_Preset {
  KeyAccount = 'KEY_ACCOUNT',
}

export enum Preset_Products_Preset {
  BrandWeek = 'BRAND_WEEK',
  En_1 = 'EN_1',
  Ja_1 = 'JA_1',
  Ko_1 = 'KO_1',
  Recommended = 'RECOMMENDED',
  ResourceBank_1 = 'RESOURCE_BANK_1',
  ResourceBank_2 = 'RESOURCE_BANK_2',
}

export enum Products_Input_Condition_Industry {
  Game = 'GAME',
  Webtoon = 'WEBTOON',
}

export enum Products_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Products_Sort_Key {
  Id = 'ID',
  OrderAmount = 'ORDER_AMOUNT',
  OrderCount = 'ORDER_COUNT',
  PromotionStartDate = 'PROMOTION_START_DATE',
  ReleasedAt = 'RELEASED_AT',
  SalePrice = 'SALE_PRICE',
}

export enum Promotion_Concept_Codes {
  Flashsale = 'flashsale',
  Openrun = 'openrun',
}

export type PackageChildren = {
  __typename?: 'PackageChildren';
  selected: Array<Asset>;
  unselected: Array<Asset>;
};

export type PackageInput = {
  childrenIds: Array<Scalars['Int']>;
  parentId: Scalars['Int'];
};

export type PackageOutput = {
  __typename?: 'PackageOutput';
  children: Array<Asset>;
  parent: Asset;
};

export type PaginatedBrandPromotionArgsSearch = {
  doNotUseThisField?: InputMaybe<Scalars['Int']>;
};

export type PaginatedBrandPromotionOutput = {
  __typename?: 'PaginatedBrandPromotionOutput';
  data: Array<BrandPromotionOutput>;
  pagination: PaginatedBrandPromotionOutputPaginationOutput;
};

export type PaginatedBrandPromotionOutputPaginationOutput = {
  __typename?: 'PaginatedBrandPromotionOutputPaginationOutput';
  limit: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginatedDetailReview = {
  __typename?: 'PaginatedDetailReview';
  nextCursor?: Maybe<Scalars['Int']>;
  node?: Maybe<Review>;
  nth?: Maybe<Scalars['Int']>;
  prevCursor?: Maybe<Scalars['Int']>;
};

export type PaginatedPromotionOutput = {
  __typename?: 'PaginatedPromotionOutput';
  data: Array<PromotionOutput>;
  pagination: PaginatedPromotionOutputPaginationOutput;
};

export type PaginatedPromotionOutputPaginationOutput = {
  __typename?: 'PaginatedPromotionOutputPaginationOutput';
  limit: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginatedReview = {
  __typename?: 'PaginatedReview';
  edge?: Maybe<ReviewEdge>;
  nodes?: Maybe<Array<Review>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedReviewsWithCount = {
  __typename?: 'PaginatedReviewsWithCount';
  count: Scalars['Int'];
  rows: Array<Review>;
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Float'];
  lastPage: Scalars['Float'];
  perPage: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type PaginationInput = {
  /** 페이지 출력량 */
  limit: Scalars['Int'];
  /** 요청 페이지 */
  page: Scalars['Int'];
};

export type PaginationOutput = {
  __typename?: 'PaginationOutput';
  /** 현재 페이지 */
  currentPage: Scalars['Int'];
  /** 마지막 페이지 */
  lastPage: Scalars['Int'];
  /** 페이지당 수량 */
  perPage: Scalars['Int'];
  /** 총 수량 */
  totalCount: Scalars['Int'];
};

export type ParticipateChristmasEventOutput = {
  __typename?: 'ParticipateChristmasEventOutput';
  alreadyParticipated: Scalars['Boolean'];
  result: Scalars['Boolean'];
};

export enum PartnerExcelDocumentClassEnum {
  User = 'USER',
  UserSettle = 'USER_SETTLE',
}

export enum PartnerMemoTypeEnum {
  User = 'USER',
  UserSettle = 'USER_SETTLE',
}

export type PartnerPlanInput = {
  /** 전속 여부 */
  isExclusive: Scalars['Boolean'];
  /** 파트너십 종류 */
  planType: Scalars['Int'];
};

export enum PartnerSettleSortColumn {
  RequestDate = 'REQUEST_DATE',
}

export enum PartnerSettleStatusEnum {
  Approve = 'approve',
  None = 'none',
  Reject = 'reject',
  Request = 'request',
  Rerequest = 'rerequest',
}

export type PartnerSettlesSortCriteria = {
  orderBy?: InputMaybe<OrderBy>;
  userSettleOrderColumn?: InputMaybe<PartnerSettleSortColumn>;
};

export enum PartnerTypeEnum {
  Business = 'Business',
  Personal = 'Personal',
}

export type PaymentPoint = {
  __typename?: 'PaymentPoint';
  /** 구매 완료 기본 지급 포인트 */
  defaultPaymentPoint: Scalars['Int'];
  /** 리뷰 작성 기본 지급 포인트 */
  defaultWriteReviewPoint: Scalars['Int'];
  /** 총 포인트 혜택 */
  godoGoodsNo: Scalars['Int'];
  /** 총 포인트 혜택 */
  point: Scalars['Int'];
};

export type Periods = {
  __typename?: 'Periods';
  /** 종료일자 */
  endDate: Scalars['DateTime'];
  /** 시작일자 */
  startDate: Scalars['DateTime'];
};

export type PointBenefit = {
  __typename?: 'PointBenefit';
  /** 포인트 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<PointBenefitApply>>;
  /** 포인트 혜택 적용 설정 */
  applyType?: Maybe<BenefitApply>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<PointBenefitBoardInfo>>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 기본 설정 여부 */
  defaultApply?: Maybe<Scalars['Boolean']>;
  /** 지급 내용 */
  description?: Maybe<Scalars['String']>;
  /** 포인트 혜택 적용 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 프로모션에서 자동 생성한 혜택 여부 */
  isAutoCreatedByPromotion?: Maybe<Scalars['Boolean']>;
  /** 포인트 혜택을 적용할 다국어몰 */
  language?: Maybe<Language>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<PointBenefitOrderInfo>>;
  /** 포인트 지급 */
  payPoint: Scalars['String'];
  /** 포인트 지급 설정 */
  pointUnit: Scalars['String'];
  /** 포인트 혜택 수량 */
  quantity?: Maybe<Scalars['String']>;
  /** 포인트 혜택 적용 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 포인트 헤택 타입 */
  type?: Maybe<BenefitType>;
  typeId: Scalars['Float'];
  /** 수정일 */
  updated: Scalars['DateTime'];
  /** 포인트 혜택 사용 개수 */
  useCount?: Maybe<Scalars['Float']>;
};

export type PointBenefitApply = {
  __typename?: 'PointBenefitApply';
  /** 고유 ID */
  id: Scalars['Float'];
  pointBenefitId: Scalars['Float'];
  /** 적용 설정 값 */
  value: Scalars['String'];
};

export type PointBenefitApplyClone = {
  __typename?: 'PointBenefitApplyClone';
  /** 고유 ID */
  id: Scalars['Float'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float'];
  /** 적용 설정 값 */
  value: Scalars['String'];
};

export type PointBenefitBoardInfo = {
  __typename?: 'PointBenefitBoardInfo';
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean'];
  /** 지급 포인트 */
  payPoint: Scalars['Float'];
  pointBenefitId: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type PointBenefitBoardInfoClone = {
  __typename?: 'PointBenefitBoardInfoClone';
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean'];
  /** 지급 포인트 */
  payPoint: Scalars['Float'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type PointBenefitClone = {
  __typename?: 'PointBenefitClone';
  /** 포인트 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<PointBenefitApplyClone>>;
  /** 포인트 혜택 적용 설정 */
  applyType?: Maybe<BenefitApplyClone>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<PointBenefitBoardInfoClone>>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 기본 설정 여부 */
  defaultApply?: Maybe<Scalars['Boolean']>;
  /** 지급 내용 */
  description?: Maybe<Scalars['String']>;
  /** 포인트 혜택 적용 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  /** 프로모션에서 자동 생성한 혜택 여부 */
  isAutoCreatedByPromotion?: Maybe<Scalars['Boolean']>;
  /** 포인트 혜택을 적용할 다국어몰 */
  language?: Maybe<LanguageClone>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<PointBenefitOrderInfoClone>>;
  /** 포인트 지급 */
  payPoint: Scalars['String'];
  /** 포인트 지급 설정 */
  pointUnit: Scalars['String'];
  /** 포인트 혜택 수량 */
  quantity?: Maybe<Scalars['String']>;
  /** 포인트 혜택 적용 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 포인트 헤택 타입 */
  type?: Maybe<BenefitTypeClone>;
  typeId: Scalars['Float'];
  /** 수정일 */
  updated: Scalars['DateTime'];
  /** 포인트 혜택 사용 개수 */
  useCount?: Maybe<Scalars['Float']>;
};

export type PointBenefitOrderInfo = {
  __typename?: 'PointBenefitOrderInfo';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean'];
  /** 지급 포인트 */
  payPoint: Scalars['Float'];
  pointBenefitId: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type PointBenefitOrderInfoClone = {
  __typename?: 'PointBenefitOrderInfoClone';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean'];
  /** 지급 포인트 */
  payPoint: Scalars['Float'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type PointItem = {
  __typename?: 'PointItem';
  /** 남은 포인트 액수 */
  afterPoint: Scalars['Int'];
  /** 포인트 지급 내용 */
  contents: Scalars['String'];
  /** 포인트 만료 일자 */
  deleteScheduleDate: Scalars['String'];
  /** 포인트 지급 액수 */
  point: Scalars['Int'];
  /** 포인트 지급 일자 */
  regDate: Scalars['String'];
};

export type PointList = {
  __typename?: 'PointList';
  /** 포인트 지급 내역 목록 */
  items: Array<PointItem>;
  /** 지급 내역 전체 갯수 */
  totalCount: Scalars['Int'];
};

export type Popup = {
  __typename?: 'Popup';
  /** 팝업 오늘하루 숨기기 버튼 표시 여부 */
  allowHide: Scalars['Boolean'];
  /** 팝업 내용(바디) */
  body?: Maybe<Scalars['String']>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 팝업 표시 종료일 */
  endDate: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 팝업를 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** 팝업 표시 url */
  location?: Maybe<Array<PopupLocation>>;
  /** 팝업 표시 시작일 */
  startDate: Scalars['DateTime'];
  /** 팝업 제목 */
  title?: Maybe<Scalars['String']>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type PopupHistory = {
  __typename?: 'PopupHistory';
  status: PopupStatus;
  updated: Scalars['DateTime'];
  updater: Scalars['String'];
};

export type PopupLocation = {
  __typename?: 'PopupLocation';
  /** 고유 id */
  id: Scalars['Float'];
  /** 팝업 id */
  popupId: Scalars['Float'];
  /** 팝업을 표시할 url */
  url: Scalars['String'];
};

export enum PopupLocationV2 {
  Acon3d = 'acon3d',
  PartnerHub = 'partnerHub',
}

export enum PopupOrderCol {
  Created = 'created',
  EndDate = 'endDate',
  StartDate = 'startDate',
}

export type PopupOutput = {
  __typename?: 'PopupOutput';
  /** 버튼 문구 */
  buttonText?: Maybe<Scalars['String']>;
  /** 연결 URL */
  connectedUrl?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  /** 등록자 */
  creator: AdminUser;
  /** 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean'];
  id: Scalars['Int'];
  /** 팝업 이미지 아이디 */
  image?: Maybe<UploadFile>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen: Scalars['Boolean'];
  /** 일시 정지용 */
  isStopped?: Maybe<Scalars['Boolean']>;
  /** 언어 */
  language: LanguageCodeEnum;
  /** 위치 */
  location: PopupLocationV2;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean'];
  popupHistories: Array<PopupHistory>;
  /** 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 하위 페이지 url */
  subLocations: Array<PopupSubLocation>;
  /** 서브 텍스트 */
  subText?: Maybe<Scalars['String']>;
  /** 팝업 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 팝업 제목(관리용) */
  title: Scalars['String'];
  updated: Scalars['DateTime'];
  /** 수정자 */
  updater: AdminUser;
  /** 하위 페이지 url */
  urls: Array<PopupUrlOutput>;
};

export enum PopupStatus {
  End = 'end',
  Open = 'open',
  Pause = 'pause',
  Standby = 'standby',
  TemporarySaved = 'temporarySaved',
  UnPause = 'unPause',
}

export type PopupSubLocation = {
  __typename?: 'PopupSubLocation';
  id: Scalars['Int'];
  /** 소속된 팝업 */
  popup: PopupV2;
  /** 하위 위치(메인 구분용) */
  subLocation: PopupSubLocationEnum;
};

export enum PopupSubLocationEnum {
  Game = 'game',
  Toon = 'toon',
}

export type PopupSubLocationInput = {
  id?: InputMaybe<Scalars['Int']>;
  popupId?: InputMaybe<Scalars['Int']>;
  subLocation: PopupSubLocationEnum;
};

export type PopupUploadFileInput = {
  id: Scalars['Int'];
};

export type PopupUrl = {
  __typename?: 'PopupUrl';
  id: Scalars['Int'];
  /** 하위페이지 노출여부 */
  isSubPageIncluded: Scalars['Boolean'];
  /** 소속된 팝업 */
  popup: PopupV2;
  /** 하위 위치(메인 구분용) */
  subLocation?: Maybe<PopupSubLocationEnum>;
  /** 하위 페이지 url */
  url: Scalars['String'];
};

export type PopupUrlOutput = {
  __typename?: 'PopupUrlOutput';
  id?: Maybe<Scalars['Int']>;
  popupId?: Maybe<Scalars['Int']>;
  subLocation?: Maybe<PopupSubLocationEnum>;
  urls: Array<UrlsOutput>;
};

export type PopupV2 = {
  __typename?: 'PopupV2';
  /** 버튼 문구 */
  buttonText?: Maybe<Scalars['String']>;
  /** 버튼 연결 링크 */
  connectedUrl?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  /** 등록자 */
  creator: AdminUser;
  /** 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean'];
  id: Scalars['Int'];
  /** 팝업 이미지 아이디 */
  image?: Maybe<UploadFile>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen: Scalars['Boolean'];
  /** 일시 정지용 */
  isStopped?: Maybe<Scalars['Boolean']>;
  /** 언어 */
  language: LanguageCodeEnum;
  /** 팝업을 노출할 서비스 */
  location: PopupLocationV2;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean'];
  popupHistories?: Maybe<Array<PopupHistory>>;
  /** 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 하위 페이지 url */
  subLocations?: Maybe<Array<PopupSubLocation>>;
  /** 서브 텍스트 */
  subText?: Maybe<Scalars['String']>;
  /** 팝업 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 팝업 제목(관리용) */
  title: Scalars['String'];
  updated: Scalars['DateTime'];
  /** 수정자 */
  updater: AdminUser;
  /** 하위 페이지 url */
  urls?: Maybe<Array<PopupUrl>>;
};

export type Product = {
  __typename?: 'Product';
  /** 상품 응용프로그램 필드 */
  applications?: Maybe<Array<ApplicationEntity>>;
  /** 해당 상품에 표시(또는 숨기기)할 배너들 */
  banners?: Maybe<Array<Banner>>;
  brand: Brand;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Float']>;
  /** 상품 카테고리 */
  categories?: Maybe<Array<Category>>;
  /** 상품 의견 리스트 */
  comments?: Maybe<Array<ProductComment>>;
  /** 수수료 */
  commission?: Maybe<Scalars['Float']>;
  /** 상품 본문 */
  contents?: Maybe<Scalars['String']>;
  /** 저작권 */
  copyright?: Maybe<Scalars['String']>;
  /** 상품 설명 */
  description?: Maybe<Scalars['String']>;
  /** 문서 ID */
  docId: Scalars['String'];
  /** 동적 요소 */
  dynamicItem?: Maybe<Scalars['String']>;
  /** 상품 파일 구성 */
  fileConfigList?: Maybe<Array<ProductFileConfig>>;
  /** 상품 파일 확장자 */
  fileExtensionList?: Maybe<Array<ProductFileExtension>>;
  /** 상품 파일 업데이트 내역 */
  fileUpdateList?: Maybe<Array<ProductFileUpdate>>;
  /** 고도몰 상품번호 */
  godoGoodsNo?: Maybe<Scalars['Int']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 상품 이미지 리스트 */
  imageList?: Maybe<Array<Maybe<ProductImage>>>;
  /** 삭제여부 */
  isDelete: Scalars['Boolean'];
  /** 수정요청 여부 */
  isEdit: Scalars['Boolean'];
  /** 검색 키워드 */
  keywords?: Maybe<Scalars['String']>;
  language: Language;
  languageCode: Scalars['String'];
  /** 업로드 한 파일 */
  model?: Maybe<UploadFile>;
  /** 모델구성 */
  modelConfigList?: Maybe<Array<ModelConfig>>;
  /** 모델 파일 ID */
  modelFileId?: Maybe<Scalars['Float']>;
  /** 모델 파일 명 */
  modelFileName?: Maybe<Scalars['String']>;
  /** 상품 오픈일 */
  openDate?: Maybe<Scalars['DateTime']>;
  originLanguage: Language;
  /** 상품 금액 */
  price?: Maybe<Scalars['Float']>;
  /** 연관상품 */
  related?: Maybe<Array<ProductRelated>>;
  /** 검토 요청일 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 상품 할인가 */
  salePrice?: Maybe<Scalars['Float']>;
  /** 상품 상태 */
  status: Scalars['String'];
  /** 상품 제목 */
  title: Scalars['String'];
  /** 파일 버전 */
  versions?: Maybe<Array<ProductVersion>>;
};

export type ProductBookInfoOutput = {
  __typename?: 'ProductBookInfoOutput';
  /** isbn */
  isbn?: Maybe<Scalars['String']>;
};

export type ProductCardsSummary = {
  __typename?: 'ProductCardsSummary';
  /** 상품 정보 */
  products: Array<Acon3dProduct>;
  sort?: Maybe<ProductListSort>;
  /** 사용 여부 */
  toggle?: Maybe<Scalars['Boolean']>;
};

export type ProductComment = {
  __typename?: 'ProductComment';
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 메세지 */
  message?: Maybe<Scalars['String']>;
  productId: Scalars['Float'];
  /** 메세지 유형 */
  type?: Maybe<Scalars['String']>;
};

export type ProductDateInfoOutput = {
  __typename?: 'ProductDateInfoOutput';
  /** 상품 최초 노출일 */
  releasedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductExtensionsInput_Condition = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 검색어 */
  input?: InputMaybe<Scalars['String']>;
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']>>;
};

export type ProductFileConfig = {
  __typename?: 'ProductFileConfig';
  /** 고유 ID */
  id: Scalars['Float'];
  name: Scalars['String'];
  productId: Scalars['Float'];
  size: Scalars['Float'];
};

export type ProductFileExtension = {
  __typename?: 'ProductFileExtension';
  extensionConfig: ExtensionConfig;
  extensionConfigId: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  productId: Scalars['Int'];
};

export type ProductFileUpdate = {
  __typename?: 'ProductFileUpdate';
  created: Scalars['DateTime'];
  /** 업데이트 일자 */
  date?: Maybe<Scalars['DateTime']>;
  /** 고유 ID */
  id: Scalars['Float'];
  name: Scalars['String'];
  productId: Scalars['Float'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  created: Scalars['DateTime'];
  /** 크롭 높이 */
  cropHeight?: Maybe<Scalars['Float']>;
  /** 크롭 너비 */
  cropWidth?: Maybe<Scalars['Float']>;
  /** 크롭 x 위치 */
  cropX?: Maybe<Scalars['Float']>;
  /** 크롭 y 위치 */
  cropY?: Maybe<Scalars['Float']>;
  /** 고유 ID */
  id: Scalars['Float'];
  item?: Maybe<UploadFile>;
  productId: Scalars['Float'];
  /** 이미지 유형 */
  type: Scalars['String'];
  uploadFileId?: Maybe<Scalars['Float']>;
};

export type ProductImageInfoOutput = {
  __typename?: 'ProductImageInfoOutput';
  main: ProductImageOutput;
  sub: ProductImageOutput;
};

export type ProductImageOutput = {
  __typename?: 'ProductImageOutput';
  url: Scalars['String'];
};

export type ProductLang = {
  __typename?: 'ProductLang';
  /** 브랜드 명 */
  creator: Scalars['String'];
  /** 브랜드 명 */
  itemNum: Scalars['Int'];
  /** 제목 */
  title: Scalars['String'];
};

export type ProductListFilterInput = {
  applications?: InputMaybe<Array<Scalars['String']>>;
  extensions?: InputMaybe<Array<Scalars['String']>>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
};

export type ProductListOptionInput = {
  isAdult?: InputMaybe<Scalars['Boolean']>;
};

export type ProductListReturn = {
  __typename?: 'ProductListReturn';
  /** 카테고리 코드 */
  cateCd?: Maybe<Scalars['String']>;
  /** 개수 */
  count?: Maybe<Scalars['Float']>;
  /** 비슷한 작품 항목들 */
  items?: Maybe<Array<ProductViewCardList>>;
};

export enum ProductListSort {
  HigherPrice = 'HIGHER_PRICE',
  LowerPrice = 'LOWER_PRICE',
  Newest = 'NEWEST',
  Popularity = 'POPULARITY',
  Recommended = 'RECOMMENDED',
}

export type ProductOutput = {
  __typename?: 'ProductOutput';
  /** 상품 사용 가능 프로그램 정보 */
  applications: Array<AssetApplicationOutput>;
  /** 책 정보 */
  bookInfo: ProductBookInfoOutput;
  /** 상품 브랜드 정보 */
  brandInfo: BrandOutput;
  /** 장르 */
  categoryGenre: Scalars['String'];
  /** 상품 일자 정보 */
  dateInfo: ProductDateInfoOutput;
  /** 상품 확장자 정보 */
  extensions: Array<ExtensionOutput>;
  id: Scalars['Int'];
  /** 상품 이미지 정보 */
  imageInfo: ProductImageInfoOutput;
  isAdultOnly: Scalars['Boolean'];
  /** 상품 가격 정보 */
  priceInfo: ProductPriceInfoOutput;
  /** 조회수 */
  produkt: ProduktOutput;
  /** 상품 프로모션 정보; 해당하지 않으면 null; */
  promotionInfo?: Maybe<ProductPromotionInfoOutput>;
  /** 리뷰 */
  reviewInfo: ProductReviewOutput;
  /** 상품 태그 정보 */
  tags: Array<TagOutput>;
  title: Scalars['String'];
};

export type ProductPaginatedOutput = {
  __typename?: 'ProductPaginatedOutput';
  data: Array<ProductOutput>;
  pagination: PaginationOutput;
};

export type ProductPriceInfoOutput = {
  __typename?: 'ProductPriceInfoOutput';
  /** 상품 정가 */
  price: Scalars['Float'];
  /** 상품 판매가 */
  salePrice: Scalars['Float'];
};

export type ProductPromotionInfoOutput = {
  __typename?: 'ProductPromotionInfoOutput';
  /** 프로모션 시작일자 */
  since: Scalars['DateTime'];
  /** 프로모션 종료일자 */
  until: Scalars['DateTime'];
};

export type ProductRelated = {
  __typename?: 'ProductRelated';
  from: Scalars['Float'];
  to: Scalars['Float'];
};

export type ProductReviewOutput = {
  __typename?: 'ProductReviewOutput';
  /** 리뷰 */
  review?: Maybe<Array<Review>>;
};

export type ProductTagsInput_Condition = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 검색어 */
  input?: InputMaybe<Scalars['String']>;
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']>>;
};

export enum ProductType {
  Game = 'GAME',
  Webtoon = 'WEBTOON',
}

export enum ProductTypes {
  Game = 'GAME',
  Imgsrc = 'IMGSRC',
  Lecture = 'LECTURE',
  Webtoon = 'WEBTOON',
}

export type ProductVersion = {
  __typename?: 'ProductVersion';
  /** 데이터 전체 */
  contents?: Maybe<Scalars['String']>;
  /** 버전 생성일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 버전 Major */
  major?: Maybe<Scalars['Float']>;
  /** 버전 Minor */
  minor?: Maybe<Scalars['Float']>;
  /** 오픈된 상품정보 */
  openProductInfo?: Maybe<Product>;
  /** 상품 ID */
  productId?: Maybe<Scalars['Float']>;
  /** 선택된 상품정보 */
  productInfo?: Maybe<Product>;
};

export type ProductViewCardList = {
  __typename?: 'ProductViewCardList';
  /** 브랜드 명 */
  brand: Scalars['String'];
  /** 브랜드 코드 */
  brandCd: Scalars['String'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 가격 */
  price: Scalars['String'];
  /** 가격 */
  realPrice: Scalars['String'];
  /** 제목 */
  title: Scalars['String'];
};

export type ProductsConditionInput = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']>>;
};

export type ProduktOutput = {
  __typename?: 'ProduktOutput';
  /** 주문수 */
  orderCount?: Maybe<Scalars['Float']>;
  /** 리뷰수 */
  reviewCount?: Maybe<Scalars['Float']>;
  /** 조회수 */
  viewCount?: Maybe<Scalars['Float']>;
};

export type ProduktPublicOutput = {
  __typename?: 'ProduktPublicOutput';
  badgeNames: Array<Scalars['String']>;
  brand: BrandPublicOutput;
  categories: Array<CategoryPublicOutput>;
  content?: Maybe<Scalars['String']>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  extensions: Array<Scalars['String']>;
  id: Scalars['Int'];
  /** 성인상품 여부 */
  isAdultOnly: Scalars['Boolean'];
  licenses: Array<AssetLicensePublicOutput>;
  /** 대표 이미지 URL */
  mainImageUrl: Scalars['String'];
  /** 리스트 노출 여부 */
  onDisplay: Scalars['Boolean'];
  /** 판매 여부 */
  onSale: Scalars['Boolean'];
  /** 주문 수(인기순 정렬) */
  orderCount: Scalars['Int'];
  /** 상품 정가 */
  price: Scalars['Int'];
  /** 진행 중인 프로모션 정보 */
  promotionEndDate?: Maybe<Scalars['DateTime']>;
  /** 리뷰 수 */
  reviewCount: Scalars['Int'];
  /** 상품 할인가 */
  salePrice: Scalars['Int'];
  /** 서브 이미지 URL */
  subImageUrl: Scalars['String'];
  /** 번역된 상품명 */
  title: Scalars['String'];
  /** 갱신 일자 */
  updated?: Maybe<Scalars['DateTime']>;
  /** 조회 수 */
  viewCount: Scalars['Int'];
};

export type ProfilePhoto = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type PromotedProductOutput = {
  __typename?: 'PromotedProductOutput';
  applications?: Maybe<Array<ApplicationEntity>>;
  /** 상품카드 뱃지 이름들(번역) */
  badgeNames: Array<Maybe<Scalars['String']>>;
  brand: Acon3dBrand;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 확장자 */
  extensions: Array<Scalars['String']>;
  id: Scalars['Int'];
  /** ACON ONLY 여부 */
  isAconOnly: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  /** 언어 */
  languageCode: LanguageCodeEnum;
  /** 대표 이미지 URL */
  mainImageUrl: Scalars['String'];
  /** 주문 수(인기순 정렬) */
  orderCount: Scalars['Int'];
  /** 상품 정가 */
  price: Scalars['Int'];
  promotion: PromotionOutput;
  /** 리뷰 수 */
  reviewCount: Scalars['Int'];
  /** 상품 할인가 */
  salePrice: Scalars['Int'];
  /** 서브 이미지 URL */
  subImageUrl: Scalars['String'];
  /** 번역된 상품명 */
  title: Scalars['String'];
  /** 갱신 일자 */
  updated?: Maybe<Scalars['DateTime']>;
  /** 조회 수 */
  viewCount: Scalars['Int'];
};

export type Promotion = {
  __typename?: 'Promotion';
  /** 프로모션 뱃지 리스트 */
  badgeList?: Maybe<Array<PromotionBadge>>;
  /** 프로모션 혜택 리스트 */
  benefitList?: Maybe<Array<PromotionBenefit>>;
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 프로모션 이미지 리스트 */
  imageList?: Maybe<Array<PromotionImage>>;
  /** 리뷰 작성 시 포인트 지급 혜택을 자동 생성할 지 여부 */
  isAutoCreateReviewBenefit?: Maybe<Scalars['Boolean']>;
  /** 프로모션 언어 리스트 */
  languageList?: Maybe<Array<PromotionLang>>;
  /** 프로모션이 저장되는 시점에 해당되는 상품의 브랜드 명입니다. */
  productBrandName: Scalars['String'];
  /** 프로모션이 저장되는 시점에 해당되는 상품의 제목입니다. */
  productTitle: Scalars['String'];
  /** 예약날짜 */
  reserveDate?: Maybe<Scalars['DateTime']>;
  /** 프로모션의 타입입니다. (cosmic, comet) */
  type: Scalars['String'];
};

export type PromotionAvailabilityOutput = {
  __typename?: 'PromotionAvailabilityOutput';
  /** total이 null인 경우 제한이 없으므로 자연스럽게 null이며, 그 외에는 available = total - current */
  available?: Maybe<Scalars['Int']>;
  /** 현재 보유중인 promote_item의 갯수로 최저값은 0이고 null이 될 수 없음  */
  current: Scalars['Int'];
  /** null인 경우 무제한 */
  total?: Maybe<Scalars['Int']>;
};

export type PromotionBadge = {
  __typename?: 'PromotionBadge';
  /** 고유 ID */
  id: Scalars['Float'];
  item?: Maybe<Array<PromotionBadgeI18n>>;
  promotionId: Scalars['Float'];
  /** 혜택 종류(point, coupon) */
  type: Scalars['String'];
};

export type PromotionBadgeI18n = {
  __typename?: 'PromotionBadgeI18n';
  /** 제공 가격 */
  amount: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  language?: Maybe<Language>;
  /** 프로모션 뱃지 id */
  promotionBadgeId: Scalars['Float'];
};

export type PromotionBenefit = {
  __typename?: 'PromotionBenefit';
  amount: Scalars['Float'];
  /** 할인된 결과물에 대한 금액 */
  discount: Scalars['Float'];
  /** 스냅샷된 할인 단위 */
  discountUnit: Scalars['String'];
  /** 스냅샷된 혜택 종료일자 */
  endDate: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 스냅샷된 혜택 명 */
  name: Scalars['String'];
  promotionId: Scalars['Float'];
  /** 스냅샷된 혜택 시작일자 */
  startDate: Scalars['DateTime'];
  /** 혜택 유형 (1,2,3이 존재) */
  type: Scalars['Int'];
};

export type PromotionConceptOutput = {
  __typename?: 'PromotionConceptOutput';
  code: Scalars['String'];
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  titleRegional?: Maybe<GqlRegional>;
};

export type PromotionImage = {
  __typename?: 'PromotionImage';
  created: Scalars['DateTime'];
  /** 크롭 높이 */
  cropHeight?: Maybe<Scalars['Float']>;
  /** 크롭 너비 */
  cropWidth?: Maybe<Scalars['Float']>;
  /** 크롭 x 위치 */
  cropX?: Maybe<Scalars['Float']>;
  /** 크롭 y 위치 */
  cropY?: Maybe<Scalars['Float']>;
  /** 고유 ID */
  id: Scalars['Float'];
  originUploadFile?: Maybe<UploadFile>;
  originUploadFileId: Scalars['Float'];
  promotionId: Scalars['Float'];
  resizeUploadFile?: Maybe<UploadFile>;
  resizeUploadFileId: Scalars['Float'];
  /** 이미지 유형 */
  type: Scalars['Float'];
};

export type PromotionItemInput = {
  discountType?: InputMaybe<EPromotionDiscountType>;
  discountValue?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  since?: InputMaybe<Scalars['DateTime']>;
  until?: InputMaybe<Scalars['DateTime']>;
};

export type PromotionItemOutput = {
  __typename?: 'PromotionItemOutput';
  asset?: Maybe<Asset>;
  discountType?: Maybe<EPromotionDiscountType>;
  discountValue?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  registeredAt?: Maybe<Scalars['DateTime']>;
  since?: Maybe<Scalars['DateTime']>;
  suspendedAt?: Maybe<Scalars['DateTime']>;
  until?: Maybe<Scalars['DateTime']>;
};

export type PromotionItemOutputAssetArgs = {
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
};

export type PromotionLang = {
  __typename?: 'PromotionLang';
  /** 고유 ID */
  id: Scalars['Float'];
  language: Language;
  promotionId: Scalars['Float'];
};

export type PromotionOutput = {
  __typename?: 'PromotionOutput';
  availability: PromotionAvailabilityOutput;
  concept: PromotionConceptOutput;
  description: GqlRegional;
  discountType?: Maybe<EPromotionDiscountType>;
  discountValue?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  inheritance?: Maybe<Scalars['Boolean']>;
  issuer: GqlRegional;
  items: Array<PromotionItemOutput>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  since?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EPromotionStatus>;
  suspendedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  until?: Maybe<Scalars['DateTime']>;
};

export type PromotionOutputItemsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
};

export type PromotionProductOutput = {
  __typename?: 'PromotionProductOutput';
  /** 언어 */
  languageCode: LanguageCodeEnum;
  product: ProductOutput;
  promotion: PromotionOutput;
};

export type Query = {
  __typename?: 'Query';
  GODO_EVENT_ORDER_COMPLETE: Scalars['Boolean'];
  GODO_EVENT_ORDER_REFUND: Scalars['Boolean'];
  GODO_EVENT_ORDER_SETTLE: Scalars['Boolean'];
  GODO_UPDATE_ISREFUNDED: Scalars['Boolean'];
  aconNotifications: Array<AconNotification>;
  adminAsset: AssetOutput;
  adminBrandAssets: Array<Asset>;
  adminBrandPick: BrandPick;
  adminDocument?: Maybe<AdminSingleDocumentOutput>;
  adminDocuments: Array<AdminMultiDocument>;
  adminDownload: Scalars['String'];
  adminInquiries: Array<Inquiry>;
  adminInquiry: Inquiry;
  adminInquiryCount: Scalars['Int'];
  adminOrderCouponInfo: Array<OrderCouponReturn>;
  adminOrderEventHistory: Array<OrderEventHistory>;
  adminOrderGoodsPriceInfo: OrderAdminRefundModalReturn;
  adminOrderPaymentInfo: OrderPaymentReturn;
  adminTranslate: Array<Translated>;
  adultProductCardsSummary: ProductCardsSummary;
  asset: Asset;
  assetAdminDownload: Scalars['String'];
  assetFileInfo: Asset;
  assets: Array<Asset>;
  banks: Array<Bank>;
  benefitProducts: Array<Acon3dProduct>;
  bestProducts: Array<Scalars['Int']>;
  brand?: Maybe<Brand>;
  brandAssets: Array<Asset>;
  brandPick: BrandPick;
  brandPickSummary: ProductCardsSummary;
  brandProductsWithCategories: Array<BrandProductsWithCategoriesOutput>;
  brandPromotion: BrandPromotionOutput;
  brandPromotions: PaginatedBrandPromotionOutput;
  brandReviews: PaginatedReviewsWithCount;
  brands: BrandPaginatedOutput;
  /** 유저의 장바구니 갯수를 가져오는 쿼리. 장바구니 아이콘에 숫자를 표시하고, 상품에서 장바구니에 담았는지 여부를 표현하기 위해 사용합니다.이전의 query godoUserCart()를 대체하는 쿼리입니다. */
  cart: Array<CartOutput>;
  /** 장바구니 상품 목록을 가져오는 메소드 입니다. */
  carts: Array<CartItem>;
  categories: Array<GodoCategory>;
  categoriesV2: Array<CategoryOutputV4>;
  categoriesV3: Array<CategoryOutputV2>;
  checkExistMember: Scalars['Boolean'];
  checkHasAdultProducts: Scalars['Boolean'];
  checkIssuanceTempPassword: Scalars['Boolean'];
  /** 회원이 사업자인지 확인하는 메소드 입니다. */
  checkedBusiness: Scalars['Boolean'];
  checkedPasswordChange: Scalars['Boolean'];
  childCategories: Array<CategoryNode>;
  clips: Array<Clip>;
  clipsV2: Array<Clip>;
  compare: Array<AdminSingleDocumentOutput>;
  createPartnerPlan: Scalars['Boolean'];
  descendantsOfRegularCategory: Array<CategoryNode>;
  displayAssetWithItems: DisplayAssetWithItemsOutput;
  displayBrandWithItems: DisplayBrandWithItemsOutput;
  document: SingleDocumentOutput;
  documents: Array<ListDocumentOutput>;
  downloadContract: Scalars['String'];
  exchangeRate: Scalars['Float'];
  existsPartner: Scalars['Boolean'];
  faq?: Maybe<HubAdminPost>;
  faqAcon?: Maybe<FaqAconModel>;
  faqAconCount: Scalars['Float'];
  faqAconTypes: Array<FaqAconTypeModel>;
  faqAcons: Array<FaqAconModel>;
  /** 입점 faq 항목들을 가져오는 쿼리 */
  faqCreator?: Maybe<FaqCreatorModel>;
  /** 입점 faq 카테고리 항목들을 가져오는 쿼리 */
  faqCreatorCategories?: Maybe<Array<FaqCreatorModel>>;
  /** 입점 faq 항목들을 개수를 가져오는 쿼리 */
  faqCreatorCount: Scalars['Int'];
  /** 입점 faq 유형들을 가져오는 메소드입니다. */
  faqCreatorTypes: Array<FaqCreatorTypeModel>;
  /** 입점 faq 항목들을 가져오는 쿼리 */
  faqCreators: Array<FaqCreatorModel>;
  faqs: HubAdminPosts;
  fetchPromotion: PromotionOutput;
  fetchPromotions: PaginatedPromotionOutput;
  fileByTempPath?: Maybe<Scalars['String']>;
  fileInfo: UserFileInfoOutput;
  /** 친구 수를 가져오는 쿼리 */
  friendsCount: Scalars['Int'];
  fundingProductNos: Array<Scalars['Int']>;
  getAdminOrder: OrderProductClone;
  getAdminOrders: Array<OrderProductClone>;
  getAdminOrdersCount: Scalars['Int'];
  getAdminReview: Review;
  getAdminReviews: Array<Review>;
  getAdminReviewsCount: Scalars['Int'];
  getApplications: Array<ApplicationEntity>;
  getAttendedDates: Array<Scalars['Float']>;
  getBadge?: Maybe<BadgeReturn>;
  getBadgeCount: Scalars['Float'];
  getBadges: Array<BadgeReturn>;
  getBandBanner?: Maybe<BandBanner>;
  getBandBannerClosed: Array<BandBannerClosed>;
  getBandBannerForPage?: Maybe<Array<BandBannerItems>>;
  getBandBannerTypes: Array<BandBannerType>;
  getBandBanners: Array<BandBanner>;
  getBandBannersTotalCount: Scalars['Float'];
  getBanner?: Maybe<BannerClone>;
  getBannerTypes: Array<BannerTypeClone>;
  getBanners: Array<BannerClone>;
  getBannersByGodoNo?: Maybe<Array<BannerClone>>;
  getBannersTotalCount: Scalars['Float'];
  getBenefitBurdenCount: Scalars['Float'];
  getBenefitBurdenList: Array<BenefitBurden>;
  getBrand: BrandEntity;
  getBrandForAdmin: BrandEntity;
  getBrandOrderProductItems: OrderProductItemPaginatedOutput;
  getBrandOrderProductItemsExcel: Scalars['String'];
  getBrandSettlementOrderExcel: Scalars['String'];
  getBrandSettlements: SettlementPaginatedOutput;
  getBrands: Array<Brand>;
  getBrandsForAdmin: BrandPaginatedResponse;
  getBrandsNotHavingUser: Array<Brand>;
  getCashList?: Maybe<CashList>;
  getCategories: Array<Category>;
  getCategoriesByLang: Array<Category>;
  getCategory?: Maybe<GodoCategory>;
  getCounts: Scalars['Int'];
  getCouponBenefit?: Maybe<CouponBenefitItem>;
  getCouponBenefits?: Maybe<Array<CouponBenefitItem>>;
  getCouponBenefitsTotalCount: Scalars['Float'];
  getCouponBurdenCount: Scalars['Float'];
  getCouponBurdenList?: Maybe<Array<CouponBurden>>;
  getCouponItems?: Maybe<Array<CouponItems>>;
  getCouponList: CouponList;
  getDownloadLogs: DownloadLogResponse;
  /** 이벤트 배너 항목을 가져오는 메소드 입니다. */
  getEventBannerItem?: Maybe<EventBannerItems>;
  /** 이벤트 배너 목록을 가져오는 메소드 입니다. */
  getEventBannerItems?: Maybe<Array<EventBannerItems>>;
  /** 이벤트 배너 총 갯수를 가져오는 메소드입니다. */
  getEventBannerTotalCount: Scalars['Float'];
  getExtensionConfigs?: Maybe<Array<ExtensionConfig>>;
  getFaq?: Maybe<Faq>;
  getFaqTypes: Array<FaqType>;
  getFaqs: Array<Faq>;
  getFaqsTotalCount: Scalars['Float'];
  getGodoBannerInfo: BannerInfo;
  getGodoBrandName: Scalars['String'];
  getGodoPopup?: Maybe<Array<GodoPopupInfo>>;
  getGoodsBuyers: Array<GoodsBuyers>;
  getItem?: Maybe<CouponBanner>;
  getItems?: Maybe<Array<CouponBanner>>;
  getLanguages: Array<Language>;
  getMainGroups: Array<MainGroup>;
  getModelConfigs?: Maybe<Array<ModelConfig>>;
  getMyOrders: Array<MyOrderReturn>;
  getNotice?: Maybe<Notice>;
  getNoticeTypes: Array<NoticeType>;
  getNotices: Array<Notice>;
  getNoticesTotalCount: Scalars['Float'];
  getOrderBenefitPoint: Scalars['Float'];
  getOrderCouponItems?: Maybe<Array<OrderCouponItems>>;
  getOrderInfo: OrderInfo;
  getOrderNoWithoutReview?: Maybe<Scalars['String']>;
  getPaymentPoint?: Maybe<Array<PaymentPoint>>;
  getPhotoReview: PaginatedDetailReview;
  getPhotoReviewTotalCount: Scalars['Int'];
  getPhotoReviews: PaginatedReview;
  getPointBenefit?: Maybe<PointBenefit>;
  getPointBenefitApply: Array<BenefitApply>;
  getPointBenefitTypes: Array<BenefitType>;
  getPointBenefits?: Maybe<Array<PointBenefit>>;
  getPointBenefitsTotalCount: Scalars['Float'];
  getPointLimitPolicy: BenefitLimit;
  getPointLimitPolicyCount: Scalars['Int'];
  getPointLimitPolicyList: Array<BenefitLimit>;
  getPointList: PointList;
  getPopupsHere: Array<PopupV2>;
  getProductLang: Array<ProductLang>;
  getPromotedProducts: Array<PromotedProductOutput>;
  getPromotion: Promotion;
  getPromotionConcept: PromotionConceptOutput;
  getPromotionConcepts: Array<PromotionConceptOutput>;
  getPromotionCount: Scalars['Int'];
  getPromotionProducts: Array<PromotionProductOutput>;
  getPromotions?: Maybe<Array<DisplayPromotionItem>>;
  getReviewPointItems?: Maybe<Array<PointBenefit>>;
  getReviewsByIrregularCategory: Array<Review>;
  getSearchKeywords: Array<SearchKeyword>;
  getSettleAllListV2?: Maybe<Array<Settle>>;
  getSettleAllPrice: Scalars['Float'];
  getSettleCount: SettleCount;
  getSettleListV2?: Maybe<Array<Settle>>;
  getSettleV2: SettleDetail;
  getTerm: Term;
  getTermAll: Array<Term>;
  /** 포인트 합산을 가져오는 쿼리 */
  getTotalInvitationPoint: Scalars['Int'];
  getTransGodoCategoryChildItems: Array<GodoCategoryOutput>;
  getTransGodoCategoryItems: Array<GodoDisplayCategory>;
  getValidationGoodsNo?: Maybe<Scalars['Boolean']>;
  gift: Gift;
  gifts: Array<Gift>;
  /** 고띄몰의 정의띘어 있는 혜성특가 리스트를 가져오는 메소드입니다. */
  godoCometDealList?: Maybe<Array<GodoCosmicDeal>>;
  /** 고띄몰의 정의띘어 있는 우주최저가 리스트를 가져오는 메소드입니다. */
  godoCosmicDealList?: Maybe<Array<GodoCosmicDeal>>;
  /** 문의 페이지에서 자기 자신의 주문별 상품 조회에 사용될 메소드 입니다. */
  godoMyOrders: Array<IGodoOrderGoodsNo>;
  /** 사용자 초대코드를 가져오는 쿼리 */
  godoUserInvitationCode: Scalars['String'];
  /** 사용자 명을 가져오는 쿼리 */
  godoUserName: Scalars['String'];
  /** 탈퇴(현재는 이벤트 발행용) */
  hackOut: Scalars['Boolean'];
  hasNewCoupon: Scalars['Boolean'];
  hasNewPoint: Scalars['Boolean'];
  hasOpenrunPromotedProducts: Scalars['Boolean'];
  hasOrdered: Scalars['Boolean'];
  haveRegisteredPromotion: Scalars['Boolean'];
  /** hot 모델 구좌에 표시될 상품 목록, 가장 적합한 상품부터 상품 id를 count 개수만큼 반환 */
  hotProducts: Array<Scalars['Int']>;
  hubDownload: Scalars['String'];
  ifPaidMember: Scalars['Boolean'];
  industries: IndustryPaginatedOutput;
  inquiries: Array<Inquiry>;
  inquiry: Inquiry;
  inquiryCount: Scalars['Int'];
  /** 초대 코드로 해당하는 초대코드의 언어를 가져오는 쿼리 */
  invitationLangCode?: Maybe<Scalars['String']>;
  isDuplicateBrandName: Scalars['Boolean'];
  isEqualPassword: Scalars['Boolean'];
  isExistPromotion: Scalars['Boolean'];
  isFirstDownload: Scalars['Boolean'];
  isLeavedBrandProduct: Scalars['Boolean'];
  isParticipatedEvent: Scalars['Boolean'];
  /** 상품의 상세페이지에서 업데이트 불릿 여부를 가져오는 메소드입니다. */
  isUpdate: Scalars['Boolean'];
  licenseTypes: Array<LicenseTypeOutput>;
  mainGroups: Array<GodoMainGroup>;
  /** 메인페이지 구좌의 상품 목록을 가져오는 메소드 입니다. */
  mainProductListV2: MainProductListV2Return;
  me: UserOutput;
  memberDepositDetail: MemberDepositDetailOutput;
  memberEventPeriodPaidAmount: Scalars['Float'];
  memberMoonWelcomeEventPeriodPaidAmount: Scalars['Float'];
  memberPeriodPaidAmount: Scalars['Float'];
  memberResidualGiftDeposit: Scalars['Float'];
  myAssets: Array<HubAssetOutput>;
  myBrand: BrandEntity;
  notice?: Maybe<HubAdminPost>;
  notifications: Array<Get>;
  openrunPromotions: OpenrunPromotionHubPaginatedOutput;
  orderGoodsWithExchangeRate: Scalars['String'];
  /** 주문 시 입력한 필명 정보를 가져오는 쿼리 */
  orderLicense?: Maybe<OrderLicenseModel>;
  /** 주문 상품을 가져오는 쿼리 */
  orderProducts?: Maybe<Array<OrderProductReturn>>;
  orderViewV2: OrderViewDto;
  orderedProductCards: Array<Maybe<Acon3dProduct>>;
  orderedProducts: ProductPaginatedOutput;
  /** 다른 상품들을 가져오는 메소드입니다. */
  otherProductList: RelateProductListOutput;
  package: CurrentPackageOutput;
  packages?: Maybe<Array<Maybe<PackageOutput>>>;
  paginateProductCards: TempProductPaginatedOutput;
  partner: UserOutput;
  partnerAcceptStatusNumber: Scalars['Float'];
  partnerExcelDownloadHistories: UserExcelDownloadHistoryPaginatedOutput;
  partnerPlan?: Maybe<UserPlanOutput>;
  partnerSettle: UserSettleOutput;
  partnerSettleAdmin?: Maybe<UserSettleOutput>;
  partnerSettles: UserSettlePaginatedOutput;
  partnerSettlesExcel: Scalars['String'];
  partners: UserPaginatedOutput;
  partnersExcel: Scalars['String'];
  popup: PopupOutput;
  popupCount: Scalars['Int'];
  popups: Array<PopupOutput>;
  presetBrands: BrandPaginatedOutput;
  presetProducts: ProductPaginatedOutput;
  product: ProductOutput;
  productCards: Array<Maybe<Acon3dProduct>>;
  productCardsSummary: ProductCardsSummary;
  productContent: Scalars['String'];
  productExtensions: ExtensionPaginatedOutput;
  productTags: TagPaginatedOutput;
  products: ProductPaginatedOutput;
  receivedGift: OrderGift;
  receivedGifts: Array<OrderGift>;
  recentOrdersV2: Array<RecentOrder>;
  refresh: UserOutput;
  review?: Maybe<Review>;
  reviews: Array<Review>;
  sasUrl: Scalars['String'];
  searchApplicationsV2: ApplicationPaginatedOutput;
  searchBrandsV2: BrandPaginatedOutput;
  searchExtensionsV2: ExtensionPaginatedOutput;
  searchKeywordList: Array<SearchKeywordListReturn>;
  searchProducts: ProductPaginatedOutput;
  searchProductsV2: ProductPaginatedOutput;
  searchTagsV2: TagPaginatedOutput;
  sentGift: OrderGift;
  sentGifts: Array<OrderGift>;
  settle?: Maybe<UserSettle>;
  settlement: Scalars['String'];
  settlementExcel: Scalars['String'];
  settlementExcelDownloadHistory: Array<SettlementExcelDownloadHistory>;
  settlementExportHistories: SettlementExportHistoryPaginatedOutput;
  settlementOrderExcel: Scalars['String'];
  sign: ResultAndUser;
  signedUser: UserOutput;
  /** 비슷한 작품들을 가져오는 메소드입니다. */
  similarProductList: RelateProductListOutput;
  syncCategory: Array<BrandCategoryReturn>;
  tags: TagPaginatedOutput;
  tempReviews: PaginatedReview;
  termAgree?: Maybe<UserTermAgreeOutput>;
  /** 비슷한 작품들을 가져오는 메소드입니다. */
  togetherProductListRecommendation: ProductListReturn;
  unfilteredProductCards: Array<Maybe<Acon3dProduct>>;
};

export type QueryGodo_Event_Order_CompleteArgs = {
  lang: Scalars['String'];
  orderNo: Scalars['String'];
};

export type QueryGodo_Event_Order_RefundArgs = {
  goodsNos: Array<Scalars['String']>;
  orderNo: Scalars['String'];
};

export type QueryGodo_Event_Order_SettleArgs = {
  goodsNo: Array<Scalars['String']>;
  lang: Scalars['String'];
  orderNo: Scalars['String'];
};

export type QueryGodo_Update_IsrefundedArgs = {
  goodsNos: Array<Scalars['String']>;
  orderNo: Scalars['String'];
};

export type QueryAdminAssetArgs = {
  id: Scalars['Int'];
  language: Language_Code;
};

export type QueryAdminBrandAssetsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  brandName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryAdminBrandPickArgs = {
  brandId: Scalars['Float'];
};

export type QueryAdminDocumentArgs = {
  id: Scalars['String'];
  language: Language_Code;
};

export type QueryAdminDocumentsArgs = {
  assetId?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['Int']>;
  game?: InputMaybe<Scalars['Boolean']>;
  imgsrc?: InputMaybe<Scalars['Boolean']>;
  industries?: InputMaybe<Array<DocumentIndustryInput>>;
  isOpenrunPromotion?: InputMaybe<Scalars['Boolean']>;
  lecture?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  manager?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  onView?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<DocumentsOrderCriteria>;
  requestedEnd?: InputMaybe<Scalars['DateTime']>;
  requestedStart?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<Document_Status>>>;
  statusChangedEnd?: InputMaybe<Scalars['DateTime']>;
  statusChangedStart?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  webtoon?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAdminDownloadArgs = {
  id: Scalars['String'];
};

export type QueryAdminInquiriesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoMemNo?: InputMaybe<Scalars['String']>;
  isClipped?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderCol?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};

export type QueryAdminInquiryArgs = {
  id: Scalars['Int'];
};

export type QueryAdminInquiryCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoMemNo?: InputMaybe<Scalars['String']>;
  isClipped?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderCol?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};

export type QueryAdminOrderCouponInfoArgs = {
  orderNo: Scalars['String'];
};

export type QueryAdminOrderEventHistoryArgs = {
  orderId: Scalars['String'];
};

export type QueryAdminOrderGoodsPriceInfoArgs = {
  input: OrderGoodsPriceInfoArgs;
};

export type QueryAdminOrderPaymentInfoArgs = {
  orderNo: Scalars['String'];
};

export type QueryAdminTranslateArgs = {
  lang: LanguageCodeEnum;
  text: Scalars['String'];
  toLangs: Array<LanguageCodeEnum>;
};

export type QueryAdultProductCardsSummaryArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<ProductListSort>;
};

export type QueryAssetArgs = {
  id: Scalars['Int'];
};

export type QueryAssetAdminDownloadArgs = {
  id: Scalars['Int'];
};

export type QueryAssetFileInfoArgs = {
  id: Scalars['Int'];
  language: Language_Code;
};

export type QueryAssetsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  brandName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryBenefitProductsArgs = {
  lang: Scalars['String'];
  productType?: InputMaybe<ProductType>;
};

export type QueryBestProductsArgs = {
  categoryCode?: InputMaybe<Scalars['String']>;
  isAdultProductInclude?: InputMaybe<Scalars['Boolean']>;
  isFreeProductInclude?: InputMaybe<Scalars['Boolean']>;
  type: BestProductType;
};

export type QueryBrandAssetsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  brandName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryBrandPickSummaryArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  isAdult?: InputMaybe<Scalars['Boolean']>;
  isNew?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductListSort>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryBrandProductsWithCategoriesArgs = {
  brandCode: Scalars['String'];
  languageCode: LanguageCodeEnum;
};

export type QueryBrandPromotionArgs = {
  id: Scalars['Int'];
};

export type QueryBrandPromotionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<PaginatedBrandPromotionArgsSearch>;
};

export type QueryBrandReviewsArgs = {
  brandId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBrandsArgs = {
  condition: BrandsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: BrandsInput_Sort;
};

export type QueryCartsArgs = {
  lang: Scalars['String'];
  noCache?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCategoriesV2Args = {
  condition: CategoriesInput_Condition;
  language: Language_Code;
  sort: CategoriesInput_Sort;
};

export type QueryCategoriesV3Args = {
  languageCode: LanguageCodeEnum;
};

export type QueryCheckExistMemberArgs = {
  accessToken: Scalars['String'];
  snsType: SnsTypeEnum;
};

export type QueryCheckHasAdultProductsArgs = {
  condition: CheckHasAdultProducts_Condition;
  language: Language_Code;
};

export type QueryCheckIssuanceTempPasswordArgs = {
  aconMemberId: Scalars['Int'];
  snsType: SnsTypeEnum;
};

export type QueryCheckedPasswordChangeArgs = {
  key: Scalars['String'];
};

export type QueryChildCategoriesArgs = {
  childCategoriesInput: ChildCategoriesInput;
};

export type QueryClipsV2Args = {
  pagination?: InputMaybe<PaginationInput>;
  productsCondition?: InputMaybe<GetClipsProductsCondition>;
  sort: GetClipsSort;
};

export type QueryCompareArgs = {
  id: Scalars['String'];
  language: Language_Code;
};

export type QueryCreatePartnerPlanArgs = {
  isExclusive: Scalars['Boolean'];
  type: Scalars['Float'];
};

export type QueryDescendantsOfRegularCategoryArgs = {
  descendantsOfRegularCategoryInput: DescendantsOfRegularCategoryInput;
};

export type QueryDisplayAssetWithItemsArgs = {
  language: Language_Code;
  pagination: PaginationInput;
  type: Scalars['String'];
};

export type QueryDisplayBrandWithItemsArgs = {
  language: Language_Code;
  pagination: PaginationInput;
  type: Scalars['String'];
};

export type QueryDocumentArgs = {
  id: Scalars['String'];
};

export type QueryDocumentsArgs = {
  assetId?: InputMaybe<Scalars['Int']>;
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  content?: InputMaybe<Scalars['String']>;
  createdInterval?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedInterval?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  status?: InputMaybe<Array<InputMaybe<Partner_Document_Status>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryDownloadContractArgs = {
  userId: Scalars['Int'];
};

export type QueryExchangeRateArgs = {
  languageCode: LanguageCodeEnum;
};

export type QueryExistsPartnerArgs = {
  account: Scalars['String'];
};

export type QueryFaqArgs = {
  id: Scalars['Int'];
};

export type QueryFaqAconArgs = {
  id: Scalars['Int'];
};

export type QueryFaqAconCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type QueryFaqAconTypesArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type QueryFaqAconsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type QueryFaqCreatorArgs = {
  id: Scalars['Int'];
};

export type QueryFaqCreatorCategoriesArgs = {
  id: Scalars['Int'];
};

export type QueryFaqCreatorCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type QueryFaqCreatorTypesArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type QueryFaqCreatorsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type QueryFetchPromotionArgs = {
  id: Scalars['Int'];
};

export type QueryFetchPromotionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<FetchPromotionsSearchInput>;
};

export type QueryFileByTempPathArgs = {
  tempPath: Scalars['String'];
};

export type QueryFileInfoArgs = {
  id: Scalars['Float'];
};

export type QueryFundingProductNosArgs = {
  isAdult?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetAdminOrderArgs = {
  orderNo: Scalars['String'];
};

export type QueryGetAdminOrdersArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoGoodsNo?: InputMaybe<Scalars['Int']>;
  godoMemId?: InputMaybe<Scalars['String']>;
  godoOrderEmail?: InputMaybe<Scalars['String']>;
  godoOrderNo?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryGetAdminOrdersCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoGoodsNo?: InputMaybe<Scalars['Int']>;
  godoMemId?: InputMaybe<Scalars['String']>;
  godoOrderEmail?: InputMaybe<Scalars['String']>;
  godoOrderNo?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryGetAdminReviewArgs = {
  id: Scalars['Int'];
};

export type QueryGetAdminReviewsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  isCollectScrap?: InputMaybe<Scalars['Boolean']>;
  isImageUrl?: InputMaybe<Scalars['Boolean']>;
  isWithDeleted?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordType?: InputMaybe<KeywordTypeEnum>;
  originalLanguageCode?: InputMaybe<LanguageCodeEnum>;
  rating?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryGetAdminReviewsCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  isCollectScrap?: InputMaybe<Scalars['Boolean']>;
  isImageUrl?: InputMaybe<Scalars['Boolean']>;
  isWithDeleted?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordType?: InputMaybe<KeywordTypeEnum>;
  originalLanguageCode?: InputMaybe<LanguageCodeEnum>;
  rating?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryGetAttendedDatesArgs = {
  eventId: Scalars['Float'];
};

export type QueryGetBadgeArgs = {
  id?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetBadgesArgs = {
  id?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetBandBannerArgs = {
  id: Scalars['Int'];
};

export type QueryGetBandBannerForPageArgs = {
  lang: Scalars['String'];
};

export type QueryGetBandBannersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetBannerArgs = {
  id: Scalars['Float'];
};

export type QueryGetBannersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetBannersByGodoNoArgs = {
  godoGoodsNo: Scalars['Float'];
  lang: Scalars['String'];
};

export type QueryGetBenefitBurdenListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetBrandArgs = {
  brandCode: Scalars['String'];
  lang: LanguageCodeEnum;
};

export type QueryGetBrandForAdminArgs = {
  brandId: Scalars['Int'];
};

export type QueryGetBrandOrderProductItemsArgs = {
  condition?: InputMaybe<GetBrandOrderProductItemsInput>;
  pagination: PaginationInput;
};

export type QueryGetBrandOrderProductItemsExcelArgs = {
  condition?: InputMaybe<GetBrandOrderProductItemsInput>;
  lang: LanguageCodeEnum;
};

export type QueryGetBrandSettlementOrderExcelArgs = {
  lang: LanguageCodeEnum;
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type QueryGetBrandSettlementsArgs = {
  pagination: PaginationInput;
};

export type QueryGetBrandsForAdminArgs = {
  brandName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profileModifiedEndDate?: InputMaybe<Scalars['DateTime']>;
  profileModifiedStartDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryGetCashListArgs = {
  endDate: Scalars['String'];
  page: Scalars['Int'];
  startDate: Scalars['String'];
  type: Scalars['String'];
};

export type QueryGetCategoriesByLangArgs = {
  lang: Scalars['String'];
};

export type QueryGetCategoryArgs = {
  godoCateCd: Scalars['String'];
};

export type QueryGetCountsArgs = {
  lang: Scalars['String'];
  viewOnlyActiveCoupons: Scalars['Boolean'];
};

export type QueryGetCouponBenefitArgs = {
  id: Scalars['Int'];
};

export type QueryGetCouponBenefitsArgs = {
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCouponBenefitsTotalCountArgs = {
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCouponBurdenListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCouponItemsArgs = {
  couponName?: InputMaybe<Scalars['String']>;
  couponNos?: InputMaybe<Array<Scalars['String']>>;
  couponRegDt?: InputMaybe<Scalars['String']>;
};

export type QueryGetCouponListArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  page: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type QueryGetDownloadLogsArgs = {
  goodsNo?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderNo?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGetEventBannerItemArgs = {
  id: Scalars['Int'];
};

export type QueryGetEventBannerItemsArgs = {
  isInprogressEvent: Scalars['Boolean'];
  lang: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetEventBannerTotalCountArgs = {
  isInprogressEvent: Scalars['Boolean'];
  lang: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetFaqArgs = {
  id: Scalars['Int'];
};

export type QueryGetFaqsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetFaqsTotalCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetGodoBannerInfoArgs = {
  bannerCode: Scalars['Float'];
};

export type QueryGetGodoBrandNameArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
};

export type QueryGetGodoPopupArgs = {
  url: Scalars['String'];
};

export type QueryGetGoodsBuyersArgs = {
  goodsNo: Scalars['Float'];
};

export type QueryGetItemArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetItemsArgs = {
  lang: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
  viewOnlyActiveCoupons: Scalars['Boolean'];
};

export type QueryGetMyOrdersArgs = {
  languageCode: LanguageCodeEnum;
};

export type QueryGetNoticeArgs = {
  id: Scalars['Int'];
};

export type QueryGetNoticesArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetNoticesTotalCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetOrderBenefitPointArgs = {
  godoOrderNo: Scalars['String'];
};

export type QueryGetOrderCouponItemsArgs = {
  couponApplyOrderNo?: InputMaybe<Scalars['String']>;
  godoCartSnos?: InputMaybe<Array<Scalars['String']>>;
  lang?: InputMaybe<Scalars['String']>;
};

export type QueryGetOrderInfoArgs = {
  godoCartNos?: InputMaybe<Array<Scalars['String']>>;
  lang?: InputMaybe<Scalars['String']>;
};

export type QueryGetOrderNoWithoutReviewArgs = {
  godoGoodsNo: Scalars['String'];
};

export type QueryGetPaymentPointArgs = {
  godoGoodsNo?: InputMaybe<Array<Scalars['Int']>>;
  godoPrice?: InputMaybe<Array<Scalars['Int']>>;
  lang?: InputMaybe<Scalars['String']>;
};

export type QueryGetPhotoReviewArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  godoCateCd?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPhotoReviewTotalCountArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  godoCateCd?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPhotoReviewsArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  godoCateCd?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPointBenefitArgs = {
  id: Scalars['Int'];
};

export type QueryGetPointBenefitsArgs = {
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPointBenefitsTotalCountArgs = {
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPointLimitPolicyArgs = {
  id: Scalars['Int'];
};

export type QueryGetPointLimitPolicyListArgs = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryGetPointListArgs = {
  endDate: Scalars['String'];
  lang: Scalars['String'];
  page: Scalars['Int'];
  startDate: Scalars['String'];
};

export type QueryGetPopupsHereArgs = {
  origin: Scalars['String'];
  url: Scalars['String'];
};

export type QueryGetProductLangArgs = {
  godoGoodsNo?: InputMaybe<Array<Scalars['Int']>>;
  lang: Scalars['String'];
};

export type QueryGetPromotedProductsArgs = {
  code: Scalars['String'];
  lang: LanguageCodeEnum;
  take: Scalars['Int'];
};

export type QueryGetPromotionArgs = {
  id: Scalars['Int'];
};

export type QueryGetPromotionConceptArgs = {
  id: Scalars['Int'];
};

export type QueryGetPromotionCountArgs = {
  brandName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetPromotionProductsArgs = {
  code: Scalars['String'];
  lang: LanguageCodeEnum;
  take: Scalars['Int'];
};

export type QueryGetPromotionsArgs = {
  brandName?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetReviewsByIrregularCategoryArgs = {
  categoryId: Scalars['Int'];
  lang: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
  sortType?: InputMaybe<Scalars['String']>;
};

export type QueryGetSettleAllListV2Args = {
  endDate?: InputMaybe<Scalars['String']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetSettleAllPriceArgs = {
  brandId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goodsNm?: InputMaybe<Scalars['String']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  penNm?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetSettleCountArgs = {
  brandId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goodsNm?: InputMaybe<Scalars['String']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  penNm?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetSettleListV2Args = {
  brandId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goodsNm?: InputMaybe<Scalars['String']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  penNm?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetSettleV2Args = {
  goodsNo: Scalars['Int'];
  orderNo: Scalars['String'];
};

export type QueryGetTermArgs = {
  type: Scalars['String'];
};

export type QueryGetTransGodoCategoryChildItemsArgs = {
  cateCds: Array<Scalars['String']>;
  lang: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetTransGodoCategoryItemsArgs = {
  cateCds: Array<Scalars['String']>;
  lang: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetValidationGoodsNoArgs = {
  godoGoodsNo?: InputMaybe<Scalars['Int']>;
  lang?: InputMaybe<Scalars['String']>;
};

export type QueryGiftArgs = {
  id: Scalars['Float'];
};

export type QueryGodoCometDealListArgs = {
  godoGoodsNo: Scalars['Int'];
};

export type QueryGodoCosmicDealListArgs = {
  godoGoodsNo: Scalars['Int'];
};

export type QueryGodoMyOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryGodoUserInvitationCodeArgs = {
  langCode: Scalars['String'];
};

export type QueryHasOpenrunPromotedProductsArgs = {
  assetIds: Array<Scalars['Int']>;
};

export type QueryHasOrderedArgs = {
  assetId: Scalars['Float'];
};

export type QueryHaveRegisteredPromotionArgs = {
  id: Scalars['Int'];
};

export type QueryHotProductsArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type QueryHubDownloadArgs = {
  id: Scalars['String'];
};

export type QueryIndustriesArgs = {
  condition: IndustriesInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: IndustriesInput_Sort;
};

export type QueryInquiriesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoMemNo?: InputMaybe<Scalars['String']>;
  isClipped?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderCol?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};

export type QueryInquiryArgs = {
  id: Scalars['Int'];
};

export type QueryInquiryCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoMemNo?: InputMaybe<Scalars['String']>;
  isClipped?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderCol?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};

export type QueryInvitationLangCodeArgs = {
  code: Scalars['String'];
};

export type QueryIsDuplicateBrandNameArgs = {
  lang: Scalars['String'];
  name: Scalars['String'];
};

export type QueryIsEqualPasswordArgs = {
  password: Scalars['String'];
};

export type QueryIsExistPromotionArgs = {
  godoGoodsNo: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type QueryIsFirstDownloadArgs = {
  goodsNo: Scalars['Int'];
  orderNo: Scalars['String'];
};

export type QueryIsLeavedBrandProductArgs = {
  goodsNo: Scalars['Float'];
};

export type QueryIsParticipatedEventArgs = {
  eventId: Scalars['Float'];
};

export type QueryIsUpdateArgs = {
  goodsNo: Scalars['String'];
  lang: Scalars['String'];
};

export type QueryMainProductListV2Args = {
  lang: Scalars['String'];
  sno: Scalars['Int'];
};

export type QueryMemberEventPeriodPaidAmountArgs = {
  eventId: Scalars['Float'];
};

export type QueryMemberPeriodPaidAmountArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type QueryMyAssetsArgs = {
  daysAfterReleased?: InputMaybe<Scalars['Int']>;
  includeFree?: InputMaybe<Scalars['Boolean']>;
  includeIrregularPrice?: InputMaybe<Scalars['Boolean']>;
  isIncludeBook?: InputMaybe<Scalars['Boolean']>;
  isOnPromotion?: InputMaybe<Scalars['Boolean']>;
  language: Language_Code;
  onSaleOnly?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryNoticeArgs = {
  id: Scalars['Int'];
};

export type QueryOpenrunPromotionsArgs = {
  languageCode?: InputMaybe<LanguageCodeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryOrderGoodsWithExchangeRateArgs = {
  settlementEndDate: Scalars['String'];
  settlementStartDate: Scalars['String'];
};

export type QueryOrderLicenseArgs = {
  orderNo: Scalars['Float'];
};

export type QueryOrderProductsArgs = {
  lang: Scalars['String'];
  orderNo: Scalars['Float'];
};

export type QueryOrderViewV2Args = {
  lang: Scalars['String'];
  orderNo: Scalars['Float'];
};

export type QueryOrderedProductCardsArgs = {
  isAdult?: InputMaybe<Scalars['Boolean']>;
  isExperimental?: InputMaybe<Scalars['Boolean']>;
  isIncludeNotForSale?: InputMaybe<Scalars['Boolean']>;
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryOrderedProductsArgs = {
  condition: OrderedProductsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: OrderedProductsInput_Sort;
};

export type QueryOtherProductListArgs = {
  godoGoodsNo: Scalars['Int'];
  isAdult?: InputMaybe<Scalars['Boolean']>;
  isCount: Scalars['Boolean'];
  isFresh?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
};

export type QueryPackageArgs = {
  id: Scalars['Float'];
};

export type QueryPaginateProductCardsArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  filter: ProductListFilterInput;
  languageCode: LanguageCodeEnum;
  limit?: InputMaybe<Scalars['Int']>;
  onlyAdult?: InputMaybe<Scalars['Boolean']>;
  onlyDiscount?: InputMaybe<Scalars['Boolean']>;
  onlyFree?: InputMaybe<Scalars['Boolean']>;
  onlyNew?: InputMaybe<Scalars['Boolean']>;
  option?: InputMaybe<ProductListOptionInput>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductListSort>;
  type?: InputMaybe<Array<ProductTypes>>;
};

export type QueryPartnerArgs = {
  id: Scalars['Int'];
};

export type QueryPartnerAcceptStatusNumberArgs = {
  modifyStatus?: InputMaybe<AcceptStatusEnum>;
  status: AcceptStatusEnum;
};

export type QueryPartnerExcelDownloadHistoriesArgs = {
  documentClass: PartnerExcelDocumentClassEnum;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryPartnerSettleAdminArgs = {
  id: Scalars['Int'];
};

export type QueryPartnerSettlesArgs = {
  account?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  brandCode?: InputMaybe<Scalars['String']>;
  brandName?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  isOversea?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<PartnerSettlesSortCriteria>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  settlementStatuses?: InputMaybe<Array<PartnerSettleStatusEnum>>;
  userSubType?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<PartnerTypeEnum>;
};

export type QueryPartnerSettlesExcelArgs = {
  account?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  brandCode?: InputMaybe<Scalars['String']>;
  brandName?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  isOversea?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<PartnerSettlesSortCriteria>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  settlementStatuses?: InputMaybe<Array<PartnerSettleStatusEnum>>;
  userSubType?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<PartnerTypeEnum>;
};

export type QueryPartnersArgs = {
  acceptStatus?: InputMaybe<Array<AcceptStatusEnum>>;
  acceptedEndDate?: InputMaybe<Scalars['DateTime']>;
  acceptedStartDate?: InputMaybe<Scalars['DateTime']>;
  account?: InputMaybe<Scalars['String']>;
  brandCode?: InputMaybe<Scalars['String']>;
  brandName?: InputMaybe<Scalars['String']>;
  isOversea?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  plan?: InputMaybe<PartnerPlanInput>;
};

export type QueryPartnersExcelArgs = {
  acceptStatus?: InputMaybe<Array<AcceptStatusEnum>>;
  acceptedEndDate?: InputMaybe<Scalars['DateTime']>;
  acceptedStartDate?: InputMaybe<Scalars['DateTime']>;
  account?: InputMaybe<Scalars['String']>;
  brandCode?: InputMaybe<Scalars['String']>;
  brandName?: InputMaybe<Scalars['String']>;
  isOversea?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  plan?: InputMaybe<PartnerPlanInput>;
};

export type QueryPopupArgs = {
  id: Scalars['Int'];
};

export type QueryPopupCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<PopupLocationV2>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  orderCol?: InputMaybe<PopupOrderCol>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<PopupStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryPopupsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<PopupLocationV2>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  orderCol?: InputMaybe<PopupOrderCol>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<PopupStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type QueryPresetBrandsArgs = {
  language: Language_Code;
  pagination: PaginationInput;
  preset: Preset_Brands_Input_Preset;
};

export type QueryPresetProductsArgs = {
  language: Language_Code;
  pagination: PaginationInput;
  preset: Preset_Products_Preset;
};

export type QueryProductArgs = {
  id: Scalars['Float'];
  language: Language_Code;
};

export type QueryProductCardsArgs = {
  isAdult?: InputMaybe<Scalars['Boolean']>;
  isExperimental?: InputMaybe<Scalars['Boolean']>;
  isIncludeNotForSale?: InputMaybe<Scalars['Boolean']>;
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryProductCardsSummaryArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  isAdult?: InputMaybe<Scalars['Boolean']>;
  isNew?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductListSort>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryProductContentArgs = {
  assetId: Scalars['Int'];
  lang: Scalars['String'];
};

export type QueryProductExtensionsArgs = {
  condition: ProductExtensionsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
};

export type QueryProductTagsArgs = {
  condition: ProductTagsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
};

export type QueryProductsArgs = {
  condition: ProductsConditionInput;
  language: Language_Code;
  pagination: PaginationInput;
  sort: Sort;
};

export type QueryReceivedGiftArgs = {
  orderGiftId: Scalars['String'];
};

export type QueryRefreshArgs = {
  account: Scalars['String'];
};

export type QueryReviewArgs = {
  reviewId: Scalars['Int'];
};

export type QueryReviewsArgs = {
  goodsNo: Scalars['String'];
  isExperimental?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
  rating?: InputMaybe<Array<Scalars['Int']>>;
  sortType?: InputMaybe<Scalars['String']>;
};

export type QuerySasUrlArgs = {
  inputs: SasUrlInput;
};

export type QuerySearchApplicationsV2Args = {
  condition: SearchProductsV2Input_Condition;
  https?: InputMaybe<Scalars['Boolean']>;
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  sort: SearchProductsV2Input_Sort;
};

export type QuerySearchBrandsV2Args = {
  https?: InputMaybe<Scalars['Boolean']>;
  keyword: Scalars['String'];
  language: Language_Code;
};

export type QuerySearchExtensionsV2Args = {
  condition: SearchProductsV2Input_Condition;
  https?: InputMaybe<Scalars['Boolean']>;
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  sort: SearchProductsV2Input_Sort;
};

export type QuerySearchKeywordListArgs = {
  lang: Scalars['String'];
};

export type QuerySearchProductsArgs = {
  condition: SearchProductsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: SearchProductsInput_Sort;
};

export type QuerySearchProductsV2Args = {
  condition: SearchProductsV2Input_Condition;
  https?: InputMaybe<Scalars['Boolean']>;
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  sort: SearchProductsV2Input_Sort;
};

export type QuerySearchTagsV2Args = {
  condition: SearchProductsV2Input_Condition;
  https?: InputMaybe<Scalars['Boolean']>;
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  sort: SearchProductsV2Input_Sort;
};

export type QuerySentGiftArgs = {
  orderGiftId: Scalars['String'];
};

export type QuerySettlementArgs = {
  settlementEndDate: Scalars['String'];
  settlementStartDate: Scalars['String'];
};

export type QuerySettlementExcelArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type QuerySettlementExportHistoriesArgs = {
  pagination: PaginationInput;
};

export type QuerySettlementOrderExcelArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type QuerySignArgs = {
  account: Scalars['String'];
  password: Scalars['String'];
};

export type QuerySimilarProductListArgs = {
  godoGoodsNo: Scalars['Int'];
  isAdult?: InputMaybe<Scalars['Boolean']>;
  isCount: Scalars['Boolean'];
  isFresh?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
};

export type QuerySyncCategoryArgs = {
  brandCd?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type QueryTagsArgs = {
  condition: TagsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: TagsInput_Sort;
};

export type QueryTempReviewsArgs = {
  condition: ReviewsInput_Condition;
  pagination: ReviewsInput_Pagination;
  sort: ReviewsInput_Sort;
};

export type QueryTogetherProductListRecommendationArgs = {
  godoGoodsNo: Scalars['Int'];
  isCount: Scalars['Boolean'];
  isFresh?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
};

export type QueryUnfilteredProductCardsArgs = {
  isAdult?: InputMaybe<Scalars['Boolean']>;
  isExperimental?: InputMaybe<Scalars['Boolean']>;
  isIncludeNotForSale?: InputMaybe<Scalars['Boolean']>;
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']>>;
};

export enum Reviews_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Reviews_Input_Sort_Key {
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

export type RecentOrder = {
  __typename?: 'RecentOrder';
  orderNo: Scalars['Float'];
  orderedAt: Scalars['DateTime'];
  orderedProducts: Array<RecentOrderedProduct>;
  settlePrice: Scalars['Float'];
};

export type RecentOrderedProduct = {
  __typename?: 'RecentOrderedProduct';
  acon3dProduct: Acon3dProduct;
  godoGoodsNo: Scalars['Float'];
  hasWrittenReview: Scalars['Boolean'];
  price: Scalars['Float'];
  product?: Maybe<Acon3dProduct>;
  reviewId?: Maybe<Scalars['Float']>;
  reviewSno?: Maybe<Scalars['Float']>;
  sno: Scalars['Float'];
  status: Scalars['String'];
  statusString: Scalars['String'];
  title: Scalars['String'];
};

export type RecentOrderedProductProductArgs = {
  langCode?: InputMaybe<Scalars['String']>;
};

/** 결제-취소/환불(H1-H3) */
export type RefundAccountInput = {
  /** 예금주 */
  accountHolder: Scalars['String'];
  /** 계좌번호 */
  accountNumber: Scalars['String'];
  /** 은행명 */
  bankName: Scalars['String'];
};

export type RegisterBrandPromotionInput = {
  items: Array<RegisterBrandPromotionInputItem>;
  promotionId: Scalars['Int'];
};

export type RegisterBrandPromotionInputItem = {
  assetId: Scalars['Int'];
  discountType: EPromotionDiscountType;
  discountValue: Scalars['Float'];
};

export type RegisterOpenrunPromotionInput = {
  /** 브랜드 ID */
  brandId: Scalars['Int'];
  /** document id(uuid) */
  id: Scalars['String'];
};

export type RegisterPartnerMemoInput = {
  /** 메모 내용 */
  memo: Scalars['String'];
  /** 분류(사용자, 정산) */
  memoType?: InputMaybe<PartnerMemoTypeEnum>;
};

export type RegisterPromotionInput = {
  /** 프로모션 컨셉 ID */
  conceptId: Scalars['Int'];
  description: GqlRegionalInput;
  /** 할인 타입 */
  discountType: EPromotionDiscountType;
  /** 할인 금액(퍼센트인 경우는 퍼센트), decimal(10, 2) */
  discountValue: Scalars['Float'];
  /** 프로모션 ID */
  id?: InputMaybe<Scalars['Int']>;
  /** 프로모션 상품이 프로모션의 대표 할인정보를 그대로 상속받아 가져가는지 여부, 생략하면 디폴트는 true */
  inheritance?: InputMaybe<Scalars['Boolean']>;
  issuer: GqlRegionalInput;
  /** 에셋별 할인정보, 에셋 전체가 프로모션의 할인정보를 상속받을 경우 id만 채워도 된다. inheritance === true로 설정해둘 것 */
  items: Array<PromotionItemInput>;
  /** 할인 시작일자 */
  since: Scalars['DateTime'];
  /** 프로모션 이름 */
  title: Scalars['String'];
  /** 할인 종료일자  */
  until: Scalars['DateTime'];
};

export type RegisterReviewInput = {
  contents: Scalars['String'];
  godoGoodsNo: Scalars['Int'];
  godoOrderNo: Scalars['String'];
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  isSecret: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  rating: Scalars['Int'];
};

export type RegisterSnsMemberOutput = {
  __typename?: 'RegisterSnsMemberOutput';
  email: Scalars['String'];
  id: Scalars['Int'];
  mobile?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum RegularCategoryType {
  Game = 'GAME',
  Genre = 'GENRE',
  Lecture = 'LECTURE',
  Theme = 'THEME',
}

export type RejectOpenrunPromotionInput = {
  /** document id(uuid) */
  id: Scalars['String'];
  /** 거절 사유, 승인상태가 거절시 필수 */
  rejectReason?: InputMaybe<Scalars['String']>;
};

export type RelateProductListItem = {
  __typename?: 'RelateProductListItem';
  /** 브랜드 명 */
  brand: Scalars['String'];
  /** 브랜드 코드 */
  brandCd: Scalars['String'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 가격 */
  price: Scalars['String'];
  /** 가격 */
  realPrice: Scalars['String'];
  /** 제목 */
  title: Scalars['String'];
};

export type RelateProductListOutput = {
  __typename?: 'RelateProductListOutput';
  /** 브랜드 코드 */
  brandCd?: Maybe<Scalars['String']>;
  /** 카테고리 코드 */
  cateCd?: Maybe<Scalars['String']>;
  /** 개수 */
  count?: Maybe<Scalars['Float']>;
  /** 비슷한 작품 항목들 */
  items?: Maybe<Array<RelateProductListItem>>;
};

export type RemoveClipInput = {
  godoGoodsNo: Scalars['Int'];
};

export type RemoveClipsInput = {
  godoGoodsNos: Array<Scalars['Int']>;
};

export type RequestPartnerRevisionFileInput = {
  sign?: InputMaybe<StoredFileInput>;
};

export type RequestPartnerRevisionInput = {
  /** 브랜드 데이터 */
  brand?: InputMaybe<UpdatePartnerBrandInput>;
  /** 회사명 */
  companyName?: InputMaybe<Scalars['String']>;
  /** 연락처 */
  contact?: InputMaybe<Scalars['String']>;
  /** 국가 코드 */
  country?: InputMaybe<Scalars['String']>;
  /** 계약/정산 관련 파일 */
  file?: InputMaybe<RequestPartnerRevisionFileInput>;
  /** 사용자명 */
  name?: InputMaybe<Scalars['String']>;
  /** 개인식별번호(like. 주민번호) */
  nationalIdNumber?: InputMaybe<Scalars['String']>;
  /** 참고 url */
  reference?: InputMaybe<Array<UpdatePartnerReferenceInput>>;
};

export type ResultAndUser = {
  __typename?: 'ResultAndUser';
  item?: Maybe<UserOutput>;
  result: Scalars['Boolean'];
};

export type Review = {
  __typename?: 'Review';
  adminUpdateHistories?: Maybe<Array<ReviewAdminUpdateHistory>>;
  author: Scalars['String'];
  commentsCount: Scalars['Int'];
  created: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  favoriteCount?: Maybe<Scalars['Int']>;
  godoGoodsNo: Scalars['Int'];
  godoMemNo: Scalars['Int'];
  godoOrderNo?: Maybe<Scalars['String']>;
  godoUserId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** 리뷰 대표이미지 */
  imageUrl?: Maybe<Scalars['String']>;
  isBestReview: Scalars['Boolean'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isImage: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isScrap?: Maybe<Scalars['Boolean']>;
  isSecret: Scalars['Boolean'];
  nonMaskedGodoUserId: Scalars['String'];
  product?: Maybe<Acon3dProduct>;
  /** 리뷰 별점 */
  rating: Scalars['Int'];
  reviewContentsItems: Array<ReviewContents>;
  reviewImages?: Maybe<Array<ReviewImage>>;
  reviewReplies?: Maybe<Array<ReviewReply>>;
  userUpdateHistories?: Maybe<Array<ReviewUpdateHistory>>;
};

export type ReviewProductArgs = {
  languageCode: LanguageCodeEnum;
};

export type ReviewReviewContentsItemsArgs = {
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<Scalars['String']>;
};

export type ReviewAdminUpdateHistory = {
  __typename?: 'ReviewAdminUpdateHistory';
  adminId: Scalars['String'];
  content: Scalars['String'];
  date?: Maybe<Scalars['String']>;
};

export type ReviewContents = {
  __typename?: 'ReviewContents';
  contents: Scalars['String'];
  /** 원본 리뷰 여부 */
  isOriginal: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  reviewId: Scalars['Int'];
  title: Scalars['String'];
};

export type ReviewEdge = {
  __typename?: 'ReviewEdge';
  cursor?: Maybe<Scalars['Int']>;
  node?: Maybe<Review>;
};

export type ReviewImage = {
  __typename?: 'ReviewImage';
  imageNumber: Scalars['Int'];
  imageUrl: Scalars['String'];
};

export type ReviewReply = {
  __typename?: 'ReviewReply';
  adminUser: AdminUser;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  reviewId: Scalars['Int'];
  reviewReplyContents?: Maybe<Array<ReviewReplyContent>>;
};

export type ReviewReplyContent = {
  __typename?: 'ReviewReplyContent';
  content: Scalars['String'];
  /** 원본 리뷰 여부 */
  isOriginal: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  reviewReplyId: Scalars['Int'];
};

export type ReviewUpdateHistory = {
  __typename?: 'ReviewUpdateHistory';
  date?: Maybe<Scalars['String']>;
};

export type ReviewsInput_Condition = {
  /** 상품 카테고리 */
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** 베스트 리뷰 여부 */
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  /** 성인상품 여부 */
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
  /** 포토 리뷰 여부 */
  isPhotoReview?: InputMaybe<Scalars['Boolean']>;
};

export type ReviewsInput_Pagination = {
  /** 2. cursor */
  cursor?: InputMaybe<Scalars['Int']>;
  /** 1. offset */
  offset?: InputMaybe<Scalars['Int']>;
  /** take */
  take: Scalars['Int'];
};

export type ReviewsInput_Sort = {
  criterion: Reviews_Input_Sort_Criterion;
  key: Reviews_Input_Sort_Key;
};

export enum Search_Products_Language {
  En = 'EN',
  Ja = 'JA',
  Ko = 'KO',
  Zh = 'ZH',
}

export enum Search_Products_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Search_Products_Sort_Key {
  Accuracy = 'ACCURACY',
  Id = 'ID',
  OrderCount = 'ORDER_COUNT',
  ReleasedAt = 'RELEASED_AT',
  SalePrice = 'SALE_PRICE',
}

export enum Search_Products_V2_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Search_Products_V2_Sort_Key {
  Accuracy = 'ACCURACY',
  Id = 'ID',
  OrderCount = 'ORDER_COUNT',
  ReleasedAt = 'RELEASED_AT',
  SalePrice = 'SALE_PRICE',
}

export type SasUrlInput = {
  /** upload file id */
  id: Scalars['Int'];
};

export type SavePromotionInput = {
  /** 프로모션 컨셉 ID */
  conceptId: Scalars['Int'];
  description?: InputMaybe<GqlRegionalInputNullable>;
  /** 할인 타입 */
  discountType?: InputMaybe<EPromotionDiscountType>;
  /** 할인 금액(퍼센트인 경우는 퍼센트), decimal(10, 2) */
  discountValue?: InputMaybe<Scalars['Float']>;
  /** 프로모션 ID : 없으면 POST로 인식해 새로 생성하고, 있으면 PUT으로 인식해 기존 Promotion을 업데이트함 */
  id?: InputMaybe<Scalars['Int']>;
  /** 프로모션 상품이 프로모션의 대표 할인정보를 그대로 상속받아 가져가는지 여부, 생략하면 디폴트는 true */
  inheritance?: InputMaybe<Scalars['Boolean']>;
  issuer?: InputMaybe<GqlRegionalInputNullable>;
  /** 에셋별 할인정보, 에셋 전체가 프로모션의 할인정보를 상속받을 경우 id만 채워도 된다. inheritance === true로 설정해둘 것 */
  items?: InputMaybe<Array<PromotionItemInput>>;
  /** 할인 시작일자 */
  since?: InputMaybe<Scalars['DateTime']>;
  /** 프로모션 이름 */
  title?: InputMaybe<Scalars['String']>;
  /** 할인 종료일자 */
  until?: InputMaybe<Scalars['DateTime']>;
};

export type SearchKeyword = {
  __typename?: 'SearchKeyword';
  creator?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  keyword: Scalars['String'];
  language?: Maybe<Language>;
  type: Scalars['String'];
  updater?: Maybe<Scalars['Float']>;
};

export type SearchKeywordListReturn = {
  __typename?: 'SearchKeywordListReturn';
  /** 키워드 */
  keyword: Scalars['String'];
  /** 언어 코드 */
  lang: Scalars['String'];
};

export type SearchProductsInput_Condition = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 요청 검색어(필수) */
  input: Scalars['String'];
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']>>;
};

export type SearchProductsInput_Sort = {
  criterion: Search_Products_Sort_Criterion;
  key: Search_Products_Sort_Key;
};

export type SearchProductsV2Input_Condition = {
  /** 응용프로그램 ID 배열 */
  applications: Array<Scalars['Int']>;
  /** 카테고리 ID 배열 */
  categories: Array<Scalars['Int']>;
  /** 확장자 ID 배열 */
  extensions: Array<Scalars['Int']>;
  /** 산업 ID 배열 */
  industries: Array<Scalars['Int']>;
  /** 요청 검색어(필수) */
  keyword: Scalars['String'];
  /** 최대 가격 */
  maxPrice?: InputMaybe<Scalars['Int']>;
  /** 최소 가격 */
  minPrice?: InputMaybe<Scalars['Int']>;
  /** 태그 ID 배열 */
  tags: Array<Scalars['Int']>;
  /** 성인용 포함 여부 */
  withAdultOnly?: InputMaybe<Scalars['Boolean']>;
};

export type SearchProductsV2Input_Language = {
  /** 언어 코드 */
  code: Search_Products_Language;
};

export type SearchProductsV2Input_Pagination = {
  /** 페이지 번호 */
  page: Scalars['Int'];
  /** 페이지 당 아이템 수 */
  size: Scalars['Int'];
};

export type SearchProductsV2Input_Sort = {
  criterion: Search_Products_V2_Sort_Criterion;
  key: Search_Products_V2_Sort_Key;
};

export type SetCategoryFileInput = {
  /** 업로드 파일 key */
  key?: InputMaybe<Scalars['String']>;
};

export type SetCategoryI18nInput = {
  /** 카테고리 URL */
  anchor?: InputMaybe<Scalars['String']>;
  /** 카테고리 ID */
  categoryId: Scalars['Float'];
  /** 색상 정보 */
  color?: InputMaybe<Scalars['String']>;
  /** 작성자 */
  creator?: InputMaybe<Scalars['Float']>;
  /** 파일 정보 */
  file?: InputMaybe<SetCategoryFileInput>;
  /** 새 창으로 열기 여부 */
  isBlankAnchor: Scalars['Float'];
  /** 검색 결과 창 표시 여부 */
  isShowResultCount: Scalars['Float'];
  /** 언어 코드 */
  lang: Scalars['String'];
  /** map */
  map?: InputMaybe<Scalars['String']>;
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 수정자 */
  updater?: InputMaybe<Scalars['Float']>;
};

export type Settle = {
  __typename?: 'Settle';
  /** 추가필드 */
  addField: Scalars['String'];
  /** 혜택 부담도 */
  benefitBrandBurden: Scalars['String'];
  /** 부담도 */
  brandBurden?: Maybe<Scalars['String']>;
  /** 브랜드코드 */
  brandCd: Scalars['String'];
  /** 쿠폰 상품 dc 가격 */
  couponGoodsDcPrice: Scalars['String'];
  /** 상품할인가격 */
  goodsDcPrice: Scalars['String'];
  /** 상품명 */
  goodsNm: Scalars['String'];
  goodsNo?: Maybe<Scalars['Int']>;
  /** 상품가격 */
  goodsPrice: Scalars['String'];
  /** 라이센스 유형 */
  licenseType?: Maybe<Scalars['String']>;
  /** 주문메모 */
  orderMemo: Scalars['String'];
  /** 주문자명 */
  orderName: Scalars['String'];
  /** 주문번호 */
  orderNo: Scalars['String'];
  /** 지불일자 */
  paymentDt: Scalars['DateTime'];
  /** 등록일 */
  regDt: Scalars['DateTime'];
  /** 정산일자 */
  settleDt: Scalars['DateTime'];
  /** sno */
  sno: Scalars['String'];
};

export type SettleCount = {
  __typename?: 'SettleCount';
  /** 검색 합계 */
  searchCount: Scalars['Int'];
  /** 총 합계 */
  totalCount: Scalars['Int'];
};

export type SettleDetail = {
  __typename?: 'SettleDetail';
  /** 고도몰 추가필드 */
  addField?: Maybe<Scalars['String']>;
  /** 혜택 브랜드 부담도 */
  benefitBrandBurden?: Maybe<Scalars['Int']>;
  /** 브랜드 부담도 */
  brandBurden?: Maybe<Scalars['Int']>;
  brandCd: Scalars['String'];
  /** 구매한 회원의 국가정보 */
  country?: Maybe<Scalars['String']>;
  couponGoodsDcPrice: Scalars['Int'];
  goodsDcPrice: Scalars['Int'];
  goodsNm: Scalars['String'];
  goodsPrice: Scalars['Int'];
  /** 주문한 쇼핑몰 국가코드 */
  languageCode: Scalars['String'];
  /** 개인/공동 사용권명 */
  licenseName: Scalars['String'];
  /** 사용권 유형 personal: 개인사용권, company: 공동사용권 */
  licenseType: Scalars['String'];
  orderName: Scalars['String'];
  orderNo: Scalars['String'];
  paymentDt: Scalars['DateTime'];
  /** 공동사용권 상품사용예정작품명 */
  projectName?: Maybe<Scalars['String']>;
  regDt: Scalars['DateTime'];
  settleDt: Scalars['DateTime'];
};

export type SettlementExcelDownloadHistory = {
  __typename?: 'SettlementExcelDownloadHistory';
  /** 문서 분류 */
  documentClass: Scalars['String'];
  /** 엑셀파일 다운로드 일자 */
  downloadDate: Scalars['DateTime'];
  /** 정산구간: 종료일자 */
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  /** 정산구간: 시작일자 */
  startDate: Scalars['DateTime'];
  /** 다운로드 유저 이름(ex. 미우) */
  userName: Scalars['String'];
};

export type SettlementExportHistoryOutput = {
  __typename?: 'SettlementExportHistoryOutput';
  /** 내보낸 일시 */
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  /** 대상 월 */
  month: Scalars['Int'];
  /** 요청자 이름 */
  name: Scalars['String'];
  /** 문서 종류 */
  type: Scalars['String'];
  /** 대상 년도 */
  year: Scalars['Int'];
};

export type SettlementExportHistoryPaginatedOutput = {
  __typename?: 'SettlementExportHistoryPaginatedOutput';
  data: Array<SettlementExportHistoryOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type SettlementOutput = {
  __typename?: 'SettlementOutput';
  /** 에이콘 수수료율 */
  aconCommission: Scalars['Float'];
  /** 에이콘 수수료 금액 */
  aconCommissionAmount: Scalars['Int'];
  /** 혜택(프로모션) 부담 금액 */
  benefitBurdenAmount: Scalars['Int'];
  /** 혜택(프로모션) 부담 금액(해외결제) */
  benefitBurdenAmountForeign: Scalars['Int'];
  brandId: Scalars['Int'];
  /** 추/차감 금액 */
  correctionAmount: Scalars['Int'];
  /** 쿠폰 할인 부담 금액 */
  couponBurdenAmount: Scalars['Int'];
  /** 쿠폰 할인 부담 금액(해외결제) */
  couponBurdenAmountForeign: Scalars['Int'];
  /** 적용 환율 */
  exchangeRate: Scalars['Float'];
  id?: Maybe<Scalars['Int']>;
  /** 독점 여부 */
  isExclusive: Scalars['Boolean'];
  /** 지방세율 */
  localTax: Scalars['Float'];
  /** 지방세 금액 */
  localTaxAmount: Scalars['Int'];
  /** 월 */
  month: Scalars['Int'];
  /** 국세율 */
  nationalTax: Scalars['Float'];
  /** 국세 금액 */
  nationalTaxAmount: Scalars['Int'];
  /** PG 수수료율 */
  pgCommission: Scalars['Float'];
  /** PG 수수료 금액 */
  pgCommissionAmount: Scalars['Int'];
  /** 파트너십 타입 */
  planType: Scalars['Int'];
  /** 최종 정산 금액 */
  resultAmount: Scalars['Int'];
  /** 판매금액 */
  saleAmount: Scalars['Int'];
  /** 판매금액(해외결제) */
  saleAmountForeign: Scalars['Int'];
  /** 판매수 */
  saleCount: Scalars['Int'];
  /** 정산 기준 시작일 */
  settleSince: Scalars['DateTime'];
  /** 정산 기준 종료일 */
  settleUntil: Scalars['DateTime'];
  /** 정산 대상 금액 */
  settlementAmount: Scalars['Int'];
  /** 결제 시작일 */
  since: Scalars['DateTime'];
  /** 소득세율 */
  tax: Scalars['Float'];
  /** 소득세 금액 */
  taxAmount: Scalars['Int'];
  /** 총 혜택(프로모션) 부담 금액 */
  totalBenefitBurdenAmount: Scalars['Int'];
  /** 총 쿠폰 할인 부담 금액 */
  totalCouponBurdenAmount: Scalars['Int'];
  /** 총 판매금액 */
  totalSaleAmount: Scalars['Int'];
  /** 총 부가세 금액 */
  totalVatAmount: Scalars['Int'];
  /** 결제 종료일 */
  until: Scalars['DateTime'];
  /** 부가세 금액 */
  vatAmount: Scalars['Int'];
  /** 부가세 금액(해외결제) */
  vatAmountForeign: Scalars['Int'];
  /** 년도 */
  year: Scalars['Int'];
};

export type SettlementPaginatedOutput = {
  __typename?: 'SettlementPaginatedOutput';
  data: Array<SettlementOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type ShowAssetsInput = {
  /** asset id를 배열로 */
  assetIds: Array<Scalars['Int']>;
  /** 메일 발송하지 않는 경우라면 null, 발송하는 경우 메모 내용을 그대로, 없다면 ""을 전송 */
  mailContent?: InputMaybe<Scalars['String']>;
  /** 즉시 예약인 경우 현재 일자(new Date())를 넣어주시면 됩니다 */
  showingDate: Scalars['DateTime'];
};

export type SingleDocumentBrandName = {
  __typename?: 'SingleDocumentBrandName';
  language?: Maybe<Language_Code>;
  name?: Maybe<Scalars['String']>;
};

export type SingleDocumentCategoryInfoInput = {
  categoryBranch?: InputMaybe<Array<Scalars['String']>>;
  primary?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
  tertiary?: InputMaybe<Scalars['String']>;
};

export type SingleDocumentCategoryInfoOutput = {
  __typename?: 'SingleDocumentCategoryInfoOutput';
  categoryBranch?: Maybe<Array<Scalars['String']>>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  tertiary?: Maybe<Scalars['String']>;
};

export type SingleDocumentInput = {
  applications?: InputMaybe<Array<Scalars['Int']>>;
  /** 고도몰상품번호 */
  assetId?: InputMaybe<Scalars['Int']>;
  /** 전자책 상품의 정보 */
  book?: InputMaybe<DocumentBookInput>;
  /** 브랜드 ID */
  brandId?: InputMaybe<Scalars['Int']>;
  categories?: InputMaybe<Array<DocumentCategoryInput>>;
  categoryGenre?: InputMaybe<Array<DocumentCategoryInput>>;
  contentBody?: InputMaybe<Scalars['String']>;
  contentHead?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<DocumentCopyrightInput>;
  extensions?: InputMaybe<Array<Scalars['Int']>>;
  fileComponents?: InputMaybe<Array<InputMaybe<DocumentFileComponentInput>>>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  game?: InputMaybe<Scalars['Boolean']>;
  /** 다큐먼트 ID */
  id: Scalars['String'];
  imgsrc?: InputMaybe<Scalars['Boolean']>;
  /** 산업분야 목록; id만; */
  industries?: InputMaybe<Array<DocumentIndustryInput>>;
  isAdultOnly?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  language?: InputMaybe<Language_Code>;
  lecture?: InputMaybe<Scalars['Boolean']>;
  /** 라이선스 옵션 목록 */
  licenseOptions?: InputMaybe<Array<LicenseTypeOptionInput>>;
  mainCoordination?: InputMaybe<ImageCropCoordinationInput>;
  mainImage?: InputMaybe<Scalars['String']>;
  mainOrigin?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceChanged?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Document_Status>;
  subCoordination?: InputMaybe<ImageCropCoordinationInput>;
  subImage?: InputMaybe<Scalars['String']>;
  subOrigin?: InputMaybe<Scalars['String']>;
  /** 태그 목록; id만; */
  tags?: InputMaybe<Array<DocumentTagInput>>;
  title?: InputMaybe<Scalars['String']>;
  webtoon?: InputMaybe<Scalars['Boolean']>;
};

export type SingleDocumentOutput = {
  __typename?: 'SingleDocumentOutput';
  /** 응용프로그램 목록 */
  applications: Array<Scalars['Int']>;
  /** 고도몰상품번호 */
  assetId?: Maybe<Scalars['Int']>;
  book?: Maybe<DocumentBookOutput>;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Int']>;
  brandNames?: Maybe<Array<Maybe<SingleDocumentBrandName>>>;
  /** 카테고리 정보 */
  categories: Array<CategoryOutputV4>;
  categoryGenre?: Maybe<Scalars['String']>;
  changeHistories?: Maybe<Array<Maybe<DocumentChangeHistoryOutput>>>;
  contentBody?: Maybe<Scalars['String']>;
  contentHead?: Maybe<Scalars['String']>;
  copyright?: Maybe<DocumentCopyrightOutput>;
  /** 확장자 목록 */
  extensions: Array<Scalars['Int']>;
  fileComponents?: Maybe<Array<Maybe<DocumentFileComponentOutput>>>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  game?: Maybe<Scalars['Boolean']>;
  /** 다큐먼트 ID */
  id: Scalars['String'];
  imgsrc?: Maybe<Scalars['Boolean']>;
  /** 산업분야 목록 */
  industries: Array<IndustryOutput>;
  isAdultOnly?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  language?: Maybe<Language_Code>;
  lecture?: Maybe<Scalars['Boolean']>;
  /** 라이선스 옵션 목록 */
  licenseOptions?: Maybe<Array<LicenseTypeOptionOutput>>;
  mainCoordination?: Maybe<ImageCropCoordinationOutput>;
  mainImage?: Maybe<Scalars['String']>;
  mainOrigin?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceChanged?: Maybe<Scalars['DateTime']>;
  promotion?: Maybe<OpenrunPromotionHubOutput>;
  status?: Maybe<Document_Status>;
  subCoordination?: Maybe<ImageCropCoordinationOutput>;
  subImage?: Maybe<Scalars['String']>;
  subOrigin?: Maybe<Scalars['String']>;
  /** 태그 목록 */
  tags: Array<TagOutput>;
  title?: Maybe<Scalars['String']>;
  webtoon?: Maybe<Scalars['Boolean']>;
};

export enum SnsTypeEnum {
  Google = 'google',
  Naver = 'naver',
}

export type Sort = {
  /** 정렬 방법 */
  criterion: Products_Sort_Criterion;
  /** 정렬 기준 키 */
  key: Products_Sort_Key;
};

export type StateSpecificTotal = {
  __typename?: 'StateSpecificTotal';
  status: OpenrunStatusEnum;
  total: Scalars['Int'];
};

export type StorePromotionConceptInput = {
  code: Scalars['String'];
  title: GqlRegionalInput;
};

export type StoredFileInput = {
  /** 대상파일의 위치(path+name) */
  key: Scalars['String'];
  /** 저장할 파일명 */
  name: Scalars['String'];
  size?: InputMaybe<Scalars['Float']>;
};

export type SuspendPromotionInput = {
  id: Scalars['Int'];
};

export enum Tags_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Tags_Input_Sort_Key {
  Id = 'ID',
}

export type TagOutput = {
  __typename?: 'TagOutput';
  /** 상품 태그 수량(pagination이 걸린 검색의 경우 사용하지 않아야 할 필드) */
  count: Scalars['Int'];
  /** 상품 태그 ID */
  id: Scalars['Int'];
  /** 태그 이름 */
  name: Scalars['String'];
};

export type TagPaginatedOutput = {
  __typename?: 'TagPaginatedOutput';
  data: Array<TagOutput>;
  pagination: PaginationOutput;
};

export type TagsInput_Condition = {
  /** 태그 ID 배열 */
  ids?: InputMaybe<Array<Scalars['Int']>>;
  /** 태그명; 요청 언어 기준으로 찾음; LIKE 조건임; */
  name?: InputMaybe<Scalars['String']>;
};

export type TagsInput_Sort = {
  /** 요청 정렬 방법 */
  criterion: Tags_Input_Sort_Criterion;
  /** 요청 정렬 기준 */
  key: Tags_Input_Sort_Key;
};

/** 세금계산서(B2) */
export type TaxesInput = {
  /** 에이콘 캐시 결제금액 */
  aconCashPaymentAmountText?: InputMaybe<Scalars['String']>;
  /** 에이콘 캐시 결제일 */
  aconCashPaymentDateText?: InputMaybe<Scalars['String']>;
  /** 주문번호 */
  orderNoText?: InputMaybe<Scalars['String']>;
  /** 사용자 ID */
  userId: Scalars['String'];
};

export type TempAconProduct = {
  __typename?: 'TempAconProduct';
  goodsNo: Scalars['Int'];
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type TempProductPaginatedOutput = {
  __typename?: 'TempProductPaginatedOutput';
  allCount: Scalars['Int'];
  data: Array<ProduktPublicOutput>;
  languageCode: LanguageCodeEnum;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
  sort: ProductListSort;
};

export type Term = {
  __typename?: 'Term';
  contents: Scalars['String'];
  created: Scalars['DateTime'];
  type: Scalars['String'];
};

export type Translated = {
  __typename?: 'Translated';
  lang: LanguageCodeEnum;
  text: Scalars['String'];
};

export type Type = {
  __typename?: 'Type';
  /** 혜택 유형 코드 */
  code?: Maybe<Scalars['String']>;
  /** 혜택 유형 코드 ID */
  id?: Maybe<Scalars['Float']>;
  /** 혜택 유형 코드 명 */
  name?: Maybe<Scalars['String']>;
};

export type UpdateApplicationInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateExtensionConfigInput = {
  id: Scalars['Int'];
  /** 바꾸려는 이름 */
  name: Scalars['String'];
};

export type UpdateGiftInput = {
  benefitDeposit?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  imageUrl?: InputMaybe<Scalars['String']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  price?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateInquiryAnswerInput = {
  /** Inquiry answer content to update with */
  answer: Scalars['String'];
  /** Inquiry ID */
  inquiryId: Scalars['Int'];
};

export type UpdatePartnerAcceptInput = {
  /** 승인처리관련 메시지 */
  comment?: InputMaybe<Scalars['String']>;
  /** 상태(-1, 0, 1) */
  status?: InputMaybe<AcceptStatusEnum>;
};

export type UpdatePartnerAdminInput = {
  /** 승인 요청 정보 */
  accept?: InputMaybe<UpdatePartnerAcceptInput>;
  /** 사용자 계정(이메일) */
  account?: InputMaybe<Scalars['String']>;
  /** 브랜드 정보 */
  brand?: InputMaybe<AdminUpdatePartnerBrandInput>;
  /** 회사명 */
  companyName?: InputMaybe<Scalars['String']>;
  /** 연락처 */
  contact?: InputMaybe<Scalars['String']>;
  /** 추가 연락처 */
  contactAdditional?: InputMaybe<Scalars['String']>;
  /** 국가코드 */
  country?: InputMaybe<Scalars['String']>;
  /** 사용자 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 개인식별번호(주민번호) */
  nationalIdNumber?: InputMaybe<Scalars['String']>;
  /** 파트너십 정보 */
  plan?: InputMaybe<UpdatePartnerPlanInput>;
  /** 사업자 유형 */
  subType?: InputMaybe<Scalars['String']>;
  /** 사용자 구분(개인, 회사) */
  type?: InputMaybe<PartnerTypeEnum>;
};

export type UpdatePartnerBrandInput = {
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
};

export type UpdatePartnerPlanInput = {
  commissionRate?: InputMaybe<Scalars['Float']>;
  isExclusive: Scalars['Boolean'];
  type: Scalars['Int'];
};

export type UpdatePartnerReferenceInput = {
  url: Scalars['String'];
};

export type UpdatePartnerSettleAdminFileInput = {
  bankBook?: InputMaybe<StoredFileInput>;
  idCard?: InputMaybe<StoredFileInput>;
  limitedTaxApl?: InputMaybe<StoredFileInput>;
};

export type UpdatePartnerSettleAdminInput = {
  files?: InputMaybe<UpdatePartnerSettleAdminFileInput>;
  /** 변경 항목 관련 내용 */
  memo: Scalars['String'];
  settle: UpdatePartnerSettleInput;
};

export type UpdatePartnerSettleInput = {
  /** 계좌번호 */
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  /** 예금주 */
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  bankId?: InputMaybe<Scalars['Int']>;
  /** 은행명 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 정산용 이메일 */
  settlementEmails?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdatePopupInput = {
  /** 버튼 문구 */
  buttonText?: InputMaybe<Scalars['String']>;
  /** 연결 URL */
  connectedUrl?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  exposeImmediately?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** 팝업 이미지 아이디 */
  image?: InputMaybe<PopupUploadFileInput>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<LanguageCodeEnum>;
  location?: InputMaybe<PopupLocationV2>;
  noEndDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** 하위 페이지 url */
  subLocations?: InputMaybe<Array<PopupSubLocationInput>>;
  subText?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  urls?: InputMaybe<Array<CreatePopupUrlInput>>;
};

export type UpdatePromotionConceptInput = {
  code: Scalars['String'];
  id: Scalars['Int'];
  title: GqlRegionalInput;
};

export type UpdatePromotionInput = {
  /** 프로모션 컨셉 ID */
  conceptId: Scalars['Int'];
  description: GqlRegionalInput;
  /** 할인 타입 */
  discountType: EPromotionDiscountType;
  /** 할인 금액(퍼센트인 경우는 퍼센트), decimal(10, 2) */
  discountValue: Scalars['Float'];
  /** 프로모션 ID */
  id: Scalars['Int'];
  /** 프로모션 상품이 프로모션의 대표 할인정보를 그대로 상속받아 가져가는지 여부, 생략하면 디폴트는 true */
  inheritance?: InputMaybe<Scalars['Boolean']>;
  issuer: GqlRegionalInput;
  /** 에셋별 할인정보, 에셋 전체가 프로모션의 할인정보를 상속받을 경우 id만 채워도 된다. inheritance === true로 설정해둘 것 */
  items: Array<PromotionItemInput>;
  /** 할인 시작일자 */
  since: Scalars['DateTime'];
  /** 프로모션 이름 */
  title: Scalars['String'];
  /** 할인 종료일자  */
  until: Scalars['DateTime'];
};

export type UpdateReviewInput = {
  contents: Scalars['String'];
  godoGoodsNo: Scalars['Int'];
  godoOrderNo: Scalars['String'];
  id: Scalars['Int'];
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  isSecret: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  rating: Scalars['Int'];
};

export type UploadBlobStorageInput = {
  /** 파일 이름 */
  fileName: Scalars['String'];
  /** 저장될 폴더 이름 */
  targetDirectory: Scalars['String'];
  /** 파일 경로 ex) tmp/file_name */
  temporaryFilePath: Scalars['String'];
};

export type UploadFile = {
  __typename?: 'UploadFile';
  azureStoragePath: Scalars['String'];
  creator?: Maybe<Scalars['Float']>;
  fileName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type UploadFileClone = {
  __typename?: 'UploadFileClone';
  azureStoragePath: Scalars['String'];
  creator?: Maybe<Scalars['Int']>;
  fileName: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  updater?: Maybe<Scalars['Int']>;
};

export type UrlsInput = {
  isSubPageIncluded: Scalars['Boolean'];
  url: Scalars['String'];
};

export type UrlsOutput = {
  __typename?: 'UrlsOutput';
  isSubPageIncluded: Scalars['Boolean'];
  url: Scalars['String'];
};

export type UserAccept = {
  __typename?: 'UserAccept';
  /** 최초 승인날짜 */
  accepted?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  /** 정산 정보 수정 여부 */
  isModifyPayment?: Maybe<Scalars['Boolean']>;
  /** 수정 - 승인 사유 */
  modifyApproveComment?: Maybe<Scalars['String']>;
  /** 수정 - 반려 사유 */
  modifyRejectComment?: Maybe<Scalars['String']>;
  modifyStatus: Scalars['Float'];
  modifyStatusString: Scalars['String'];
  status: Scalars['Float'];
  statusString: Scalars['String'];
  statusStringList: Array<Scalars['String']>;
};

export type UserAcceptOutput = {
  __typename?: 'UserAcceptOutput';
  /** 최초 승인날짜 */
  accepted?: Maybe<Scalars['DateTime']>;
  /** 검토 답변 */
  comment?: Maybe<Scalars['String']>;
  /** 승인요청일시 */
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  /** 정산 정보 수정 여부 */
  isModifyPayment?: Maybe<Scalars['Boolean']>;
  /** 퇴점일시 */
  leaved?: Maybe<Scalars['DateTime']>;
  /** 수정 - 승인 사유 */
  modifyApproveComment?: Maybe<Scalars['String']>;
  /** 수정 - 반려 사유 */
  modifyRejectComment?: Maybe<Scalars['String']>;
  /** 수정상태(-1:반려, 0:요청, 1:승인) */
  modifyStatus: AcceptStatusEnum;
  /** 상태(-1:반려, 0:요청, 1:승인) */
  status: AcceptStatusEnum;
};

export type UserAcceptReference = {
  __typename?: 'UserAcceptReference';
  id: Scalars['Float'];
  url: Scalars['String'];
};

export type UserAcceptReferenceOutput = {
  __typename?: 'UserAcceptReferenceOutput';
  /** ID */
  id: Scalars['Int'];
  /** 참조 url */
  url: Scalars['String'];
};

export type UserExcelDownloadHistoryOutput = {
  __typename?: 'UserExcelDownloadHistoryOutput';
  /** 다운로드 받은 문서 종류 */
  documentClass: PartnerExcelDocumentClassEnum;
  /** 다운로드 받은 날짜 */
  downloadDate: Scalars['DateTime'];
  /** 아이디 */
  id: Scalars['Int'];
  /** 다운로드 받은 유저 */
  userName: Scalars['String'];
};

export type UserExcelDownloadHistoryPaginatedOutput = {
  __typename?: 'UserExcelDownloadHistoryPaginatedOutput';
  data: Array<UserExcelDownloadHistoryOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type UserFileInfoOutput = {
  __typename?: 'UserFileInfoOutput';
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Float']>;
  /** 업로드한 사람 */
  creator?: Maybe<Scalars['Float']>;
  /** 파일 이름을 포함한 경로 */
  fullName: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 원본 파일 이름 */
  path: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  /** 주소 */
  address: Scalars['String'];
  /** 상세 주소 */
  addressSub: Scalars['String'];
  /** 사업자 - 번호 */
  cashBusiNo: Scalars['String'];
  /** 사업자 - 휴대폰 번호 */
  cashCellPhone: Scalars['String'];
  /** 핸드폰 번호 */
  cellPhone: Scalars['String'];
  /** 주문자 예치금 액수 */
  deposit: Scalars['Float'];
  /** 주문자 이메일 */
  email: Scalars['String'];
  /** 주문자 선물 예치금 액수 */
  giftDeposit: Scalars['Float'];
  /** 주문자 명 */
  name: Scalars['String'];
  /** 주문자 명 */
  orderName: Scalars['String'];
  /** 필명 */
  penName: Scalars['String'];
  /** 주문자 포인트 액수 */
  point: Scalars['Float'];
  /** 세금 계산서 - 사업장 주소 */
  taxAddress: Scalars['String'];
  /** 세금 계산서 - 사업장 상세 주소 */
  taxAddressSub: Scalars['String'];
  /** 세금 계산서 - 사업자 번호 */
  taxBusiNo: Scalars['String'];
  /** 세금 계산서 - 대표자 명 */
  taxCeoNm: Scalars['String'];
  /** 세금 계산서 - 회사 명 */
  taxCompany: Scalars['String'];
  /** 세금 계산서 - 이메일 */
  taxEmail: Scalars['String'];
  /** 세금 계산서 - 종목 */
  taxItem: Scalars['String'];
  /** 세금 계산서 - 업태 */
  taxService: Scalars['String'];
  /** 세금 계산서 - 사업장 우편 코드 */
  taxZipcode: Scalars['String'];
  /** 세금 계산서 - 사업장 지역 코드 */
  taxZonecode: Scalars['String'];
  /** 우편 코드 */
  zipCode: Scalars['String'];
  /** 지역 코드 */
  zoneCode: Scalars['String'];
};

export type UserMemoModel = {
  __typename?: 'UserMemoModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 메모 */
  memo: Scalars['String'];
  /** 메모 타입 */
  memoType: Scalars['String'];
  /** 수정 일자 */
  updated: Scalars['DateTime'];
  /** 주문 사용권 ID */
  userId: Scalars['Float'];
};

export type UserMemoOutput = {
  __typename?: 'UserMemoOutput';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']>;
  adminUser?: Maybe<AdminUser>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Int'];
  /** 메모 */
  memo: Scalars['String'];
  /** 메모 타입 */
  memoType: PartnerMemoTypeEnum;
  /** 수정 일자 */
  updated: Scalars['DateTime'];
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

export type UserOutput = {
  __typename?: 'UserOutput';
  accept: UserAcceptOutput;
  /** 아이디 */
  account: Scalars['String'];
  /** 생년월일 */
  birth?: Maybe<Scalars['DateTime']>;
  brand?: Maybe<BrandOutput>;
  brandId?: Maybe<Scalars['Int']>;
  /** 변경기록 */
  changeLog?: Maybe<Scalars['String']>;
  /** 상호 */
  companyName?: Maybe<Scalars['String']>;
  /** 연락처 */
  contact?: Maybe<Scalars['String']>;
  /** 추가 연락처 */
  contactAdditional?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  /** 첫 로그인일시 */
  firstLoginDate?: Maybe<Scalars['DateTime']>;
  group: GroupOutput;
  histories: Array<UserSettleStatusHistoryOutput>;
  id: Scalars['Int'];
  /** 성인 여부 */
  isAdult: Scalars['Boolean'];
  /** 회원가입 언어, 현재는 무조건 ko 또는 en */
  joinLanguage: Scalars['String'];
  /** 마지막 로그인일시 */
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  memos: Array<UserMemoOutput>;
  /** 이름 */
  name: Scalars['String'];
  plan?: Maybe<UserPlanOutput>;
  reference: Array<UserAcceptReferenceOutput>;
  settle?: Maybe<UserSettleOutput>;
  /** sns 주소 */
  snsUrl?: Maybe<Scalars['DateTime']>;
  /** 사업자 구분 */
  subType?: Maybe<Scalars['String']>;
  /** 사업자 유형(human readable) */
  subTypeString?: Maybe<Scalars['String']>;
  subTypeStringList: Array<Scalars['String']>;
  termAgree: Array<UserTermAgreeOutput>;
  /** 0:일반/1:사업자 */
  type?: Maybe<PartnerTypeEnum>;
  /** 회원 유형(human readable) */
  typeString?: Maybe<Scalars['String']>;
  typeStringList: Array<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type UserPaginatedOutput = {
  __typename?: 'UserPaginatedOutput';
  data: Array<UserOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type UserPlan = {
  __typename?: 'UserPlan';
  commissionRate?: Maybe<Scalars['Float']>;
  isExclusive: Scalars['Boolean'];
  type: Scalars['Float'];
  userId: Scalars['Float'];
};

export type UserPlanOutput = {
  __typename?: 'UserPlanOutput';
  /** 수수료율 */
  commissionRate: Scalars['Int'];
  /** 독점 여부 */
  isExclusive: Scalars['Boolean'];
  /** 플랜 타입 */
  type: Scalars['Int'];
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

export type UserSettle = {
  __typename?: 'UserSettle';
  /** 승인일자 */
  approveDate?: Maybe<Scalars['DateTime']>;
  bank?: Maybe<Bank>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountOwner?: Maybe<Scalars['String']>;
  bankBook?: Maybe<UploadFile>;
  bankId: Scalars['Float'];
  bankName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  idCard?: Maybe<UploadFile>;
  imageSign: Scalars['Float'];
  limitedTaxApl?: Maybe<UploadFile>;
  nationalIdNumber?: Maybe<Scalars['String']>;
  /** 반려일자 */
  rejectDate?: Maybe<Scalars['DateTime']>;
  /** 요청일자 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 정산용 이메일 */
  settlementEmails: Array<Scalars['String']>;
  sign?: Maybe<UploadFile>;
  signLang?: Maybe<Scalars['String']>;
  /** 상태 */
  status: UserSettleStatusEnum;
};

export type UserSettleOutput = {
  __typename?: 'UserSettleOutput';
  /** 승인일자 */
  approveDate?: Maybe<Scalars['DateTime']>;
  bank?: Maybe<BankOutput>;
  /** 계좌번호 */
  bankAccountNumber?: Maybe<Scalars['String']>;
  /** 예금주 */
  bankAccountOwner?: Maybe<Scalars['String']>;
  bankBook?: Maybe<FileOutput>;
  /** 은행 구분 번호 */
  bankId: Scalars['Int'];
  /** 특별한 은행명 */
  bankName?: Maybe<Scalars['String']>;
  /** 국가코드 */
  country?: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['Int'];
  idCard?: Maybe<FileOutput>;
  /** 통장 파일 아이디 */
  imageBankBook?: Maybe<Scalars['Int']>;
  /** 신분증 파일 아이디 */
  imageIdCard?: Maybe<Scalars['Int']>;
  /** 제한 세율 적용 신청서 파일 아이디 */
  imageLimitedTaxApl?: Maybe<Scalars['Int']>;
  /** 서명 파일 아이디 */
  imageSign?: Maybe<Scalars['Int']>;
  limitedTaxApl?: Maybe<FileOutput>;
  /** 개인식별번호(like. 주민번호) */
  nationalIdNumber?: Maybe<Scalars['String']>;
  /** 반려일자 */
  rejectDate?: Maybe<Scalars['DateTime']>;
  /** 요청일자 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 정산용 이메일 */
  settlementEmails: Array<Scalars['String']>;
  sign?: Maybe<FileOutput>;
  /** 가입 언어 */
  signLang?: Maybe<Scalars['String']>;
  /** 상태 */
  status: PartnerSettleStatusEnum;
  user: UserOutput;
};

export type UserSettlePaginatedOutput = {
  __typename?: 'UserSettlePaginatedOutput';
  data: Array<UserSettleOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export enum UserSettleStatusEnum {
  Approve = 'approve',
  None = 'none',
  Reject = 'reject',
  Request = 'request',
  Rerequest = 'rerequest',
}

export type UserSettleStatusHistoryModel = {
  __typename?: 'UserSettleStatusHistoryModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 상태 */
  status: UserSettleStatusEnum;
  /** 업데이트 일자 */
  updated: Scalars['DateTime'];
  /** 사용자 ID */
  userId: Scalars['Float'];
};

export type UserSettleStatusHistoryOutput = {
  __typename?: 'UserSettleStatusHistoryOutput';
  /** 관리자 아이디 */
  adminId?: Maybe<Scalars['String']>;
  adminUser?: Maybe<AdminUser>;
  /** 생성일시 */
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 상태값 */
  status: PartnerSettleStatusEnum;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

export type UserTermAgree = {
  __typename?: 'UserTermAgree';
  agreeAt: Scalars['DateTime'];
  file?: Maybe<UploadFile>;
  id: Scalars['Float'];
  uploadFileId?: Maybe<Scalars['Float']>;
};

export type UserTermAgreeOutput = {
  __typename?: 'UserTermAgreeOutput';
  /** 동의 일시 */
  agreeAt: Scalars['DateTime'];
  file?: Maybe<FileOutput>;
  id: Scalars['Int'];
  /** 계약서 파일 아이디 */
  uploadFileId?: Maybe<Scalars['Int']>;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

export type VBank = {
  __typename?: 'VBank';
  /** 예금주 명 */
  bankHolder?: Maybe<Scalars['String']>;
  /** 입금 은행 명 */
  bankName?: Maybe<Scalars['String']>;
  /** 가상계좌 번호 */
  bankNumber?: Maybe<Scalars['String']>;
  /** 송금 기한 */
  remittanceDate?: Maybe<Scalars['DateTime']>;
};

export const BanksDocument = gql`
  query Banks {
    banks {
      id
      name
      __typename
    }
  }
`;

/**
 * __useBanksQuery__
 *
 * To run a query within a React component, call `useBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useBanksQuery(baseOptions?: Apollo.QueryHookOptions<BanksQuery, BanksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BanksQuery, BanksQueryVariables>(BanksDocument, options);
}
export function useBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BanksQuery, BanksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BanksQuery, BanksQueryVariables>(BanksDocument, options);
}
export type BanksQueryHookResult = ReturnType<typeof useBanksQuery>;
export type BanksLazyQueryHookResult = ReturnType<typeof useBanksLazyQuery>;
export type BanksQueryResult = Apollo.QueryResult<BanksQuery, BanksQueryVariables>;
export const BrandAssetsDocument = gql`
  query BrandAssets($title: String, $brandName: String) {
    brandAssets(title: $title, brandName: $brandName) {
      id
      i18ns {
        title
        language
      }
    }
  }
`;

/**
 * __useBrandAssetsQuery__
 *
 * To run a query within a React component, call `useBrandAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandAssetsQuery({
 *   variables: {
 *      title: // value for 'title'
 *      brandName: // value for 'brandName'
 *   },
 * });
 */
export function useBrandAssetsQuery(baseOptions?: Apollo.QueryHookOptions<BrandAssetsQuery, BrandAssetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandAssetsQuery, BrandAssetsQueryVariables>(BrandAssetsDocument, options);
}
export function useBrandAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandAssetsQuery, BrandAssetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandAssetsQuery, BrandAssetsQueryVariables>(BrandAssetsDocument, options);
}
export type BrandAssetsQueryHookResult = ReturnType<typeof useBrandAssetsQuery>;
export type BrandAssetsLazyQueryHookResult = ReturnType<typeof useBrandAssetsLazyQuery>;
export type BrandAssetsQueryResult = Apollo.QueryResult<BrandAssetsQuery, BrandAssetsQueryVariables>;
export const BrandPickDocument = gql`
  query BrandPick {
    brandPick {
      isOn
      assets {
        id
        brand {
          id
          name
          godoBrandCd
        }
        i18ns {
          title
          language
        }
      }
    }
  }
`;

/**
 * __useBrandPickQuery__
 *
 * To run a query within a React component, call `useBrandPickQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandPickQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandPickQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandPickQuery(baseOptions?: Apollo.QueryHookOptions<BrandPickQuery, BrandPickQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandPickQuery, BrandPickQueryVariables>(BrandPickDocument, options);
}
export function useBrandPickLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandPickQuery, BrandPickQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandPickQuery, BrandPickQueryVariables>(BrandPickDocument, options);
}
export type BrandPickQueryHookResult = ReturnType<typeof useBrandPickQuery>;
export type BrandPickLazyQueryHookResult = ReturnType<typeof useBrandPickLazyQuery>;
export type BrandPickQueryResult = Apollo.QueryResult<BrandPickQuery, BrandPickQueryVariables>;
export const BrandPromotionDocument = gql`
  query BrandPromotion($brandPromotionId: Int!, $isMatureContent: Boolean) {
    brandPromotion(id: $brandPromotionId) {
      id
      promotionId
      createdAt
      canceledAt
      items {
        asset(isMatureContent: $isMatureContent) {
          id
          title
          price
        }
        discountValue
        discountType
      }
      promotion {
        since
        until
        title
        status
      }
    }
  }
`;

/**
 * __useBrandPromotionQuery__
 *
 * To run a query within a React component, call `useBrandPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandPromotionQuery({
 *   variables: {
 *      brandPromotionId: // value for 'brandPromotionId'
 *      isMatureContent: // value for 'isMatureContent'
 *   },
 * });
 */
export function useBrandPromotionQuery(baseOptions: Apollo.QueryHookOptions<BrandPromotionQuery, BrandPromotionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandPromotionQuery, BrandPromotionQueryVariables>(BrandPromotionDocument, options);
}
export function useBrandPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandPromotionQuery, BrandPromotionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandPromotionQuery, BrandPromotionQueryVariables>(BrandPromotionDocument, options);
}
export type BrandPromotionQueryHookResult = ReturnType<typeof useBrandPromotionQuery>;
export type BrandPromotionLazyQueryHookResult = ReturnType<typeof useBrandPromotionLazyQuery>;
export type BrandPromotionQueryResult = Apollo.QueryResult<BrandPromotionQuery, BrandPromotionQueryVariables>;
export const BrandPromotionsDocument = gql`
  query BrandPromotions($search: PaginatedBrandPromotionArgsSearch, $page: Int, $limit: Int) {
    brandPromotions(search: $search, page: $page, limit: $limit) {
      data {
        id
        promotionId
        createdAt
        canceledAt
        items {
          id
          since
          until
          registeredAt
          suspendedAt
          asset {
            id
            brand {
              name
              id
            }
            title
            price
            salePrice
          }
        }
        promotion {
          status
          until
          since
          items {
            id
          }
          id
        }
      }
      pagination {
        total
      }
    }
  }
`;

/**
 * __useBrandPromotionsQuery__
 *
 * To run a query within a React component, call `useBrandPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandPromotionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBrandPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<BrandPromotionsQuery, BrandPromotionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandPromotionsQuery, BrandPromotionsQueryVariables>(BrandPromotionsDocument, options);
}
export function useBrandPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandPromotionsQuery, BrandPromotionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandPromotionsQuery, BrandPromotionsQueryVariables>(BrandPromotionsDocument, options);
}
export type BrandPromotionsQueryHookResult = ReturnType<typeof useBrandPromotionsQuery>;
export type BrandPromotionsLazyQueryHookResult = ReturnType<typeof useBrandPromotionsLazyQuery>;
export type BrandPromotionsQueryResult = Apollo.QueryResult<BrandPromotionsQuery, BrandPromotionsQueryVariables>;
export const BrandReviewsDocument = gql`
  query BrandReviews($brandId: Int!, $limit: Int, $offset: Int) {
    brandReviews(brandId: $brandId, limit: $limit, offset: $offset) {
      count
    }
  }
`;

/**
 * __useBrandReviewsQuery__
 *
 * To run a query within a React component, call `useBrandReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandReviewsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useBrandReviewsQuery(baseOptions: Apollo.QueryHookOptions<BrandReviewsQuery, BrandReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandReviewsQuery, BrandReviewsQueryVariables>(BrandReviewsDocument, options);
}
export function useBrandReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandReviewsQuery, BrandReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandReviewsQuery, BrandReviewsQueryVariables>(BrandReviewsDocument, options);
}
export type BrandReviewsQueryHookResult = ReturnType<typeof useBrandReviewsQuery>;
export type BrandReviewsLazyQueryHookResult = ReturnType<typeof useBrandReviewsLazyQuery>;
export type BrandReviewsQueryResult = Apollo.QueryResult<BrandReviewsQuery, BrandReviewsQueryVariables>;
export const CancelBrandPromotionDocument = gql`
  mutation CancelBrandPromotion($input: CancelBrandPromotionInput!) {
    cancelBrandPromotion(input: $input)
  }
`;
export type CancelBrandPromotionMutationFn = Apollo.MutationFunction<CancelBrandPromotionMutation, CancelBrandPromotionMutationVariables>;

/**
 * __useCancelBrandPromotionMutation__
 *
 * To run a mutation, you first call `useCancelBrandPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBrandPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBrandPromotionMutation, { data, loading, error }] = useCancelBrandPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelBrandPromotionMutation(baseOptions?: Apollo.MutationHookOptions<CancelBrandPromotionMutation, CancelBrandPromotionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelBrandPromotionMutation, CancelBrandPromotionMutationVariables>(CancelBrandPromotionDocument, options);
}
export type CancelBrandPromotionMutationHookResult = ReturnType<typeof useCancelBrandPromotionMutation>;
export type CancelBrandPromotionMutationResult = Apollo.MutationResult<CancelBrandPromotionMutation>;
export type CancelBrandPromotionMutationOptions = Apollo.BaseMutationOptions<CancelBrandPromotionMutation, CancelBrandPromotionMutationVariables>;
export const CategoriesV2Document = gql`
  query CategoriesV2($language: LANGUAGE_CODE!, $condition: CategoriesInput_Condition!, $sort: CategoriesInput_Sort!) {
    categoriesV2(language: $language, condition: $condition, sort: $sort) {
      id
      code
      name
      children {
        id
        code
        name
        children {
          code
          id
          name
        }
      }
    }
  }
`;

/**
 * __useCategoriesV2Query__
 *
 * To run a query within a React component, call `useCategoriesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesV2Query({
 *   variables: {
 *      language: // value for 'language'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCategoriesV2Query(baseOptions: Apollo.QueryHookOptions<CategoriesV2Query, CategoriesV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoriesV2Query, CategoriesV2QueryVariables>(CategoriesV2Document, options);
}
export function useCategoriesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesV2Query, CategoriesV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoriesV2Query, CategoriesV2QueryVariables>(CategoriesV2Document, options);
}
export type CategoriesV2QueryHookResult = ReturnType<typeof useCategoriesV2Query>;
export type CategoriesV2LazyQueryHookResult = ReturnType<typeof useCategoriesV2LazyQuery>;
export type CategoriesV2QueryResult = Apollo.QueryResult<CategoriesV2Query, CategoriesV2QueryVariables>;
export const ChangePasswordPartnerDocument = gql`
  mutation ChangePasswordPartner($newPassword: String!, $oldPassword: String!) {
    changePasswordPartner(newPassword: $newPassword, oldPassword: $oldPassword) {
      id
    }
  }
`;
export type ChangePasswordPartnerMutationFn = Apollo.MutationFunction<ChangePasswordPartnerMutation, ChangePasswordPartnerMutationVariables>;

/**
 * __useChangePasswordPartnerMutation__
 *
 * To run a mutation, you first call `useChangePasswordPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordPartnerMutation, { data, loading, error }] = useChangePasswordPartnerMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordPartnerMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordPartnerMutation, ChangePasswordPartnerMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordPartnerMutation, ChangePasswordPartnerMutationVariables>(ChangePasswordPartnerDocument, options);
}
export type ChangePasswordPartnerMutationHookResult = ReturnType<typeof useChangePasswordPartnerMutation>;
export type ChangePasswordPartnerMutationResult = Apollo.MutationResult<ChangePasswordPartnerMutation>;
export type ChangePasswordPartnerMutationOptions = Apollo.BaseMutationOptions<ChangePasswordPartnerMutation, ChangePasswordPartnerMutationVariables>;
export const CreateAdminPartnerDocument = gql`
  mutation CreateAdminPartner($password: String!, $account: String!, $name: String!) {
    createAdminPartner(password: $password, account: $account, name: $name)
  }
`;
export type CreateAdminPartnerMutationFn = Apollo.MutationFunction<CreateAdminPartnerMutation, CreateAdminPartnerMutationVariables>;

/**
 * __useCreateAdminPartnerMutation__
 *
 * To run a mutation, you first call `useCreateAdminPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminPartnerMutation, { data, loading, error }] = useCreateAdminPartnerMutation({
 *   variables: {
 *      password: // value for 'password'
 *      account: // value for 'account'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateAdminPartnerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminPartnerMutation, CreateAdminPartnerMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAdminPartnerMutation, CreateAdminPartnerMutationVariables>(CreateAdminPartnerDocument, options);
}
export type CreateAdminPartnerMutationHookResult = ReturnType<typeof useCreateAdminPartnerMutation>;
export type CreateAdminPartnerMutationResult = Apollo.MutationResult<CreateAdminPartnerMutation>;
export type CreateAdminPartnerMutationOptions = Apollo.BaseMutationOptions<CreateAdminPartnerMutation, CreateAdminPartnerMutationVariables>;
export const CreatePartnerDocument = gql`
  mutation CreatePartner($file: CreatePartnerSettleFileInput!, $brand: CreatePartnerBrandInput!, $user: CreatePartnerInput!) {
    createPartner(file: $file, brand: $brand, user: $user) {
      id
    }
  }
`;
export type CreatePartnerMutationFn = Apollo.MutationFunction<CreatePartnerMutation, CreatePartnerMutationVariables>;

/**
 * __useCreatePartnerMutation__
 *
 * To run a mutation, you first call `useCreatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerMutation, { data, loading, error }] = useCreatePartnerMutation({
 *   variables: {
 *      file: // value for 'file'
 *      brand: // value for 'brand'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreatePartnerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartnerMutation, CreatePartnerMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartnerMutation, CreatePartnerMutationVariables>(CreatePartnerDocument, options);
}
export type CreatePartnerMutationHookResult = ReturnType<typeof useCreatePartnerMutation>;
export type CreatePartnerMutationResult = Apollo.MutationResult<CreatePartnerMutation>;
export type CreatePartnerMutationOptions = Apollo.BaseMutationOptions<CreatePartnerMutation, CreatePartnerMutationVariables>;
export const CreatePartnerPlanDocument = gql`
  query CreatePartnerPlan($isExclusive: Boolean!, $type: Float!) {
    createPartnerPlan(isExclusive: $isExclusive, type: $type)
  }
`;

/**
 * __useCreatePartnerPlanQuery__
 *
 * To run a query within a React component, call `useCreatePartnerPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatePartnerPlanQuery({
 *   variables: {
 *      isExclusive: // value for 'isExclusive'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreatePartnerPlanQuery(baseOptions: Apollo.QueryHookOptions<CreatePartnerPlanQuery, CreatePartnerPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreatePartnerPlanQuery, CreatePartnerPlanQueryVariables>(CreatePartnerPlanDocument, options);
}
export function useCreatePartnerPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatePartnerPlanQuery, CreatePartnerPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreatePartnerPlanQuery, CreatePartnerPlanQueryVariables>(CreatePartnerPlanDocument, options);
}
export type CreatePartnerPlanQueryHookResult = ReturnType<typeof useCreatePartnerPlanQuery>;
export type CreatePartnerPlanLazyQueryHookResult = ReturnType<typeof useCreatePartnerPlanLazyQuery>;
export type CreatePartnerPlanQueryResult = Apollo.QueryResult<CreatePartnerPlanQuery, CreatePartnerPlanQueryVariables>;
export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($documentId: String!) {
    deleteDocument(document_id: $documentId)
  }
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const DeleteOpenrunPromotionDocument = gql`
  mutation DeleteOpenrunPromotion($input: DeleteOpenrunPromotionInput!) {
    deleteOpenrunPromotion(input: $input)
  }
`;
export type DeleteOpenrunPromotionMutationFn = Apollo.MutationFunction<DeleteOpenrunPromotionMutation, DeleteOpenrunPromotionMutationVariables>;

/**
 * __useDeleteOpenrunPromotionMutation__
 *
 * To run a mutation, you first call `useDeleteOpenrunPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpenrunPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpenrunPromotionMutation, { data, loading, error }] = useDeleteOpenrunPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOpenrunPromotionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOpenrunPromotionMutation, DeleteOpenrunPromotionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOpenrunPromotionMutation, DeleteOpenrunPromotionMutationVariables>(DeleteOpenrunPromotionDocument, options);
}
export type DeleteOpenrunPromotionMutationHookResult = ReturnType<typeof useDeleteOpenrunPromotionMutation>;
export type DeleteOpenrunPromotionMutationResult = Apollo.MutationResult<DeleteOpenrunPromotionMutation>;
export type DeleteOpenrunPromotionMutationOptions = Apollo.BaseMutationOptions<DeleteOpenrunPromotionMutation, DeleteOpenrunPromotionMutationVariables>;
export const DescendantsOfRegularCategoryDocument = gql`
  query DescendantsOfRegularCategory($descendantsOfRegularCategoryInput: DescendantsOfRegularCategoryInput!) {
    descendantsOfRegularCategory(descendantsOfRegularCategoryInput: $descendantsOfRegularCategoryInput) {
      id
      code
      name
      parent
    }
  }
`;

/**
 * __useDescendantsOfRegularCategoryQuery__
 *
 * To run a query within a React component, call `useDescendantsOfRegularCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDescendantsOfRegularCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDescendantsOfRegularCategoryQuery({
 *   variables: {
 *      descendantsOfRegularCategoryInput: // value for 'descendantsOfRegularCategoryInput'
 *   },
 * });
 */
export function useDescendantsOfRegularCategoryQuery(baseOptions: Apollo.QueryHookOptions<DescendantsOfRegularCategoryQuery, DescendantsOfRegularCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DescendantsOfRegularCategoryQuery, DescendantsOfRegularCategoryQueryVariables>(DescendantsOfRegularCategoryDocument, options);
}
export function useDescendantsOfRegularCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DescendantsOfRegularCategoryQuery, DescendantsOfRegularCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DescendantsOfRegularCategoryQuery, DescendantsOfRegularCategoryQueryVariables>(DescendantsOfRegularCategoryDocument, options);
}
export type DescendantsOfRegularCategoryQueryHookResult = ReturnType<typeof useDescendantsOfRegularCategoryQuery>;
export type DescendantsOfRegularCategoryLazyQueryHookResult = ReturnType<typeof useDescendantsOfRegularCategoryLazyQuery>;
export type DescendantsOfRegularCategoryQueryResult = Apollo.QueryResult<DescendantsOfRegularCategoryQuery, DescendantsOfRegularCategoryQueryVariables>;
export const DocumentDocument = gql`
  query Document($documentId: String!) {
    document(id: $documentId) {
      id
      assetId
      brandId
      language
      status
      title
      contentHead
      contentBody
      price
      mainCoordination {
        x
        y
        width
        height
      }
      mainImage
      mainOrigin
      subCoordination {
        x
        y
        width
        height
      }
      subImage
      subOrigin
      fileName
      filePath
      categoryGenre
      categories {
        code
        children {
          code
        }
      }
      copyright {
        isOriginal
        warehouseSources
        commercialSources
        hasRealLogo
      }
      priceChanged
      isAdultOnly
      message
      promotion {
        id
      }
      licenseOptions {
        licenseTypeId
        price
      }
      tags {
        id
        name
      }
      industries {
        id
        name
      }
      changeHistories {
        message
        status
      }
      extensions
      fileComponents {
        name
        size
      }
      applications
      book {
        isbn
      }
    }
  }
`;

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions: Apollo.QueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
}
export function useDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
}
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export type DocumentQueryResult = Apollo.QueryResult<DocumentQuery, DocumentQueryVariables>;
export const DocumentIndustriesDocument = gql`
  query DocumentIndustries($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $condition: IndustriesInput_Condition!, $sort: IndustriesInput_Sort!) {
    industries(language: $language, pagination: $pagination, condition: $condition, sort: $sort) {
      data {
        id
        name
      }
    }
  }
`;

/**
 * __useDocumentIndustriesQuery__
 *
 * To run a query within a React component, call `useDocumentIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentIndustriesQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDocumentIndustriesQuery(baseOptions: Apollo.QueryHookOptions<DocumentIndustriesQuery, DocumentIndustriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocumentIndustriesQuery, DocumentIndustriesQueryVariables>(DocumentIndustriesDocument, options);
}
export function useDocumentIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentIndustriesQuery, DocumentIndustriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocumentIndustriesQuery, DocumentIndustriesQueryVariables>(DocumentIndustriesDocument, options);
}
export type DocumentIndustriesQueryHookResult = ReturnType<typeof useDocumentIndustriesQuery>;
export type DocumentIndustriesLazyQueryHookResult = ReturnType<typeof useDocumentIndustriesLazyQuery>;
export type DocumentIndustriesQueryResult = Apollo.QueryResult<DocumentIndustriesQuery, DocumentIndustriesQueryVariables>;
export const RequestDocumentReviewDocument = gql`
  mutation RequestDocumentReview($input: DocumentCommonInput!) {
    request(input: $input)
  }
`;
export type RequestDocumentReviewMutationFn = Apollo.MutationFunction<RequestDocumentReviewMutation, RequestDocumentReviewMutationVariables>;

/**
 * __useRequestDocumentReviewMutation__
 *
 * To run a mutation, you first call `useRequestDocumentReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDocumentReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDocumentReviewMutation, { data, loading, error }] = useRequestDocumentReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestDocumentReviewMutation(baseOptions?: Apollo.MutationHookOptions<RequestDocumentReviewMutation, RequestDocumentReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestDocumentReviewMutation, RequestDocumentReviewMutationVariables>(RequestDocumentReviewDocument, options);
}
export type RequestDocumentReviewMutationHookResult = ReturnType<typeof useRequestDocumentReviewMutation>;
export type RequestDocumentReviewMutationResult = Apollo.MutationResult<RequestDocumentReviewMutation>;
export type RequestDocumentReviewMutationOptions = Apollo.BaseMutationOptions<RequestDocumentReviewMutation, RequestDocumentReviewMutationVariables>;
export const ModifyDocumentDocument = gql`
  mutation ModifyDocument($input: DocumentCommonInput!) {
    modify(input: $input)
  }
`;
export type ModifyDocumentMutationFn = Apollo.MutationFunction<ModifyDocumentMutation, ModifyDocumentMutationVariables>;

/**
 * __useModifyDocumentMutation__
 *
 * To run a mutation, you first call `useModifyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyDocumentMutation, { data, loading, error }] = useModifyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ModifyDocumentMutation, ModifyDocumentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyDocumentMutation, ModifyDocumentMutationVariables>(ModifyDocumentDocument, options);
}
export type ModifyDocumentMutationHookResult = ReturnType<typeof useModifyDocumentMutation>;
export type ModifyDocumentMutationResult = Apollo.MutationResult<ModifyDocumentMutation>;
export type ModifyDocumentMutationOptions = Apollo.BaseMutationOptions<ModifyDocumentMutation, ModifyDocumentMutationVariables>;
export const AbortModifyDocumentDocument = gql`
  mutation AbortModifyDocument($input: DocumentCommonInput!) {
    abort(input: $input)
  }
`;
export type AbortModifyDocumentMutationFn = Apollo.MutationFunction<AbortModifyDocumentMutation, AbortModifyDocumentMutationVariables>;

/**
 * __useAbortModifyDocumentMutation__
 *
 * To run a mutation, you first call `useAbortModifyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortModifyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortModifyDocumentMutation, { data, loading, error }] = useAbortModifyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAbortModifyDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AbortModifyDocumentMutation, AbortModifyDocumentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AbortModifyDocumentMutation, AbortModifyDocumentMutationVariables>(AbortModifyDocumentDocument, options);
}
export type AbortModifyDocumentMutationHookResult = ReturnType<typeof useAbortModifyDocumentMutation>;
export type AbortModifyDocumentMutationResult = Apollo.MutationResult<AbortModifyDocumentMutation>;
export type AbortModifyDocumentMutationOptions = Apollo.BaseMutationOptions<AbortModifyDocumentMutation, AbortModifyDocumentMutationVariables>;
export const DocumentTagsDocument = gql`
  query DocumentTags($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $condition: TagsInput_Condition!, $sort: TagsInput_Sort!) {
    tags(language: $language, pagination: $pagination, condition: $condition, sort: $sort) {
      data {
        id
        name
      }
    }
  }
`;

/**
 * __useDocumentTagsQuery__
 *
 * To run a query within a React component, call `useDocumentTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTagsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDocumentTagsQuery(baseOptions: Apollo.QueryHookOptions<DocumentTagsQuery, DocumentTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocumentTagsQuery, DocumentTagsQueryVariables>(DocumentTagsDocument, options);
}
export function useDocumentTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentTagsQuery, DocumentTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocumentTagsQuery, DocumentTagsQueryVariables>(DocumentTagsDocument, options);
}
export type DocumentTagsQueryHookResult = ReturnType<typeof useDocumentTagsQuery>;
export type DocumentTagsLazyQueryHookResult = ReturnType<typeof useDocumentTagsLazyQuery>;
export type DocumentTagsQueryResult = Apollo.QueryResult<DocumentTagsQuery, DocumentTagsQueryVariables>;
export const DocumentsDocument = gql`
  query Documents(
    $title: String
    $content: String
    $assetId: Int
    $createdInterval: [DateTime]
    $requestedInterval: [DateTime]
    $status: [PARTNER_DOCUMENT_STATUS]
    $offset: Int
    $limit: Int
    $brandIds: [Int]
  ) {
    documents(
      title: $title
      content: $content
      assetId: $assetId
      createdInterval: $createdInterval
      requestedInterval: $requestedInterval
      status: $status
      offset: $offset
      limit: $limit
      brandIds: $brandIds
    ) {
      id
      mainImage
      language
      title
      status
      created
      requested
      assetId
      total
      isAdultOnly
    }
  }
`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      assetId: // value for 'assetId'
 *      createdInterval: // value for 'createdInterval'
 *      requestedInterval: // value for 'requestedInterval'
 *      status: // value for 'status'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      brandIds: // value for 'brandIds'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
}
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
}
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const FetchPromotionDocument = gql`
  query FetchPromotion($fetchPromotionId: Int!) {
    fetchPromotion(id: $fetchPromotionId) {
      availability {
        available
        current
        total
      }
      since
      until
    }
  }
`;

/**
 * __useFetchPromotionQuery__
 *
 * To run a query within a React component, call `useFetchPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPromotionQuery({
 *   variables: {
 *      fetchPromotionId: // value for 'fetchPromotionId'
 *   },
 * });
 */
export function useFetchPromotionQuery(baseOptions: Apollo.QueryHookOptions<FetchPromotionQuery, FetchPromotionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchPromotionQuery, FetchPromotionQueryVariables>(FetchPromotionDocument, options);
}
export function useFetchPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPromotionQuery, FetchPromotionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchPromotionQuery, FetchPromotionQueryVariables>(FetchPromotionDocument, options);
}
export type FetchPromotionQueryHookResult = ReturnType<typeof useFetchPromotionQuery>;
export type FetchPromotionLazyQueryHookResult = ReturnType<typeof useFetchPromotionLazyQuery>;
export type FetchPromotionQueryResult = Apollo.QueryResult<FetchPromotionQuery, FetchPromotionQueryVariables>;
export const FetchPromotionsDocument = gql`
  query FetchPromotions($search: FetchPromotionsSearchInput, $page: Int, $limit: Int) {
    fetchPromotions(search: $search, page: $page, limit: $limit) {
      pagination {
        page
        limit
        total
      }
      data {
        id
        title
        since
        until
        status
        availability {
          total
          current
          available
        }
      }
    }
  }
`;

/**
 * __useFetchPromotionsQuery__
 *
 * To run a query within a React component, call `useFetchPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPromotionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFetchPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<FetchPromotionsQuery, FetchPromotionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchPromotionsQuery, FetchPromotionsQueryVariables>(FetchPromotionsDocument, options);
}
export function useFetchPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPromotionsQuery, FetchPromotionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchPromotionsQuery, FetchPromotionsQueryVariables>(FetchPromotionsDocument, options);
}
export type FetchPromotionsQueryHookResult = ReturnType<typeof useFetchPromotionsQuery>;
export type FetchPromotionsLazyQueryHookResult = ReturnType<typeof useFetchPromotionsLazyQuery>;
export type FetchPromotionsQueryResult = Apollo.QueryResult<FetchPromotionsQuery, FetchPromotionsQueryVariables>;
export const GetAdminDocument = gql`
  query GetAdmin {
    me {
      account
      name
      type
      typeString
    }
  }
`;

/**
 * __useGetAdminQuery__
 *
 * To run a query within a React component, call `useGetAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminQuery, GetAdminQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminQuery, GetAdminQueryVariables>(GetAdminDocument, options);
}
export function useGetAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminQuery, GetAdminQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminQuery, GetAdminQueryVariables>(GetAdminDocument, options);
}
export type GetAdminQueryHookResult = ReturnType<typeof useGetAdminQuery>;
export type GetAdminLazyQueryHookResult = ReturnType<typeof useGetAdminLazyQuery>;
export type GetAdminQueryResult = Apollo.QueryResult<GetAdminQuery, GetAdminQueryVariables>;
export const GetAllTermsDocument = gql`
  query GetAllTerms {
    getTermAll {
      type
      contents
    }
  }
`;

/**
 * __useGetAllTermsQuery__
 *
 * To run a query within a React component, call `useGetAllTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTermsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTermsQuery, GetAllTermsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTermsQuery, GetAllTermsQueryVariables>(GetAllTermsDocument, options);
}
export function useGetAllTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTermsQuery, GetAllTermsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTermsQuery, GetAllTermsQueryVariables>(GetAllTermsDocument, options);
}
export type GetAllTermsQueryHookResult = ReturnType<typeof useGetAllTermsQuery>;
export type GetAllTermsLazyQueryHookResult = ReturnType<typeof useGetAllTermsLazyQuery>;
export type GetAllTermsQueryResult = Apollo.QueryResult<GetAllTermsQuery, GetAllTermsQueryVariables>;
export const GetBrandOrderProductItemsDocument = gql`
  query GetBrandOrderProductItems($pagination: PaginationInput!, $condition: GetBrandOrderProductItemsInput, $lang: LanguageCodeEnum!) {
    getBrandOrderProductItems(pagination: $pagination, condition: $condition) {
      data {
        id
        godoGoodsNo
        isRefunded
        refundedAt
        price
        salePrice
        goodsDcPrice
        couponGoodsDcPrice
        divisionCouponOrderDcPrice
        couponGoodsBurden
        couponOrderBurden
        benefitBurden
        benefitBurdenPrice
        couponGoodsBurdenPrice
        couponOrderBurdenPrice
        totalBurdenPrice
        isDownloaded
        isCanceled
        product(lang: $lang) {
          id
          title
          isAdultOnly
          priceInfo {
            price
            salePrice
          }
          imageInfo {
            main {
              url
            }
            sub {
              url
            }
          }
          dateInfo {
            releasedAt
          }
          promotionInfo {
            since
            until
          }
          brandInfo {
            id
            code
            godoBrandCd
            name
            isAcon3DExclusive
            update {
              type
              value
            }
            productCount
            vipInfo
            photoInfo {
              id
              path
              fileName
              size
            }
            i18ns {
              brandId
              languageCode
              name
              introduction
            }
          }
          tags {
            id
            name
            count
          }
          extensions {
            id
            name
            count
          }
          applications {
            id
            name
          }
        }
        order {
          id
          memNo
          memId
          langCode
          status
          email
          name
          cellPhone
          amount
          usePoint
          useAconCash
          useGiftDeposit
          godoOrderId
          createdAt
          country
          license {
            id
            orderNo
            type
            created
            authors {
              id
              orderLicenseId
              name
            }
            project {
              id
              orderLicenseId
              name
              goodsNos
            }
          }
          histories {
            id
            orderId
            godoGoodsNo
            event
            createdAt
          }
        }
      }
      pagination {
        totalCount
        currentPage
        perPage
        lastPage
      }
      aggregation {
        amount
        refundedCount
      }
    }
  }
`;

/**
 * __useGetBrandOrderProductItemsQuery__
 *
 * To run a query within a React component, call `useGetBrandOrderProductItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandOrderProductItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandOrderProductItemsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetBrandOrderProductItemsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandOrderProductItemsQuery, GetBrandOrderProductItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandOrderProductItemsQuery, GetBrandOrderProductItemsQueryVariables>(GetBrandOrderProductItemsDocument, options);
}
export function useGetBrandOrderProductItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandOrderProductItemsQuery, GetBrandOrderProductItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandOrderProductItemsQuery, GetBrandOrderProductItemsQueryVariables>(GetBrandOrderProductItemsDocument, options);
}
export type GetBrandOrderProductItemsQueryHookResult = ReturnType<typeof useGetBrandOrderProductItemsQuery>;
export type GetBrandOrderProductItemsLazyQueryHookResult = ReturnType<typeof useGetBrandOrderProductItemsLazyQuery>;
export type GetBrandOrderProductItemsQueryResult = Apollo.QueryResult<GetBrandOrderProductItemsQuery, GetBrandOrderProductItemsQueryVariables>;
export const GetBrandOrderProductItemsExcelDocument = gql`
  query GetBrandOrderProductItemsExcel($lang: LanguageCodeEnum!, $condition: GetBrandOrderProductItemsInput) {
    getBrandOrderProductItemsExcel(lang: $lang, condition: $condition)
  }
`;

/**
 * __useGetBrandOrderProductItemsExcelQuery__
 *
 * To run a query within a React component, call `useGetBrandOrderProductItemsExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandOrderProductItemsExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandOrderProductItemsExcelQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useGetBrandOrderProductItemsExcelQuery(baseOptions: Apollo.QueryHookOptions<GetBrandOrderProductItemsExcelQuery, GetBrandOrderProductItemsExcelQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandOrderProductItemsExcelQuery, GetBrandOrderProductItemsExcelQueryVariables>(GetBrandOrderProductItemsExcelDocument, options);
}
export function useGetBrandOrderProductItemsExcelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBrandOrderProductItemsExcelQuery, GetBrandOrderProductItemsExcelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandOrderProductItemsExcelQuery, GetBrandOrderProductItemsExcelQueryVariables>(GetBrandOrderProductItemsExcelDocument, options);
}
export type GetBrandOrderProductItemsExcelQueryHookResult = ReturnType<typeof useGetBrandOrderProductItemsExcelQuery>;
export type GetBrandOrderProductItemsExcelLazyQueryHookResult = ReturnType<typeof useGetBrandOrderProductItemsExcelLazyQuery>;
export type GetBrandOrderProductItemsExcelQueryResult = Apollo.QueryResult<GetBrandOrderProductItemsExcelQuery, GetBrandOrderProductItemsExcelQueryVariables>;
export const GetBrandSettlementOrderExcelDocument = gql`
  query GetBrandSettlementOrderExcel($lang: LanguageCodeEnum!, $month: Int!, $year: Int!) {
    getBrandSettlementOrderExcel(lang: $lang, month: $month, year: $year)
  }
`;

/**
 * __useGetBrandSettlementOrderExcelQuery__
 *
 * To run a query within a React component, call `useGetBrandSettlementOrderExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandSettlementOrderExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandSettlementOrderExcelQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetBrandSettlementOrderExcelQuery(baseOptions: Apollo.QueryHookOptions<GetBrandSettlementOrderExcelQuery, GetBrandSettlementOrderExcelQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandSettlementOrderExcelQuery, GetBrandSettlementOrderExcelQueryVariables>(GetBrandSettlementOrderExcelDocument, options);
}
export function useGetBrandSettlementOrderExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandSettlementOrderExcelQuery, GetBrandSettlementOrderExcelQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandSettlementOrderExcelQuery, GetBrandSettlementOrderExcelQueryVariables>(GetBrandSettlementOrderExcelDocument, options);
}
export type GetBrandSettlementOrderExcelQueryHookResult = ReturnType<typeof useGetBrandSettlementOrderExcelQuery>;
export type GetBrandSettlementOrderExcelLazyQueryHookResult = ReturnType<typeof useGetBrandSettlementOrderExcelLazyQuery>;
export type GetBrandSettlementOrderExcelQueryResult = Apollo.QueryResult<GetBrandSettlementOrderExcelQuery, GetBrandSettlementOrderExcelQueryVariables>;
export const GetBrandSettlementsDocument = gql`
  query GetBrandSettlements($pagination: PaginationInput!) {
    getBrandSettlements(pagination: $pagination) {
      pagination {
        totalCount
        currentPage
        perPage
        lastPage
      }
      data {
        id
        brandId
        year
        month
        since
        until
        settleSince
        settleUntil
        saleCount
        saleAmount
        benefitBurdenAmount
        couponBurdenAmount
        planType
        isExclusive
        aconCommission
        aconCommissionAmount
        pgCommission
        pgCommissionAmount
        vatAmount
        settlementAmount
        nationalTax
        nationalTaxAmount
        localTax
        localTaxAmount
        tax
        taxAmount
        correctionAmount
        resultAmount
        exchangeRate
        saleAmountForeign
        totalSaleAmount
        benefitBurdenAmountForeign
        totalBenefitBurdenAmount
        vatAmountForeign
        totalVatAmount
        couponBurdenAmountForeign
        totalCouponBurdenAmount
      }
    }
  }
`;

/**
 * __useGetBrandSettlementsQuery__
 *
 * To run a query within a React component, call `useGetBrandSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandSettlementsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetBrandSettlementsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandSettlementsQuery, GetBrandSettlementsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandSettlementsQuery, GetBrandSettlementsQueryVariables>(GetBrandSettlementsDocument, options);
}
export function useGetBrandSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandSettlementsQuery, GetBrandSettlementsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandSettlementsQuery, GetBrandSettlementsQueryVariables>(GetBrandSettlementsDocument, options);
}
export type GetBrandSettlementsQueryHookResult = ReturnType<typeof useGetBrandSettlementsQuery>;
export type GetBrandSettlementsLazyQueryHookResult = ReturnType<typeof useGetBrandSettlementsLazyQuery>;
export type GetBrandSettlementsQueryResult = Apollo.QueryResult<GetBrandSettlementsQuery, GetBrandSettlementsQueryVariables>;
export const GetFaqDocument = gql`
  query GetFaq($id: Int!) {
    faq(id: $id) {
      id
      title
      body
      typeId
      typeName
      anchoringOrder
      created
      next {
        id
        title
        typeId
        typeName
        created
      }
      prev {
        id
        title
        typeId
        typeName
        created
      }
    }
  }
`;

/**
 * __useGetFaqQuery__
 *
 * To run a query within a React component, call `useGetFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFaqQuery(baseOptions: Apollo.QueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options);
}
export function useGetFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options);
}
export type GetFaqQueryHookResult = ReturnType<typeof useGetFaqQuery>;
export type GetFaqLazyQueryHookResult = ReturnType<typeof useGetFaqLazyQuery>;
export type GetFaqQueryResult = Apollo.QueryResult<GetFaqQuery, GetFaqQueryVariables>;
export const GetIsEqualPasswordDocument = gql`
  query GetIsEqualPassword($password: String!) {
    isEqualPassword(password: $password)
  }
`;

/**
 * __useGetIsEqualPasswordQuery__
 *
 * To run a query within a React component, call `useGetIsEqualPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsEqualPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsEqualPasswordQuery({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetIsEqualPasswordQuery(baseOptions: Apollo.QueryHookOptions<GetIsEqualPasswordQuery, GetIsEqualPasswordQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIsEqualPasswordQuery, GetIsEqualPasswordQueryVariables>(GetIsEqualPasswordDocument, options);
}
export function useGetIsEqualPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsEqualPasswordQuery, GetIsEqualPasswordQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIsEqualPasswordQuery, GetIsEqualPasswordQueryVariables>(GetIsEqualPasswordDocument, options);
}
export type GetIsEqualPasswordQueryHookResult = ReturnType<typeof useGetIsEqualPasswordQuery>;
export type GetIsEqualPasswordLazyQueryHookResult = ReturnType<typeof useGetIsEqualPasswordLazyQuery>;
export type GetIsEqualPasswordQueryResult = Apollo.QueryResult<GetIsEqualPasswordQuery, GetIsEqualPasswordQueryVariables>;
export const GetIsUsedEmailDocument = gql`
  query getIsUsedEmail($account: String!) {
    existsPartner(account: $account)
  }
`;

/**
 * __useGetIsUsedEmailQuery__
 *
 * To run a query within a React component, call `useGetIsUsedEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsUsedEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsUsedEmailQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetIsUsedEmailQuery(baseOptions: Apollo.QueryHookOptions<GetIsUsedEmailQuery, GetIsUsedEmailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIsUsedEmailQuery, GetIsUsedEmailQueryVariables>(GetIsUsedEmailDocument, options);
}
export function useGetIsUsedEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsUsedEmailQuery, GetIsUsedEmailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIsUsedEmailQuery, GetIsUsedEmailQueryVariables>(GetIsUsedEmailDocument, options);
}
export type GetIsUsedEmailQueryHookResult = ReturnType<typeof useGetIsUsedEmailQuery>;
export type GetIsUsedEmailLazyQueryHookResult = ReturnType<typeof useGetIsUsedEmailLazyQuery>;
export type GetIsUsedEmailQueryResult = Apollo.QueryResult<GetIsUsedEmailQuery, GetIsUsedEmailQueryVariables>;
export const GetLicenseTypesDocument = gql`
  query GetLicenseTypes {
    licenseTypes {
      id
      code
      usableCount
    }
  }
`;

/**
 * __useGetLicenseTypesQuery__
 *
 * To run a query within a React component, call `useGetLicenseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicenseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicenseTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLicenseTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetLicenseTypesQuery, GetLicenseTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLicenseTypesQuery, GetLicenseTypesQueryVariables>(GetLicenseTypesDocument, options);
}
export function useGetLicenseTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicenseTypesQuery, GetLicenseTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLicenseTypesQuery, GetLicenseTypesQueryVariables>(GetLicenseTypesDocument, options);
}
export type GetLicenseTypesQueryHookResult = ReturnType<typeof useGetLicenseTypesQuery>;
export type GetLicenseTypesLazyQueryHookResult = ReturnType<typeof useGetLicenseTypesLazyQuery>;
export type GetLicenseTypesQueryResult = Apollo.QueryResult<GetLicenseTypesQuery, GetLicenseTypesQueryVariables>;
export const MyBrandDocument = gql`
  query MyBrand {
    myBrand {
      id
      i18ns {
        languageCode
        name
        introduction
      }
      name
      godoBrandCd
      photo {
        id
        azureStoragePath
        fileName
        size
        creator
        updater
      }
      introduction
      introductionLang
      modified
      showBrandReview
    }
  }
`;

/**
 * __useMyBrandQuery__
 *
 * To run a query within a React component, call `useMyBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBrandQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyBrandQuery(baseOptions?: Apollo.QueryHookOptions<MyBrandQuery, MyBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyBrandQuery, MyBrandQueryVariables>(MyBrandDocument, options);
}
export function useMyBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBrandQuery, MyBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyBrandQuery, MyBrandQueryVariables>(MyBrandDocument, options);
}
export type MyBrandQueryHookResult = ReturnType<typeof useMyBrandQuery>;
export type MyBrandLazyQueryHookResult = ReturnType<typeof useMyBrandLazyQuery>;
export type MyBrandQueryResult = Apollo.QueryResult<MyBrandQuery, MyBrandQueryVariables>;
export const GetNoticeDocument = gql`
  query GetNotice($id: Int!) {
    notice(id: $id) {
      id
      title
      body
      typeId
      typeName
      anchoringOrder
      created
      next {
        id
        title
        typeId
        typeName
        created
      }
      prev {
        id
        title
        typeId
        typeName
        created
      }
    }
  }
`;

/**
 * __useGetNoticeQuery__
 *
 * To run a query within a React component, call `useGetNoticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoticeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNoticeQuery(baseOptions: Apollo.QueryHookOptions<GetNoticeQuery, GetNoticeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNoticeQuery, GetNoticeQueryVariables>(GetNoticeDocument, options);
}
export function useGetNoticeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoticeQuery, GetNoticeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNoticeQuery, GetNoticeQueryVariables>(GetNoticeDocument, options);
}
export type GetNoticeQueryHookResult = ReturnType<typeof useGetNoticeQuery>;
export type GetNoticeLazyQueryHookResult = ReturnType<typeof useGetNoticeLazyQuery>;
export type GetNoticeQueryResult = Apollo.QueryResult<GetNoticeQuery, GetNoticeQueryVariables>;
export const GetPartnerDocument = gql`
  query GetPartner {
    me {
      name
      type
      subType
      typeString
      subTypeString
      subTypeStringList
      companyName
      account
      contact
      contactAdditional
      snsUrl
      joinLanguage
      accept {
        status
        comment
        modifyStatus
        modifyRejectComment
        modifyApproveComment
      }
      brand {
        id
        name
        godoBrandCd
        i18ns {
          brandId
          languageCode
          name
        }
      }
      reference {
        id
        url
      }
      settle {
        bankName
        bankAccountNumber
        bankAccountOwner
        nationalIdNumber
        status
        bankId
        country
        signLang
        bank {
          name
        }
        bankBook {
          id
          fileName
          path
        }
        idCard {
          id
          fileName
          path
          size
        }
        limitedTaxApl {
          id
          fileName
          path
          size
        }
        sign {
          id
        }
      }
    }
    termAgree {
      uploadFileId
      agreeAt
      file {
        path
      }
    }
    partnerPlan {
      type
      isExclusive
      commissionRate
    }
  }
`;

/**
 * __useGetPartnerQuery__
 *
 * To run a query within a React component, call `useGetPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnerQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerQuery, GetPartnerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerQuery, GetPartnerQueryVariables>(GetPartnerDocument, options);
}
export function useGetPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerQuery, GetPartnerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerQuery, GetPartnerQueryVariables>(GetPartnerDocument, options);
}
export type GetPartnerQueryHookResult = ReturnType<typeof useGetPartnerQuery>;
export type GetPartnerLazyQueryHookResult = ReturnType<typeof useGetPartnerLazyQuery>;
export type GetPartnerQueryResult = Apollo.QueryResult<GetPartnerQuery, GetPartnerQueryVariables>;
export const GetPartnerChangeDocument = gql`
  query GetPartnerChange {
    me {
      account
      contact
      brand {
        name
      }
    }
  }
`;

/**
 * __useGetPartnerChangeQuery__
 *
 * To run a query within a React component, call `useGetPartnerChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerChangeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnerChangeQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerChangeQuery, GetPartnerChangeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerChangeQuery, GetPartnerChangeQueryVariables>(GetPartnerChangeDocument, options);
}
export function useGetPartnerChangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerChangeQuery, GetPartnerChangeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerChangeQuery, GetPartnerChangeQueryVariables>(GetPartnerChangeDocument, options);
}
export type GetPartnerChangeQueryHookResult = ReturnType<typeof useGetPartnerChangeQuery>;
export type GetPartnerChangeLazyQueryHookResult = ReturnType<typeof useGetPartnerChangeLazyQuery>;
export type GetPartnerChangeQueryResult = Apollo.QueryResult<GetPartnerChangeQuery, GetPartnerChangeQueryVariables>;
export const GetPartnerPlanDocument = gql`
  query GetPartnerPlan {
    me {
      id
      name
      companyName
      contact
      brand {
        godoBrandCd
      }
      settle {
        nationalIdNumber
        imageSign
      }
      reference {
        id
        url
      }
      joinLanguage
    }
    termAgree {
      agreeAt
      file {
        path
        id
        fileName
      }
    }
    partnerPlan {
      userId
      type
      isExclusive
      commissionRate
    }
  }
`;

/**
 * __useGetPartnerPlanQuery__
 *
 * To run a query within a React component, call `useGetPartnerPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnerPlanQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerPlanQuery, GetPartnerPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerPlanQuery, GetPartnerPlanQueryVariables>(GetPartnerPlanDocument, options);
}
export function useGetPartnerPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerPlanQuery, GetPartnerPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerPlanQuery, GetPartnerPlanQueryVariables>(GetPartnerPlanDocument, options);
}
export type GetPartnerPlanQueryHookResult = ReturnType<typeof useGetPartnerPlanQuery>;
export type GetPartnerPlanLazyQueryHookResult = ReturnType<typeof useGetPartnerPlanLazyQuery>;
export type GetPartnerPlanQueryResult = Apollo.QueryResult<GetPartnerPlanQuery, GetPartnerPlanQueryVariables>;
export const GetPartnerSettleDocument = gql`
  query getPartnerSettle {
    me {
      type
      typeString
      typeStringList
      subType
      subTypeStringList
      name
      companyName
    }
    partnerSettle {
      bankName
      bankAccountNumber
      bankAccountOwner
      nationalIdNumber
      bankId
      bankBook {
        path
        fileName
        id
        size
      }
      idCard {
        path
        fileName
        id
        size
      }
      limitedTaxApl {
        path
        fileName
        id
        size
      }
      bank {
        name
      }
    }
  }
`;

/**
 * __useGetPartnerSettleQuery__
 *
 * To run a query within a React component, call `useGetPartnerSettleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerSettleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerSettleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnerSettleQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerSettleQuery, GetPartnerSettleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerSettleQuery, GetPartnerSettleQueryVariables>(GetPartnerSettleDocument, options);
}
export function useGetPartnerSettleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerSettleQuery, GetPartnerSettleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerSettleQuery, GetPartnerSettleQueryVariables>(GetPartnerSettleDocument, options);
}
export type GetPartnerSettleQueryHookResult = ReturnType<typeof useGetPartnerSettleQuery>;
export type GetPartnerSettleLazyQueryHookResult = ReturnType<typeof useGetPartnerSettleLazyQuery>;
export type GetPartnerSettleQueryResult = Apollo.QueryResult<GetPartnerSettleQuery, GetPartnerSettleQueryVariables>;
export const GetPartnerSettleAdminDocument = gql`
  query GetPartnerSettleAdmin($id: Int!) {
    partner(id: $id) {
      type
      typeString
      typeStringList
      subType
      subTypeStringList
      name
      companyName
    }
    partnerSettle {
      bankName
      bankAccountNumber
      bankAccountOwner
      nationalIdNumber
      bankId
      bankBook {
        id
        path
      }
      idCard {
        id
        path
        size
      }
      limitedTaxApl {
        id
        path
        size
      }
      bank {
        name
      }
    }
  }
`;

/**
 * __useGetPartnerSettleAdminQuery__
 *
 * To run a query within a React component, call `useGetPartnerSettleAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerSettleAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerSettleAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartnerSettleAdminQuery(baseOptions: Apollo.QueryHookOptions<GetPartnerSettleAdminQuery, GetPartnerSettleAdminQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerSettleAdminQuery, GetPartnerSettleAdminQueryVariables>(GetPartnerSettleAdminDocument, options);
}
export function useGetPartnerSettleAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerSettleAdminQuery, GetPartnerSettleAdminQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerSettleAdminQuery, GetPartnerSettleAdminQueryVariables>(GetPartnerSettleAdminDocument, options);
}
export type GetPartnerSettleAdminQueryHookResult = ReturnType<typeof useGetPartnerSettleAdminQuery>;
export type GetPartnerSettleAdminLazyQueryHookResult = ReturnType<typeof useGetPartnerSettleAdminLazyQuery>;
export type GetPartnerSettleAdminQueryResult = Apollo.QueryResult<GetPartnerSettleAdminQuery, GetPartnerSettleAdminQueryVariables>;
export const GetPartnerStoreDocument = gql`
  query GetPartnerStore {
    me {
      name
      type
      subType
      typeString
      subTypeString
      subTypeStringList
      companyName
      account
      contact
      contactAdditional
      snsUrl
      joinLanguage
      brand {
        id
        name
        godoBrandCd
        i18ns {
          brandId
          languageCode
          name
        }
      }
      reference {
        id
        url
      }
      settle {
        bankName
        bankAccountNumber
        bankAccountOwner
        nationalIdNumber
        status
        bankId
        country
        signLang
        bank {
          name
        }
        bankBook {
          id
          fileName
          path
        }
        idCard {
          id
          fileName
          path
          size
        }
        limitedTaxApl {
          id
          fileName
          path
          size
        }
        sign {
          id
          fileName
          path
          size
        }
      }
    }
    termAgree {
      uploadFileId
      agreeAt
      file {
        path
      }
    }
  }
`;

/**
 * __useGetPartnerStoreQuery__
 *
 * To run a query within a React component, call `useGetPartnerStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerStoreQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnerStoreQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>(GetPartnerStoreDocument, options);
}
export function useGetPartnerStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>(GetPartnerStoreDocument, options);
}
export type GetPartnerStoreQueryHookResult = ReturnType<typeof useGetPartnerStoreQuery>;
export type GetPartnerStoreLazyQueryHookResult = ReturnType<typeof useGetPartnerStoreLazyQuery>;
export type GetPartnerStoreQueryResult = Apollo.QueryResult<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>;
export const GetProductCardsSummaryDocument = gql`
  query GetProductCardsSummary($brandCode: String) {
    productCardsSummary(brandCode: $brandCode) {
      products {
        id
      }
    }
  }
`;

/**
 * __useGetProductCardsSummaryQuery__
 *
 * To run a query within a React component, call `useGetProductCardsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCardsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCardsSummaryQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useGetProductCardsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCardsSummaryQuery, GetProductCardsSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductCardsSummaryQuery, GetProductCardsSummaryQueryVariables>(GetProductCardsSummaryDocument, options);
}
export function useGetProductCardsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCardsSummaryQuery, GetProductCardsSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductCardsSummaryQuery, GetProductCardsSummaryQueryVariables>(GetProductCardsSummaryDocument, options);
}
export type GetProductCardsSummaryQueryHookResult = ReturnType<typeof useGetProductCardsSummaryQuery>;
export type GetProductCardsSummaryLazyQueryHookResult = ReturnType<typeof useGetProductCardsSummaryLazyQuery>;
export type GetProductCardsSummaryQueryResult = Apollo.QueryResult<GetProductCardsSummaryQuery, GetProductCardsSummaryQueryVariables>;
export const GetSasUrlDocument = gql`
  query GetSasUrl($inputs: SasUrlInput!) {
    sasUrl(inputs: $inputs)
  }
`;

/**
 * __useGetSasUrlQuery__
 *
 * To run a query within a React component, call `useGetSasUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSasUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSasUrlQuery({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useGetSasUrlQuery(baseOptions: Apollo.QueryHookOptions<GetSasUrlQuery, GetSasUrlQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSasUrlQuery, GetSasUrlQueryVariables>(GetSasUrlDocument, options);
}
export function useGetSasUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSasUrlQuery, GetSasUrlQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSasUrlQuery, GetSasUrlQueryVariables>(GetSasUrlDocument, options);
}
export type GetSasUrlQueryHookResult = ReturnType<typeof useGetSasUrlQuery>;
export type GetSasUrlLazyQueryHookResult = ReturnType<typeof useGetSasUrlLazyQuery>;
export type GetSasUrlQueryResult = Apollo.QueryResult<GetSasUrlQuery, GetSasUrlQueryVariables>;
export const GetSettleDocument = gql`
  query GetSettle($goodsNo: Int!, $orderNo: String!) {
    getSettleV2(goodsNo: $goodsNo, orderNo: $orderNo) {
      orderNo
      brandCd
      licenseType
      licenseName
      projectName
      orderName
      goodsNm
      regDt
      paymentDt
      settleDt
      goodsPrice
      goodsDcPrice
      couponGoodsDcPrice
      addField
      benefitBrandBurden
      brandBurden
      languageCode
      country
    }
  }
`;

/**
 * __useGetSettleQuery__
 *
 * To run a query within a React component, call `useGetSettleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettleQuery({
 *   variables: {
 *      goodsNo: // value for 'goodsNo'
 *      orderNo: // value for 'orderNo'
 *   },
 * });
 */
export function useGetSettleQuery(baseOptions: Apollo.QueryHookOptions<GetSettleQuery, GetSettleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSettleQuery, GetSettleQueryVariables>(GetSettleDocument, options);
}
export function useGetSettleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettleQuery, GetSettleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSettleQuery, GetSettleQueryVariables>(GetSettleDocument, options);
}
export type GetSettleQueryHookResult = ReturnType<typeof useGetSettleQuery>;
export type GetSettleLazyQueryHookResult = ReturnType<typeof useGetSettleLazyQuery>;
export type GetSettleQueryResult = Apollo.QueryResult<GetSettleQuery, GetSettleQueryVariables>;
export const GetSettleListDocument = gql`
  query getSettleList(
    $brandId: String!
    $page: Int
    $limit: Int
    $isFree: Boolean
    $startDate: String
    $endDate: String
    $goodsNm: String
    $penNm: String
    $paymentStartDate: String
    $paymentEndDate: String
    $settleStartDate: String
    $settleEndDate: String
  ) {
    getSettleCount(
      brandId: $brandId
      isFree: $isFree
      startDate: $startDate
      endDate: $endDate
      goodsNm: $goodsNm
      penNm: $penNm
      settleStartDate: $settleStartDate
      settleEndDate: $settleEndDate
      paymentStartDate: $paymentStartDate
      paymentEndDate: $paymentEndDate
    ) {
      totalCount
      searchCount
    }
    getSettleListV2(
      brandId: $brandId
      page: $page
      limit: $limit
      isFree: $isFree
      startDate: $startDate
      endDate: $endDate
      goodsNm: $goodsNm
      penNm: $penNm
      settleStartDate: $settleStartDate
      settleEndDate: $settleEndDate
      paymentStartDate: $paymentStartDate
      paymentEndDate: $paymentEndDate
    ) {
      sno
      brandCd
      orderNo
      orderMemo
      licenseType
      orderName
      goodsNm
      paymentDt
      settleDt
      regDt
      goodsPrice
      goodsDcPrice
      couponGoodsDcPrice
      addField
      goodsNo
    }
  }
`;

/**
 * __useGetSettleListQuery__
 *
 * To run a query within a React component, call `useGetSettleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettleListQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      isFree: // value for 'isFree'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      goodsNm: // value for 'goodsNm'
 *      penNm: // value for 'penNm'
 *      paymentStartDate: // value for 'paymentStartDate'
 *      paymentEndDate: // value for 'paymentEndDate'
 *      settleStartDate: // value for 'settleStartDate'
 *      settleEndDate: // value for 'settleEndDate'
 *   },
 * });
 */
export function useGetSettleListQuery(baseOptions: Apollo.QueryHookOptions<GetSettleListQuery, GetSettleListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSettleListQuery, GetSettleListQueryVariables>(GetSettleListDocument, options);
}
export function useGetSettleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettleListQuery, GetSettleListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSettleListQuery, GetSettleListQueryVariables>(GetSettleListDocument, options);
}
export type GetSettleListQueryHookResult = ReturnType<typeof useGetSettleListQuery>;
export type GetSettleListLazyQueryHookResult = ReturnType<typeof useGetSettleListLazyQuery>;
export type GetSettleListQueryResult = Apollo.QueryResult<GetSettleListQuery, GetSettleListQueryVariables>;
export const HaveRegisteredPromotionDocument = gql`
  query HaveRegisteredPromotion($haveRegisteredPromotionId: Int!) {
    haveRegisteredPromotion(id: $haveRegisteredPromotionId)
  }
`;

/**
 * __useHaveRegisteredPromotionQuery__
 *
 * To run a query within a React component, call `useHaveRegisteredPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHaveRegisteredPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHaveRegisteredPromotionQuery({
 *   variables: {
 *      haveRegisteredPromotionId: // value for 'haveRegisteredPromotionId'
 *   },
 * });
 */
export function useHaveRegisteredPromotionQuery(baseOptions: Apollo.QueryHookOptions<HaveRegisteredPromotionQuery, HaveRegisteredPromotionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HaveRegisteredPromotionQuery, HaveRegisteredPromotionQueryVariables>(HaveRegisteredPromotionDocument, options);
}
export function useHaveRegisteredPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HaveRegisteredPromotionQuery, HaveRegisteredPromotionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HaveRegisteredPromotionQuery, HaveRegisteredPromotionQueryVariables>(HaveRegisteredPromotionDocument, options);
}
export type HaveRegisteredPromotionQueryHookResult = ReturnType<typeof useHaveRegisteredPromotionQuery>;
export type HaveRegisteredPromotionLazyQueryHookResult = ReturnType<typeof useHaveRegisteredPromotionLazyQuery>;
export type HaveRegisteredPromotionQueryResult = Apollo.QueryResult<HaveRegisteredPromotionQuery, HaveRegisteredPromotionQueryVariables>;
export const HubDownloadDocument = gql`
  query HubDownload($hubDownloadId: String!) {
    hubDownload(id: $hubDownloadId)
  }
`;

/**
 * __useHubDownloadQuery__
 *
 * To run a query within a React component, call `useHubDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubDownloadQuery({
 *   variables: {
 *      hubDownloadId: // value for 'hubDownloadId'
 *   },
 * });
 */
export function useHubDownloadQuery(baseOptions: Apollo.QueryHookOptions<HubDownloadQuery, HubDownloadQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HubDownloadQuery, HubDownloadQueryVariables>(HubDownloadDocument, options);
}
export function useHubDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HubDownloadQuery, HubDownloadQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HubDownloadQuery, HubDownloadQueryVariables>(HubDownloadDocument, options);
}
export type HubDownloadQueryHookResult = ReturnType<typeof useHubDownloadQuery>;
export type HubDownloadLazyQueryHookResult = ReturnType<typeof useHubDownloadLazyQuery>;
export type HubDownloadQueryResult = Apollo.QueryResult<HubDownloadQuery, HubDownloadQueryVariables>;
export const MeDocument = gql`
  query Me {
    me {
      brand {
        photoInfo {
          path
        }
        i18ns {
          name
          languageCode
        }
      }
      settle {
        country
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyAssetsDocument = gql`
  query MyAssets($language: LANGUAGE_CODE!, $title: String, $includeFree: Boolean, $includeIrregularPrice: Boolean, $isOnPromotion: Boolean, $daysAfterReleased: Int) {
    myAssets(
      language: $language
      title: $title
      includeFree: $includeFree
      includeIrregularPrice: $includeIrregularPrice
      isOnPromotion: $isOnPromotion
      daysAfterReleased: $daysAfterReleased
    ) {
      id
      title
      price
    }
  }
`;

/**
 * __useMyAssetsQuery__
 *
 * To run a query within a React component, call `useMyAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAssetsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      title: // value for 'title'
 *      includeFree: // value for 'includeFree'
 *      includeIrregularPrice: // value for 'includeIrregularPrice'
 *      isOnPromotion: // value for 'isOnPromotion'
 *      daysAfterReleased: // value for 'daysAfterReleased'
 *   },
 * });
 */
export function useMyAssetsQuery(baseOptions: Apollo.QueryHookOptions<MyAssetsQuery, MyAssetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyAssetsQuery, MyAssetsQueryVariables>(MyAssetsDocument, options);
}
export function useMyAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAssetsQuery, MyAssetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyAssetsQuery, MyAssetsQueryVariables>(MyAssetsDocument, options);
}
export type MyAssetsQueryHookResult = ReturnType<typeof useMyAssetsQuery>;
export type MyAssetsLazyQueryHookResult = ReturnType<typeof useMyAssetsLazyQuery>;
export type MyAssetsQueryResult = Apollo.QueryResult<MyAssetsQuery, MyAssetsQueryVariables>;
export const OpenrunPromotionsDocument = gql`
  query OpenrunPromotions($page: Int, $limit: Int, $languageCode: LanguageCodeEnum) {
    openrunPromotions(page: $page, limit: $limit, languageCode: $languageCode) {
      data {
        assetId
        id
        assetName
        rejectReason
        since
        status
        until
      }
      meta {
        currentPage
        lastPage
        perPage
        totalCount
      }
      stateSpecificTotal {
        status
        total
      }
    }
  }
`;

/**
 * __useOpenrunPromotionsQuery__
 *
 * To run a query within a React component, call `useOpenrunPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenrunPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenrunPromotionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useOpenrunPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<OpenrunPromotionsQuery, OpenrunPromotionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpenrunPromotionsQuery, OpenrunPromotionsQueryVariables>(OpenrunPromotionsDocument, options);
}
export function useOpenrunPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenrunPromotionsQuery, OpenrunPromotionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpenrunPromotionsQuery, OpenrunPromotionsQueryVariables>(OpenrunPromotionsDocument, options);
}
export type OpenrunPromotionsQueryHookResult = ReturnType<typeof useOpenrunPromotionsQuery>;
export type OpenrunPromotionsLazyQueryHookResult = ReturnType<typeof useOpenrunPromotionsLazyQuery>;
export type OpenrunPromotionsQueryResult = Apollo.QueryResult<OpenrunPromotionsQuery, OpenrunPromotionsQueryVariables>;
export const PartnerSettleDocument = gql`
  query PartnerSettle {
    partnerSettle {
      bankName
      bankAccountNumber
      bankAccountOwner
      bank {
        name
      }
    }
  }
`;

/**
 * __usePartnerSettleQuery__
 *
 * To run a query within a React component, call `usePartnerSettleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerSettleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerSettleQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerSettleQuery(baseOptions?: Apollo.QueryHookOptions<PartnerSettleQuery, PartnerSettleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerSettleQuery, PartnerSettleQueryVariables>(PartnerSettleDocument, options);
}
export function usePartnerSettleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerSettleQuery, PartnerSettleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerSettleQuery, PartnerSettleQueryVariables>(PartnerSettleDocument, options);
}
export type PartnerSettleQueryHookResult = ReturnType<typeof usePartnerSettleQuery>;
export type PartnerSettleLazyQueryHookResult = ReturnType<typeof usePartnerSettleLazyQuery>;
export type PartnerSettleQueryResult = Apollo.QueryResult<PartnerSettleQuery, PartnerSettleQueryVariables>;
export const ReadNotificationDocument = gql`
  mutation ReadNotification($type: String) {
    readNotification(type: $type)
  }
`;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
}
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const RegisterBrandPromotionDocument = gql`
  mutation RegisterBrandPromotion($input: RegisterBrandPromotionInput!) {
    registerBrandPromotion(input: $input) {
      createdAt
      canceledAt
    }
  }
`;
export type RegisterBrandPromotionMutationFn = Apollo.MutationFunction<RegisterBrandPromotionMutation, RegisterBrandPromotionMutationVariables>;

/**
 * __useRegisterBrandPromotionMutation__
 *
 * To run a mutation, you first call `useRegisterBrandPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterBrandPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerBrandPromotionMutation, { data, loading, error }] = useRegisterBrandPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterBrandPromotionMutation(baseOptions?: Apollo.MutationHookOptions<RegisterBrandPromotionMutation, RegisterBrandPromotionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterBrandPromotionMutation, RegisterBrandPromotionMutationVariables>(RegisterBrandPromotionDocument, options);
}
export type RegisterBrandPromotionMutationHookResult = ReturnType<typeof useRegisterBrandPromotionMutation>;
export type RegisterBrandPromotionMutationResult = Apollo.MutationResult<RegisterBrandPromotionMutation>;
export type RegisterBrandPromotionMutationOptions = Apollo.BaseMutationOptions<RegisterBrandPromotionMutation, RegisterBrandPromotionMutationVariables>;
export const RegisterOpenrunPromotionDocument = gql`
  mutation RegisterOpenrunPromotion($input: RegisterOpenrunPromotionInput!) {
    registerOpenrunPromotion(input: $input) {
      assetId
      assetName
      id
      rejectReason
      since
      status
      until
    }
  }
`;
export type RegisterOpenrunPromotionMutationFn = Apollo.MutationFunction<RegisterOpenrunPromotionMutation, RegisterOpenrunPromotionMutationVariables>;

/**
 * __useRegisterOpenrunPromotionMutation__
 *
 * To run a mutation, you first call `useRegisterOpenrunPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterOpenrunPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerOpenrunPromotionMutation, { data, loading, error }] = useRegisterOpenrunPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterOpenrunPromotionMutation(baseOptions?: Apollo.MutationHookOptions<RegisterOpenrunPromotionMutation, RegisterOpenrunPromotionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterOpenrunPromotionMutation, RegisterOpenrunPromotionMutationVariables>(RegisterOpenrunPromotionDocument, options);
}
export type RegisterOpenrunPromotionMutationHookResult = ReturnType<typeof useRegisterOpenrunPromotionMutation>;
export type RegisterOpenrunPromotionMutationResult = Apollo.MutationResult<RegisterOpenrunPromotionMutation>;
export type RegisterOpenrunPromotionMutationOptions = Apollo.BaseMutationOptions<RegisterOpenrunPromotionMutation, RegisterOpenrunPromotionMutationVariables>;
export const RequestPartnerRevisionDocument = gql`
  mutation RequestPartnerRevision($user: RequestPartnerRevisionInput!) {
    requestPartnerRevision(user: $user)
  }
`;
export type RequestPartnerRevisionMutationFn = Apollo.MutationFunction<RequestPartnerRevisionMutation, RequestPartnerRevisionMutationVariables>;

/**
 * __useRequestPartnerRevisionMutation__
 *
 * To run a mutation, you first call `useRequestPartnerRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPartnerRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPartnerRevisionMutation, { data, loading, error }] = useRequestPartnerRevisionMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRequestPartnerRevisionMutation(baseOptions?: Apollo.MutationHookOptions<RequestPartnerRevisionMutation, RequestPartnerRevisionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestPartnerRevisionMutation, RequestPartnerRevisionMutationVariables>(RequestPartnerRevisionDocument, options);
}
export type RequestPartnerRevisionMutationHookResult = ReturnType<typeof useRequestPartnerRevisionMutation>;
export type RequestPartnerRevisionMutationResult = Apollo.MutationResult<RequestPartnerRevisionMutation>;
export type RequestPartnerRevisionMutationOptions = Apollo.BaseMutationOptions<RequestPartnerRevisionMutation, RequestPartnerRevisionMutationVariables>;
export const RequestPartnerSettleDocument = gql`
  mutation RequestPartnerSettle(
    $bankId: Int
    $bankName: String
    $bankAccountOwner: String
    $bankAccountNumber: String
    $idCard: StoredFileInput
    $bankBook: StoredFileInput
    $limitedTaxApl: StoredFileInput
  ) {
    requestPartnerSettle(
      bankId: $bankId
      bankName: $bankName
      bankAccountOwner: $bankAccountOwner
      bankAccountNumber: $bankAccountNumber
      idCard: $idCard
      bankBook: $bankBook
      limitedTaxApl: $limitedTaxApl
    )
  }
`;
export type RequestPartnerSettleMutationFn = Apollo.MutationFunction<RequestPartnerSettleMutation, RequestPartnerSettleMutationVariables>;

/**
 * __useRequestPartnerSettleMutation__
 *
 * To run a mutation, you first call `useRequestPartnerSettleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPartnerSettleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPartnerSettleMutation, { data, loading, error }] = useRequestPartnerSettleMutation({
 *   variables: {
 *      bankId: // value for 'bankId'
 *      bankName: // value for 'bankName'
 *      bankAccountOwner: // value for 'bankAccountOwner'
 *      bankAccountNumber: // value for 'bankAccountNumber'
 *      idCard: // value for 'idCard'
 *      bankBook: // value for 'bankBook'
 *      limitedTaxApl: // value for 'limitedTaxApl'
 *   },
 * });
 */
export function useRequestPartnerSettleMutation(baseOptions?: Apollo.MutationHookOptions<RequestPartnerSettleMutation, RequestPartnerSettleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestPartnerSettleMutation, RequestPartnerSettleMutationVariables>(RequestPartnerSettleDocument, options);
}
export type RequestPartnerSettleMutationHookResult = ReturnType<typeof useRequestPartnerSettleMutation>;
export type RequestPartnerSettleMutationResult = Apollo.MutationResult<RequestPartnerSettleMutation>;
export type RequestPartnerSettleMutationOptions = Apollo.BaseMutationOptions<RequestPartnerSettleMutation, RequestPartnerSettleMutationVariables>;
export const RetryDocument = gql`
  mutation Retry($input: DocumentCommonInput!) {
    retry(input: $input)
  }
`;
export type RetryMutationFn = Apollo.MutationFunction<RetryMutation, RetryMutationVariables>;

/**
 * __useRetryMutation__
 *
 * To run a mutation, you first call `useRetryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryMutation, { data, loading, error }] = useRetryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryMutation(baseOptions?: Apollo.MutationHookOptions<RetryMutation, RetryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RetryMutation, RetryMutationVariables>(RetryDocument, options);
}
export type RetryMutationHookResult = ReturnType<typeof useRetryMutation>;
export type RetryMutationResult = Apollo.MutationResult<RetryMutation>;
export type RetryMutationOptions = Apollo.BaseMutationOptions<RetryMutation, RetryMutationVariables>;
export const SaveDocumentDocument = gql`
  mutation SaveDocument($input: SingleDocumentInput!) {
    save(input: $input)
  }
`;
export type SaveDocumentMutationFn = Apollo.MutationFunction<SaveDocumentMutation, SaveDocumentMutationVariables>;

/**
 * __useSaveDocumentMutation__
 *
 * To run a mutation, you first call `useSaveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDocumentMutation, { data, loading, error }] = useSaveDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDocumentMutation(baseOptions?: Apollo.MutationHookOptions<SaveDocumentMutation, SaveDocumentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveDocumentMutation, SaveDocumentMutationVariables>(SaveDocumentDocument, options);
}
export type SaveDocumentMutationHookResult = ReturnType<typeof useSaveDocumentMutation>;
export type SaveDocumentMutationResult = Apollo.MutationResult<SaveDocumentMutation>;
export type SaveDocumentMutationOptions = Apollo.BaseMutationOptions<SaveDocumentMutation, SaveDocumentMutationVariables>;
export const SendAuthenticationCodeDocument = gql`
  mutation SendAuthenticationCode($email: String!, $lang: String!) {
    sendAuthenticationCode(email: $email, lang: $lang)
  }
`;
export type SendAuthenticationCodeMutationFn = Apollo.MutationFunction<SendAuthenticationCodeMutation, SendAuthenticationCodeMutationVariables>;

/**
 * __useSendAuthenticationCodeMutation__
 *
 * To run a mutation, you first call `useSendAuthenticationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAuthenticationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAuthenticationCodeMutation, { data, loading, error }] = useSendAuthenticationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSendAuthenticationCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendAuthenticationCodeMutation, SendAuthenticationCodeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendAuthenticationCodeMutation, SendAuthenticationCodeMutationVariables>(SendAuthenticationCodeDocument, options);
}
export type SendAuthenticationCodeMutationHookResult = ReturnType<typeof useSendAuthenticationCodeMutation>;
export type SendAuthenticationCodeMutationResult = Apollo.MutationResult<SendAuthenticationCodeMutation>;
export type SendAuthenticationCodeMutationOptions = Apollo.BaseMutationOptions<SendAuthenticationCodeMutation, SendAuthenticationCodeMutationVariables>;
export const StoreBrandIntroductionDocument = gql`
  mutation StoreBrandIntroduction($lang: LanguageCodeEnum!, $introduction: String!) {
    storeBrandIntroduction(lang: $lang, introduction: $introduction) {
      id
      name
      languageCode
      introduction
    }
  }
`;
export type StoreBrandIntroductionMutationFn = Apollo.MutationFunction<StoreBrandIntroductionMutation, StoreBrandIntroductionMutationVariables>;

/**
 * __useStoreBrandIntroductionMutation__
 *
 * To run a mutation, you first call `useStoreBrandIntroductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreBrandIntroductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeBrandIntroductionMutation, { data, loading, error }] = useStoreBrandIntroductionMutation({
 *   variables: {
 *      lang: // value for 'lang'
 *      introduction: // value for 'introduction'
 *   },
 * });
 */
export function useStoreBrandIntroductionMutation(baseOptions?: Apollo.MutationHookOptions<StoreBrandIntroductionMutation, StoreBrandIntroductionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreBrandIntroductionMutation, StoreBrandIntroductionMutationVariables>(StoreBrandIntroductionDocument, options);
}
export type StoreBrandIntroductionMutationHookResult = ReturnType<typeof useStoreBrandIntroductionMutation>;
export type StoreBrandIntroductionMutationResult = Apollo.MutationResult<StoreBrandIntroductionMutation>;
export type StoreBrandIntroductionMutationOptions = Apollo.BaseMutationOptions<StoreBrandIntroductionMutation, StoreBrandIntroductionMutationVariables>;
export const StoreBrandPhotoDocument = gql`
  mutation StoreBrandPhoto($photo: ProfilePhoto!) {
    storeBrandPhoto(photo: $photo) {
      id
      azureStoragePath
      fileName
      size
      creator
      updater
    }
  }
`;
export type StoreBrandPhotoMutationFn = Apollo.MutationFunction<StoreBrandPhotoMutation, StoreBrandPhotoMutationVariables>;

/**
 * __useStoreBrandPhotoMutation__
 *
 * To run a mutation, you first call `useStoreBrandPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreBrandPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeBrandPhotoMutation, { data, loading, error }] = useStoreBrandPhotoMutation({
 *   variables: {
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useStoreBrandPhotoMutation(baseOptions?: Apollo.MutationHookOptions<StoreBrandPhotoMutation, StoreBrandPhotoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreBrandPhotoMutation, StoreBrandPhotoMutationVariables>(StoreBrandPhotoDocument, options);
}
export type StoreBrandPhotoMutationHookResult = ReturnType<typeof useStoreBrandPhotoMutation>;
export type StoreBrandPhotoMutationResult = Apollo.MutationResult<StoreBrandPhotoMutation>;
export type StoreBrandPhotoMutationOptions = Apollo.BaseMutationOptions<StoreBrandPhotoMutation, StoreBrandPhotoMutationVariables>;
export const UpdateBrandPickDocument = gql`
  mutation UpdateBrandPick($assetIds: [Int!]!, $isOn: Boolean!) {
    updateBrandPick(assetIds: $assetIds, isOn: $isOn) {
      id
      name
      brandPickIds
      useBrandPick
      godoBrandCd
    }
  }
`;
export type UpdateBrandPickMutationFn = Apollo.MutationFunction<UpdateBrandPickMutation, UpdateBrandPickMutationVariables>;

/**
 * __useUpdateBrandPickMutation__
 *
 * To run a mutation, you first call `useUpdateBrandPickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandPickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandPickMutation, { data, loading, error }] = useUpdateBrandPickMutation({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *      isOn: // value for 'isOn'
 *   },
 * });
 */
export function useUpdateBrandPickMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandPickMutation, UpdateBrandPickMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBrandPickMutation, UpdateBrandPickMutationVariables>(UpdateBrandPickDocument, options);
}
export type UpdateBrandPickMutationHookResult = ReturnType<typeof useUpdateBrandPickMutation>;
export type UpdateBrandPickMutationResult = Apollo.MutationResult<UpdateBrandPickMutation>;
export type UpdateBrandPickMutationOptions = Apollo.BaseMutationOptions<UpdateBrandPickMutation, UpdateBrandPickMutationVariables>;
export const UpdateBrandReviewDocument = gql`
  mutation UpdateBrandReview($isOn: Boolean!) {
    updateBrandReview(isOn: $isOn) {
      id
    }
  }
`;
export type UpdateBrandReviewMutationFn = Apollo.MutationFunction<UpdateBrandReviewMutation, UpdateBrandReviewMutationVariables>;

/**
 * __useUpdateBrandReviewMutation__
 *
 * To run a mutation, you first call `useUpdateBrandReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandReviewMutation, { data, loading, error }] = useUpdateBrandReviewMutation({
 *   variables: {
 *      isOn: // value for 'isOn'
 *   },
 * });
 */
export function useUpdateBrandReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandReviewMutation, UpdateBrandReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBrandReviewMutation, UpdateBrandReviewMutationVariables>(UpdateBrandReviewDocument, options);
}
export type UpdateBrandReviewMutationHookResult = ReturnType<typeof useUpdateBrandReviewMutation>;
export type UpdateBrandReviewMutationResult = Apollo.MutationResult<UpdateBrandReviewMutation>;
export type UpdateBrandReviewMutationOptions = Apollo.BaseMutationOptions<UpdateBrandReviewMutation, UpdateBrandReviewMutationVariables>;
export const UploadBlobDocument = gql`
  mutation UploadBlob($targetDirectory: String!, $temporaryFilePath: String!, $fileName: String!) {
    uploadBlobStorage(inputs: { targetDirectory: $targetDirectory, temporaryFilePath: $temporaryFilePath, fileName: $fileName }) {
      id
      azureStoragePath
      fileName
    }
  }
`;
export type UploadBlobMutationFn = Apollo.MutationFunction<UploadBlobMutation, UploadBlobMutationVariables>;

/**
 * __useUploadBlobMutation__
 *
 * To run a mutation, you first call `useUploadBlobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBlobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBlobMutation, { data, loading, error }] = useUploadBlobMutation({
 *   variables: {
 *      targetDirectory: // value for 'targetDirectory'
 *      temporaryFilePath: // value for 'temporaryFilePath'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useUploadBlobMutation(baseOptions?: Apollo.MutationHookOptions<UploadBlobMutation, UploadBlobMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadBlobMutation, UploadBlobMutationVariables>(UploadBlobDocument, options);
}
export type UploadBlobMutationHookResult = ReturnType<typeof useUploadBlobMutation>;
export type UploadBlobMutationResult = Apollo.MutationResult<UploadBlobMutation>;
export type UploadBlobMutationOptions = Apollo.BaseMutationOptions<UploadBlobMutation, UploadBlobMutationVariables>;
export const AuthenticateDocument = gql`
  mutation Authenticate($email: String!, $code: String!) {
    authenticate(email: $email, code: $code)
  }
`;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const GetIsDuplicateBrandNameDocument = gql`
  query GetIsDuplicateBrandName($lang: String!, $name: String!) {
    isDuplicateBrandName(lang: $lang, name: $name)
  }
`;

/**
 * __useGetIsDuplicateBrandNameQuery__
 *
 * To run a query within a React component, call `useGetIsDuplicateBrandNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsDuplicateBrandNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsDuplicateBrandNameQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetIsDuplicateBrandNameQuery(baseOptions: Apollo.QueryHookOptions<GetIsDuplicateBrandNameQuery, GetIsDuplicateBrandNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIsDuplicateBrandNameQuery, GetIsDuplicateBrandNameQueryVariables>(GetIsDuplicateBrandNameDocument, options);
}
export function useGetIsDuplicateBrandNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsDuplicateBrandNameQuery, GetIsDuplicateBrandNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIsDuplicateBrandNameQuery, GetIsDuplicateBrandNameQueryVariables>(GetIsDuplicateBrandNameDocument, options);
}
export type GetIsDuplicateBrandNameQueryHookResult = ReturnType<typeof useGetIsDuplicateBrandNameQuery>;
export type GetIsDuplicateBrandNameLazyQueryHookResult = ReturnType<typeof useGetIsDuplicateBrandNameLazyQuery>;
export type GetIsDuplicateBrandNameQueryResult = Apollo.QueryResult<GetIsDuplicateBrandNameQuery, GetIsDuplicateBrandNameQueryVariables>;
export type BanksQueryVariables = Exact<{ [key: string]: never }>;

export type BanksQuery = { __typename?: 'Query'; banks: Array<{ __typename: 'Bank'; id: number; name: string }> };

export type BrandAssetsQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  brandName?: InputMaybe<Scalars['String']>;
}>;

export type BrandAssetsQuery = {
  __typename?: 'Query';
  brandAssets: Array<{ __typename?: 'Asset'; id: number; i18ns: Array<{ __typename?: 'AssetI18n'; title: string; language: Language_Code }> }>;
};

export type BrandPickQueryVariables = Exact<{ [key: string]: never }>;

export type BrandPickQuery = {
  __typename?: 'Query';
  brandPick: {
    __typename?: 'BrandPick';
    isOn: boolean;
    assets: Array<{
      __typename?: 'Asset';
      id: number;
      brand: { __typename?: 'BrandEntity'; id: number; name?: string | null; godoBrandCd?: string | null };
      i18ns: Array<{ __typename?: 'AssetI18n'; title: string; language: Language_Code }>;
    }>;
  };
};

export type BrandPromotionQueryVariables = Exact<{
  brandPromotionId: Scalars['Int'];
  isMatureContent?: InputMaybe<Scalars['Boolean']>;
}>;

export type BrandPromotionQuery = {
  __typename?: 'Query';
  brandPromotion: {
    __typename?: 'BrandPromotionOutput';
    id: number;
    promotionId: number;
    createdAt: any;
    canceledAt?: any | null;
    items: Array<{
      __typename?: 'PromotionItemOutput';
      discountValue?: number | null;
      discountType?: EPromotionDiscountType | null;
      asset?: { __typename?: 'Asset'; id: number; title: string; price: number } | null;
    }>;
    promotion: { __typename?: 'PromotionOutput'; since?: any | null; until?: any | null; title?: string | null; status?: EPromotionStatus | null };
  };
};

export type BrandPromotionsQueryVariables = Exact<{
  search?: InputMaybe<PaginatedBrandPromotionArgsSearch>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type BrandPromotionsQuery = {
  __typename?: 'Query';
  brandPromotions: {
    __typename?: 'PaginatedBrandPromotionOutput';
    data: Array<{
      __typename?: 'BrandPromotionOutput';
      id: number;
      promotionId: number;
      createdAt: any;
      canceledAt?: any | null;
      items: Array<{
        __typename?: 'PromotionItemOutput';
        id: number;
        since?: any | null;
        until?: any | null;
        registeredAt?: any | null;
        suspendedAt?: any | null;
        asset?: {
          __typename?: 'Asset';
          id: number;
          title: string;
          price: number;
          salePrice: number;
          brand: { __typename?: 'BrandEntity'; name?: string | null; id: number };
        } | null;
      }>;
      promotion: {
        __typename?: 'PromotionOutput';
        status?: EPromotionStatus | null;
        until?: any | null;
        since?: any | null;
        id: number;
        items: Array<{ __typename?: 'PromotionItemOutput'; id: number }>;
      };
    }>;
    pagination: { __typename?: 'PaginatedBrandPromotionOutputPaginationOutput'; total: number };
  };
};

export type BrandReviewsQueryVariables = Exact<{
  brandId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type BrandReviewsQuery = { __typename?: 'Query'; brandReviews: { __typename?: 'PaginatedReviewsWithCount'; count: number } };

export type CancelBrandPromotionMutationVariables = Exact<{
  input: CancelBrandPromotionInput;
}>;

export type CancelBrandPromotionMutation = { __typename?: 'Mutation'; cancelBrandPromotion: boolean };

export type CategoriesV2QueryVariables = Exact<{
  language: Language_Code;
  condition: CategoriesInput_Condition;
  sort: CategoriesInput_Sort;
}>;

export type CategoriesV2Query = {
  __typename?: 'Query';
  categoriesV2: Array<{
    __typename?: 'CategoryOutputV4';
    id: number;
    code: string;
    name: string;
    children: Array<{
      __typename?: 'CategoryOutputV4';
      id: number;
      code: string;
      name: string;
      children: Array<{ __typename?: 'CategoryOutputV4'; code: string; id: number; name: string }>;
    }>;
  }>;
};

export type ChangePasswordPartnerMutationVariables = Exact<{
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}>;

export type ChangePasswordPartnerMutation = { __typename?: 'Mutation'; changePasswordPartner: { __typename?: 'UserOutput'; id: number } };

export type CreateAdminPartnerMutationVariables = Exact<{
  password: Scalars['String'];
  account: Scalars['String'];
  name: Scalars['String'];
}>;

export type CreateAdminPartnerMutation = { __typename?: 'Mutation'; createAdminPartner: boolean };

export type CreatePartnerMutationVariables = Exact<{
  file: CreatePartnerSettleFileInput;
  brand: CreatePartnerBrandInput;
  user: CreatePartnerInput;
}>;

export type CreatePartnerMutation = { __typename?: 'Mutation'; createPartner: { __typename?: 'UserOutput'; id: number } };

export type CreatePartnerPlanQueryVariables = Exact<{
  isExclusive: Scalars['Boolean'];
  type: Scalars['Float'];
}>;

export type CreatePartnerPlanQuery = { __typename?: 'Query'; createPartnerPlan: boolean };

export type DeleteDocumentMutationVariables = Exact<{
  documentId: Scalars['String'];
}>;

export type DeleteDocumentMutation = { __typename?: 'Mutation'; deleteDocument: boolean };

export type DeleteOpenrunPromotionMutationVariables = Exact<{
  input: DeleteOpenrunPromotionInput;
}>;

export type DeleteOpenrunPromotionMutation = { __typename?: 'Mutation'; deleteOpenrunPromotion: boolean };

export type DescendantsOfRegularCategoryQueryVariables = Exact<{
  descendantsOfRegularCategoryInput: DescendantsOfRegularCategoryInput;
}>;

export type DescendantsOfRegularCategoryQuery = {
  __typename?: 'Query';
  descendantsOfRegularCategory: Array<{ __typename?: 'CategoryNode'; id: number; code: string; name: string; parent?: number | null }>;
};

export type DocumentQueryVariables = Exact<{
  documentId: Scalars['String'];
}>;

export type DocumentQuery = {
  __typename?: 'Query';
  document: {
    __typename?: 'SingleDocumentOutput';
    id: string;
    assetId?: number | null;
    brandId?: number | null;
    language?: Language_Code | null;
    status?: Document_Status | null;
    title?: string | null;
    contentHead?: string | null;
    contentBody?: string | null;
    price?: number | null;
    mainImage?: string | null;
    mainOrigin?: string | null;
    subImage?: string | null;
    subOrigin?: string | null;
    fileName?: string | null;
    filePath?: string | null;
    categoryGenre?: string | null;
    priceChanged?: any | null;
    isAdultOnly?: boolean | null;
    message?: string | null;
    extensions: Array<number>;
    applications: Array<number>;
    mainCoordination?: { __typename?: 'ImageCropCoordinationOutput'; x: number; y: number; width: number; height: number } | null;
    subCoordination?: { __typename?: 'ImageCropCoordinationOutput'; x: number; y: number; width: number; height: number } | null;
    categories: Array<{ __typename?: 'CategoryOutputV4'; code: string; children: Array<{ __typename?: 'CategoryOutputV4'; code: string }> }>;
    copyright?: {
      __typename?: 'DocumentCopyrightOutput';
      isOriginal?: boolean | null;
      warehouseSources?: string | null;
      commercialSources?: string | null;
      hasRealLogo?: string | null;
    } | null;
    promotion?: { __typename?: 'OpenrunPromotionHubOutput'; id: string } | null;
    licenseOptions?: Array<{ __typename?: 'LicenseTypeOptionOutput'; licenseTypeId: number; price: number }> | null;
    tags: Array<{ __typename?: 'TagOutput'; id: number; name: string }>;
    industries: Array<{ __typename?: 'IndustryOutput'; id: number; name: string }>;
    changeHistories?: Array<{ __typename?: 'DocumentChangeHistoryOutput'; message?: string | null; status: Document_Status } | null> | null;
    fileComponents?: Array<{ __typename?: 'DocumentFileComponentOutput'; name?: string | null; size?: number | null } | null> | null;
    book?: { __typename?: 'DocumentBookOutput'; isbn: string } | null;
  };
};

export type DocumentIndustriesQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  condition: IndustriesInput_Condition;
  sort: IndustriesInput_Sort;
}>;

export type DocumentIndustriesQuery = {
  __typename?: 'Query';
  industries: { __typename?: 'IndustryPaginatedOutput'; data: Array<{ __typename?: 'IndustryOutput'; id: number; name: string }> };
};

export type RequestDocumentReviewMutationVariables = Exact<{
  input: DocumentCommonInput;
}>;

export type RequestDocumentReviewMutation = { __typename?: 'Mutation'; request: boolean };

export type ModifyDocumentMutationVariables = Exact<{
  input: DocumentCommonInput;
}>;

export type ModifyDocumentMutation = { __typename?: 'Mutation'; modify: boolean };

export type AbortModifyDocumentMutationVariables = Exact<{
  input: DocumentCommonInput;
}>;

export type AbortModifyDocumentMutation = { __typename?: 'Mutation'; abort: boolean };

export type DocumentTagsQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  condition: TagsInput_Condition;
  sort: TagsInput_Sort;
}>;

export type DocumentTagsQuery = { __typename?: 'Query'; tags: { __typename?: 'TagPaginatedOutput'; data: Array<{ __typename?: 'TagOutput'; id: number; name: string }> } };

export type DocumentsQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['Int']>;
  createdInterval?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>> | InputMaybe<Scalars['DateTime']>>;
  requestedInterval?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>> | InputMaybe<Scalars['DateTime']>>;
  status?: InputMaybe<Array<InputMaybe<Partner_Document_Status>> | InputMaybe<Partner_Document_Status>>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type DocumentsQuery = {
  __typename?: 'Query';
  documents: Array<{
    __typename?: 'ListDocumentOutput';
    id: string;
    mainImage?: string | null;
    language: Language_Code;
    title?: string | null;
    status: Partner_Document_Status;
    created: any;
    requested?: any | null;
    assetId?: number | null;
    total?: number | null;
    isAdultOnly?: boolean | null;
  }>;
};

export type FetchPromotionQueryVariables = Exact<{
  fetchPromotionId: Scalars['Int'];
}>;

export type FetchPromotionQuery = {
  __typename?: 'Query';
  fetchPromotion: {
    __typename?: 'PromotionOutput';
    since?: any | null;
    until?: any | null;
    availability: { __typename?: 'PromotionAvailabilityOutput'; available?: number | null; current: number; total?: number | null };
  };
};

export type FetchPromotionsQueryVariables = Exact<{
  search?: InputMaybe<FetchPromotionsSearchInput>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type FetchPromotionsQuery = {
  __typename?: 'Query';
  fetchPromotions: {
    __typename?: 'PaginatedPromotionOutput';
    pagination: { __typename?: 'PaginatedPromotionOutputPaginationOutput'; page: number; limit: number; total: number };
    data: Array<{
      __typename?: 'PromotionOutput';
      id: number;
      title?: string | null;
      since?: any | null;
      until?: any | null;
      status?: EPromotionStatus | null;
      availability: { __typename?: 'PromotionAvailabilityOutput'; total?: number | null; current: number; available?: number | null };
    }>;
  };
};

export type GetAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminQuery = { __typename?: 'Query'; me: { __typename?: 'UserOutput'; account: string; name: string; type?: PartnerTypeEnum | null; typeString?: string | null } };

export type GetAllTermsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTermsQuery = { __typename?: 'Query'; getTermAll: Array<{ __typename?: 'Term'; type: string; contents: string }> };

export type GetBrandOrderProductItemsQueryVariables = Exact<{
  pagination: PaginationInput;
  condition?: InputMaybe<GetBrandOrderProductItemsInput>;
  lang: LanguageCodeEnum;
}>;

export type GetBrandOrderProductItemsQuery = {
  __typename?: 'Query';
  getBrandOrderProductItems: {
    __typename?: 'OrderProductItemPaginatedOutput';
    data: Array<{
      __typename?: 'OrderProductItemOutput';
      id: number;
      godoGoodsNo: number;
      isRefunded: boolean;
      refundedAt?: any | null;
      price: number;
      salePrice: number;
      goodsDcPrice: number;
      couponGoodsDcPrice: number;
      divisionCouponOrderDcPrice: number;
      couponGoodsBurden: number;
      couponOrderBurden: number;
      benefitBurden: number;
      benefitBurdenPrice: number;
      couponGoodsBurdenPrice: number;
      couponOrderBurdenPrice: number;
      totalBurdenPrice: number;
      isDownloaded: boolean;
      isCanceled: boolean;
      product: {
        __typename?: 'ProductOutput';
        id: number;
        title: string;
        isAdultOnly: boolean;
        priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
        imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
        dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
        promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
        brandInfo: {
          __typename?: 'BrandOutput';
          id: number;
          code?: string | null;
          godoBrandCd?: string | null;
          name: string;
          isAcon3DExclusive: boolean;
          productCount: number;
          vipInfo?: Brand_Output_Brand_Vip_Type | null;
          update?: { __typename?: 'BrandOutputUpdateOutput'; type: Brand_Output_Update_Output_Type; value?: number | null } | null;
          photoInfo?: { __typename?: 'FileOutput'; id: number; path?: string | null; fileName: string; size?: number | null } | null;
          i18ns: Array<{ __typename?: 'BrandI18nOutput'; brandId: number; languageCode: LanguageCodeEnum; name?: string | null; introduction?: string | null }>;
        };
        tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
        extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
        applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
      };
      order: {
        __typename?: 'OrderProductOutput';
        id: string;
        memNo: number;
        memId: string;
        langCode: LanguageCodeEnum;
        status: OrderStatus;
        email: string;
        name: string;
        cellPhone?: string | null;
        amount: number;
        usePoint: number;
        useAconCash: number;
        useGiftDeposit: number;
        godoOrderId: string;
        createdAt: any;
        country?: string | null;
        license: {
          __typename?: 'OrderLicenseOutput';
          id: number;
          orderNo: string;
          type: OrderLicenseType;
          created: any;
          authors: Array<{ __typename?: 'OrderLicenseAuthorOutput'; id: number; orderLicenseId: number; name: string }>;
          project: Array<{ __typename?: 'OrderLicenseProjectOutput'; id: number; orderLicenseId: number; name: string; goodsNos: Array<number> }>;
        };
        histories: Array<{ __typename?: 'OrderEventHistoryOutput'; id: number; orderId: string; godoGoodsNo?: number | null; event: OrderEventTypeEnum; createdAt: any }>;
      };
    }>;
    pagination: { __typename?: 'Pagination'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
    aggregation: { __typename?: 'OrderProductItemAggregation'; amount: number; refundedCount: number };
  };
};

export type GetBrandOrderProductItemsExcelQueryVariables = Exact<{
  lang: LanguageCodeEnum;
  condition?: InputMaybe<GetBrandOrderProductItemsInput>;
}>;

export type GetBrandOrderProductItemsExcelQuery = { __typename?: 'Query'; getBrandOrderProductItemsExcel: string };

export type GetBrandSettlementOrderExcelQueryVariables = Exact<{
  lang: LanguageCodeEnum;
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;

export type GetBrandSettlementOrderExcelQuery = { __typename?: 'Query'; getBrandSettlementOrderExcel: string };

export type GetBrandSettlementsQueryVariables = Exact<{
  pagination: PaginationInput;
}>;

export type GetBrandSettlementsQuery = {
  __typename?: 'Query';
  getBrandSettlements: {
    __typename?: 'SettlementPaginatedOutput';
    pagination: { __typename?: 'Pagination'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
    data: Array<{
      __typename?: 'SettlementOutput';
      id?: number | null;
      brandId: number;
      year: number;
      month: number;
      since: any;
      until: any;
      settleSince: any;
      settleUntil: any;
      saleCount: number;
      saleAmount: number;
      benefitBurdenAmount: number;
      couponBurdenAmount: number;
      planType: number;
      isExclusive: boolean;
      aconCommission: number;
      aconCommissionAmount: number;
      pgCommission: number;
      pgCommissionAmount: number;
      vatAmount: number;
      settlementAmount: number;
      nationalTax: number;
      nationalTaxAmount: number;
      localTax: number;
      localTaxAmount: number;
      tax: number;
      taxAmount: number;
      correctionAmount: number;
      resultAmount: number;
      exchangeRate: number;
      saleAmountForeign: number;
      totalSaleAmount: number;
      benefitBurdenAmountForeign: number;
      totalBenefitBurdenAmount: number;
      vatAmountForeign: number;
      totalVatAmount: number;
      couponBurdenAmountForeign: number;
      totalCouponBurdenAmount: number;
    }>;
  };
};

export type GetFaqQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetFaqQuery = {
  __typename?: 'Query';
  faq?: {
    __typename?: 'HubAdminPost';
    id: number;
    title: string;
    body: string;
    typeId: number;
    typeName: string;
    anchoringOrder?: number | null;
    created: any;
    next?: { __typename?: 'HubAdminPost'; id: number; title: string; typeId: number; typeName: string; created: any } | null;
    prev?: { __typename?: 'HubAdminPost'; id: number; title: string; typeId: number; typeName: string; created: any } | null;
  } | null;
};

export type GetIsEqualPasswordQueryVariables = Exact<{
  password: Scalars['String'];
}>;

export type GetIsEqualPasswordQuery = { __typename?: 'Query'; isEqualPassword: boolean };

export type GetIsUsedEmailQueryVariables = Exact<{
  account: Scalars['String'];
}>;

export type GetIsUsedEmailQuery = { __typename?: 'Query'; existsPartner: boolean };

export type GetLicenseTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLicenseTypesQuery = { __typename?: 'Query'; licenseTypes: Array<{ __typename?: 'LicenseTypeOutput'; id: number; code: string; usableCount: number }> };

export type MyBrandQueryVariables = Exact<{ [key: string]: never }>;

export type MyBrandQuery = {
  __typename?: 'Query';
  myBrand: {
    __typename?: 'BrandEntity';
    id: number;
    name?: string | null;
    godoBrandCd?: string | null;
    introduction?: string | null;
    introductionLang?: LanguageCodeEnum | null;
    modified?: any | null;
    showBrandReview: boolean;
    i18ns: Array<{ __typename?: 'BrandI18nEntity'; languageCode: LanguageCodeEnum; name?: string | null; introduction?: string | null }>;
    photo?: {
      __typename?: 'UploadFileClone';
      id?: number | null;
      azureStoragePath: string;
      fileName: string;
      size?: number | null;
      creator?: number | null;
      updater?: number | null;
    } | null;
  };
};

export type GetNoticeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetNoticeQuery = {
  __typename?: 'Query';
  notice?: {
    __typename?: 'HubAdminPost';
    id: number;
    title: string;
    body: string;
    typeId: number;
    typeName: string;
    anchoringOrder?: number | null;
    created: any;
    next?: { __typename?: 'HubAdminPost'; id: number; title: string; typeId: number; typeName: string; created: any } | null;
    prev?: { __typename?: 'HubAdminPost'; id: number; title: string; typeId: number; typeName: string; created: any } | null;
  } | null;
};

export type GetPartnerQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartnerQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserOutput';
    name: string;
    type?: PartnerTypeEnum | null;
    subType?: string | null;
    typeString?: string | null;
    subTypeString?: string | null;
    subTypeStringList: Array<string>;
    companyName?: string | null;
    account: string;
    contact?: string | null;
    contactAdditional?: string | null;
    snsUrl?: any | null;
    joinLanguage: string;
    accept: {
      __typename?: 'UserAcceptOutput';
      status: AcceptStatusEnum;
      comment?: string | null;
      modifyStatus: AcceptStatusEnum;
      modifyRejectComment?: string | null;
      modifyApproveComment?: string | null;
    };
    brand?: {
      __typename?: 'BrandOutput';
      id: number;
      name: string;
      godoBrandCd?: string | null;
      i18ns: Array<{ __typename?: 'BrandI18nOutput'; brandId: number; languageCode: LanguageCodeEnum; name?: string | null }>;
    } | null;
    reference: Array<{ __typename?: 'UserAcceptReferenceOutput'; id: number; url: string }>;
    settle?: {
      __typename?: 'UserSettleOutput';
      bankName?: string | null;
      bankAccountNumber?: string | null;
      bankAccountOwner?: string | null;
      nationalIdNumber?: string | null;
      status: PartnerSettleStatusEnum;
      bankId: number;
      country?: string | null;
      signLang?: string | null;
      bank?: { __typename?: 'BankOutput'; name: string } | null;
      bankBook?: { __typename?: 'FileOutput'; id: number; fileName: string; path?: string | null } | null;
      idCard?: { __typename?: 'FileOutput'; id: number; fileName: string; path?: string | null; size?: number | null } | null;
      limitedTaxApl?: { __typename?: 'FileOutput'; id: number; fileName: string; path?: string | null; size?: number | null } | null;
      sign?: { __typename?: 'FileOutput'; id: number } | null;
    } | null;
  };
  termAgree?: { __typename?: 'UserTermAgreeOutput'; uploadFileId?: number | null; agreeAt: any; file?: { __typename?: 'FileOutput'; path?: string | null } | null } | null;
  partnerPlan?: { __typename?: 'UserPlanOutput'; type: number; isExclusive: boolean; commissionRate: number } | null;
};

export type GetPartnerChangeQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartnerChangeQuery = {
  __typename?: 'Query';
  me: { __typename?: 'UserOutput'; account: string; contact?: string | null; brand?: { __typename?: 'BrandOutput'; name: string } | null };
};

export type GetPartnerPlanQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartnerPlanQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserOutput';
    id: number;
    name: string;
    companyName?: string | null;
    contact?: string | null;
    joinLanguage: string;
    brand?: { __typename?: 'BrandOutput'; godoBrandCd?: string | null } | null;
    settle?: { __typename?: 'UserSettleOutput'; nationalIdNumber?: string | null; imageSign?: number | null } | null;
    reference: Array<{ __typename?: 'UserAcceptReferenceOutput'; id: number; url: string }>;
  };
  termAgree?: { __typename?: 'UserTermAgreeOutput'; agreeAt: any; file?: { __typename?: 'FileOutput'; path?: string | null; id: number; fileName: string } | null } | null;
  partnerPlan?: { __typename?: 'UserPlanOutput'; userId: number; type: number; isExclusive: boolean; commissionRate: number } | null;
};

export type GetPartnerSettleQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartnerSettleQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserOutput';
    type?: PartnerTypeEnum | null;
    typeString?: string | null;
    typeStringList: Array<string>;
    subType?: string | null;
    subTypeStringList: Array<string>;
    name: string;
    companyName?: string | null;
  };
  partnerSettle: {
    __typename?: 'UserSettleOutput';
    bankName?: string | null;
    bankAccountNumber?: string | null;
    bankAccountOwner?: string | null;
    nationalIdNumber?: string | null;
    bankId: number;
    bankBook?: { __typename?: 'FileOutput'; path?: string | null; fileName: string; id: number; size?: number | null } | null;
    idCard?: { __typename?: 'FileOutput'; path?: string | null; fileName: string; id: number; size?: number | null } | null;
    limitedTaxApl?: { __typename?: 'FileOutput'; path?: string | null; fileName: string; id: number; size?: number | null } | null;
    bank?: { __typename?: 'BankOutput'; name: string } | null;
  };
};

export type GetPartnerSettleAdminQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetPartnerSettleAdminQuery = {
  __typename?: 'Query';
  partner: {
    __typename?: 'UserOutput';
    type?: PartnerTypeEnum | null;
    typeString?: string | null;
    typeStringList: Array<string>;
    subType?: string | null;
    subTypeStringList: Array<string>;
    name: string;
    companyName?: string | null;
  };
  partnerSettle: {
    __typename?: 'UserSettleOutput';
    bankName?: string | null;
    bankAccountNumber?: string | null;
    bankAccountOwner?: string | null;
    nationalIdNumber?: string | null;
    bankId: number;
    bankBook?: { __typename?: 'FileOutput'; id: number; path?: string | null } | null;
    idCard?: { __typename?: 'FileOutput'; id: number; path?: string | null; size?: number | null } | null;
    limitedTaxApl?: { __typename?: 'FileOutput'; id: number; path?: string | null; size?: number | null } | null;
    bank?: { __typename?: 'BankOutput'; name: string } | null;
  };
};

export type GetPartnerStoreQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartnerStoreQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserOutput';
    name: string;
    type?: PartnerTypeEnum | null;
    subType?: string | null;
    typeString?: string | null;
    subTypeString?: string | null;
    subTypeStringList: Array<string>;
    companyName?: string | null;
    account: string;
    contact?: string | null;
    contactAdditional?: string | null;
    snsUrl?: any | null;
    joinLanguage: string;
    brand?: {
      __typename?: 'BrandOutput';
      id: number;
      name: string;
      godoBrandCd?: string | null;
      i18ns: Array<{ __typename?: 'BrandI18nOutput'; brandId: number; languageCode: LanguageCodeEnum; name?: string | null }>;
    } | null;
    reference: Array<{ __typename?: 'UserAcceptReferenceOutput'; id: number; url: string }>;
    settle?: {
      __typename?: 'UserSettleOutput';
      bankName?: string | null;
      bankAccountNumber?: string | null;
      bankAccountOwner?: string | null;
      nationalIdNumber?: string | null;
      status: PartnerSettleStatusEnum;
      bankId: number;
      country?: string | null;
      signLang?: string | null;
      bank?: { __typename?: 'BankOutput'; name: string } | null;
      bankBook?: { __typename?: 'FileOutput'; id: number; fileName: string; path?: string | null } | null;
      idCard?: { __typename?: 'FileOutput'; id: number; fileName: string; path?: string | null; size?: number | null } | null;
      limitedTaxApl?: { __typename?: 'FileOutput'; id: number; fileName: string; path?: string | null; size?: number | null } | null;
      sign?: { __typename?: 'FileOutput'; id: number; fileName: string; path?: string | null; size?: number | null } | null;
    } | null;
  };
  termAgree?: { __typename?: 'UserTermAgreeOutput'; uploadFileId?: number | null; agreeAt: any; file?: { __typename?: 'FileOutput'; path?: string | null } | null } | null;
};

export type GetProductCardsSummaryQueryVariables = Exact<{
  brandCode?: InputMaybe<Scalars['String']>;
}>;

export type GetProductCardsSummaryQuery = {
  __typename?: 'Query';
  productCardsSummary: { __typename?: 'ProductCardsSummary'; products: Array<{ __typename?: 'Acon3dProduct'; id: number }> };
};

export type GetSasUrlQueryVariables = Exact<{
  inputs: SasUrlInput;
}>;

export type GetSasUrlQuery = { __typename?: 'Query'; sasUrl: string };

export type GetSettleQueryVariables = Exact<{
  goodsNo: Scalars['Int'];
  orderNo: Scalars['String'];
}>;

export type GetSettleQuery = {
  __typename?: 'Query';
  getSettleV2: {
    __typename?: 'SettleDetail';
    orderNo: string;
    brandCd: string;
    licenseType: string;
    licenseName: string;
    projectName?: string | null;
    orderName: string;
    goodsNm: string;
    regDt: any;
    paymentDt: any;
    settleDt: any;
    goodsPrice: number;
    goodsDcPrice: number;
    couponGoodsDcPrice: number;
    addField?: string | null;
    benefitBrandBurden?: number | null;
    brandBurden?: number | null;
    languageCode: string;
    country?: string | null;
  };
};

export type GetSettleListQueryVariables = Exact<{
  brandId: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goodsNm?: InputMaybe<Scalars['String']>;
  penNm?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
}>;

export type GetSettleListQuery = {
  __typename?: 'Query';
  getSettleCount: { __typename?: 'SettleCount'; totalCount: number; searchCount: number };
  getSettleListV2?: Array<{
    __typename?: 'Settle';
    sno: string;
    brandCd: string;
    orderNo: string;
    orderMemo: string;
    licenseType?: string | null;
    orderName: string;
    goodsNm: string;
    paymentDt: any;
    settleDt: any;
    regDt: any;
    goodsPrice: string;
    goodsDcPrice: string;
    couponGoodsDcPrice: string;
    addField: string;
    goodsNo?: number | null;
  }> | null;
};

export type HaveRegisteredPromotionQueryVariables = Exact<{
  haveRegisteredPromotionId: Scalars['Int'];
}>;

export type HaveRegisteredPromotionQuery = { __typename?: 'Query'; haveRegisteredPromotion: boolean };

export type HubDownloadQueryVariables = Exact<{
  hubDownloadId: Scalars['String'];
}>;

export type HubDownloadQuery = { __typename?: 'Query'; hubDownload: string };

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserOutput';
    brand?: {
      __typename?: 'BrandOutput';
      photoInfo?: { __typename?: 'FileOutput'; path?: string | null } | null;
      i18ns: Array<{ __typename?: 'BrandI18nOutput'; name?: string | null; languageCode: LanguageCodeEnum }>;
    } | null;
    settle?: { __typename?: 'UserSettleOutput'; country?: string | null } | null;
  };
};

export type MyAssetsQueryVariables = Exact<{
  language: Language_Code;
  title?: InputMaybe<Scalars['String']>;
  includeFree?: InputMaybe<Scalars['Boolean']>;
  includeIrregularPrice?: InputMaybe<Scalars['Boolean']>;
  isOnPromotion?: InputMaybe<Scalars['Boolean']>;
  daysAfterReleased?: InputMaybe<Scalars['Int']>;
}>;

export type MyAssetsQuery = { __typename?: 'Query'; myAssets: Array<{ __typename?: 'HubAssetOutput'; id: number; title?: string | null; price: number }> };

export type OpenrunPromotionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
}>;

export type OpenrunPromotionsQuery = {
  __typename?: 'Query';
  openrunPromotions: {
    __typename?: 'OpenrunPromotionHubPaginatedOutput';
    data: Array<{
      __typename?: 'OpenrunPromotionHubOutput';
      assetId?: number | null;
      id: string;
      assetName?: string | null;
      rejectReason?: string | null;
      since?: any | null;
      status: OpenrunStatusEnum;
      until?: any | null;
    }>;
    meta: { __typename?: 'Pagination'; currentPage: number; lastPage: number; perPage: number; totalCount: number };
    stateSpecificTotal: Array<{ __typename?: 'StateSpecificTotal'; status: OpenrunStatusEnum; total: number }>;
  };
};

export type PartnerSettleQueryVariables = Exact<{ [key: string]: never }>;

export type PartnerSettleQuery = {
  __typename?: 'Query';
  partnerSettle: {
    __typename?: 'UserSettleOutput';
    bankName?: string | null;
    bankAccountNumber?: string | null;
    bankAccountOwner?: string | null;
    bank?: { __typename?: 'BankOutput'; name: string } | null;
  };
};

export type ReadNotificationMutationVariables = Exact<{
  type?: InputMaybe<Scalars['String']>;
}>;

export type ReadNotificationMutation = { __typename?: 'Mutation'; readNotification: boolean };

export type RegisterBrandPromotionMutationVariables = Exact<{
  input: RegisterBrandPromotionInput;
}>;

export type RegisterBrandPromotionMutation = { __typename?: 'Mutation'; registerBrandPromotion: { __typename?: 'BrandPromotionOutput'; createdAt: any; canceledAt?: any | null } };

export type RegisterOpenrunPromotionMutationVariables = Exact<{
  input: RegisterOpenrunPromotionInput;
}>;

export type RegisterOpenrunPromotionMutation = {
  __typename?: 'Mutation';
  registerOpenrunPromotion: {
    __typename?: 'OpenrunPromotionHubOutput';
    assetId?: number | null;
    assetName?: string | null;
    id: string;
    rejectReason?: string | null;
    since?: any | null;
    status: OpenrunStatusEnum;
    until?: any | null;
  };
};

export type RequestPartnerRevisionMutationVariables = Exact<{
  user: RequestPartnerRevisionInput;
}>;

export type RequestPartnerRevisionMutation = { __typename?: 'Mutation'; requestPartnerRevision: boolean };

export type RequestPartnerSettleMutationVariables = Exact<{
  bankId?: InputMaybe<Scalars['Int']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  idCard?: InputMaybe<StoredFileInput>;
  bankBook?: InputMaybe<StoredFileInput>;
  limitedTaxApl?: InputMaybe<StoredFileInput>;
}>;

export type RequestPartnerSettleMutation = { __typename?: 'Mutation'; requestPartnerSettle: boolean };

export type RetryMutationVariables = Exact<{
  input: DocumentCommonInput;
}>;

export type RetryMutation = { __typename?: 'Mutation'; retry: boolean };

export type SaveDocumentMutationVariables = Exact<{
  input: SingleDocumentInput;
}>;

export type SaveDocumentMutation = { __typename?: 'Mutation'; save: boolean };

export type SendAuthenticationCodeMutationVariables = Exact<{
  email: Scalars['String'];
  lang: Scalars['String'];
}>;

export type SendAuthenticationCodeMutation = { __typename?: 'Mutation'; sendAuthenticationCode: boolean };

export type StoreBrandIntroductionMutationVariables = Exact<{
  lang: LanguageCodeEnum;
  introduction: Scalars['String'];
}>;

export type StoreBrandIntroductionMutation = {
  __typename?: 'Mutation';
  storeBrandIntroduction: Array<{ __typename?: 'BrandI18nClone'; id: number; name?: string | null; languageCode: LanguageCodeEnum; introduction?: string | null }>;
};

export type StoreBrandPhotoMutationVariables = Exact<{
  photo: ProfilePhoto;
}>;

export type StoreBrandPhotoMutation = {
  __typename?: 'Mutation';
  storeBrandPhoto: {
    __typename?: 'UploadFileClone';
    id?: number | null;
    azureStoragePath: string;
    fileName: string;
    size?: number | null;
    creator?: number | null;
    updater?: number | null;
  };
};

export type UpdateBrandPickMutationVariables = Exact<{
  assetIds: Array<Scalars['Int']> | Scalars['Int'];
  isOn: Scalars['Boolean'];
}>;

export type UpdateBrandPickMutation = {
  __typename?: 'Mutation';
  updateBrandPick: { __typename?: 'BrandClone'; id: number; name: string; brandPickIds: Array<number>; useBrandPick: boolean; godoBrandCd?: string | null };
};

export type UpdateBrandReviewMutationVariables = Exact<{
  isOn: Scalars['Boolean'];
}>;

export type UpdateBrandReviewMutation = { __typename?: 'Mutation'; updateBrandReview: { __typename?: 'BrandClone'; id: number } };

export type UploadBlobMutationVariables = Exact<{
  targetDirectory: Scalars['String'];
  temporaryFilePath: Scalars['String'];
  fileName: Scalars['String'];
}>;

export type UploadBlobMutation = { __typename?: 'Mutation'; uploadBlobStorage: { __typename?: 'UploadFile'; id?: number | null; azureStoragePath: string; fileName: string } };

export type AuthenticateMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
}>;

export type AuthenticateMutation = { __typename?: 'Mutation'; authenticate: boolean };

export type GetIsDuplicateBrandNameQueryVariables = Exact<{
  lang: Scalars['String'];
  name: Scalars['String'];
}>;

export type GetIsDuplicateBrandNameQuery = { __typename?: 'Query'; isDuplicateBrandName: boolean };
