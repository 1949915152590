import React from 'react';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { FieldsBox, FieldName, FieldValue, FieldGroup } from './styled';
import { UserFormType } from './type';
import { LanguageCodeEnum, useGetPartnerQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import countries from 'common/country/country.json';
import { PartnerTypeEnum } from 'signup/type';
import { BusinessTypes } from 'signup/constants';

type Props = UserFormType & {
  onShowChangePasswordDialog: () => void;
  onClickShowDialog: () => void;
};

export default function OriginalForm({ userId, onShowChangePasswordDialog, onClickShowDialog }: Props) {
  const { t, i18n } = useTranslation();
  const { data, loading } = useGetPartnerQuery();

  const penNameGroupKo = [
    {
      language: LanguageCodeEnum.Ko,
      label: 'korean',
    },
    {
      language: LanguageCodeEnum.En,
      label: 'english',
    },
    {
      language: LanguageCodeEnum.Zh,
      label: 'chinese',
    },
    {
      language: LanguageCodeEnum.Ja,
      label: 'japanese',
    },
  ];

  const penNameGroupOversea = [
    {
      language: LanguageCodeEnum.En,
      label: 'english',
    },
    {
      language: LanguageCodeEnum.Zh,
      label: 'chinese',
    },
    {
      language: LanguageCodeEnum.Ko,
      label: 'korean',
    },
    {
      language: LanguageCodeEnum.Ja,
      label: 'japanese',
    },
  ];

  const penNameGroup = i18n.language === LanguageCodeEnum.Ko ? penNameGroupKo : penNameGroupOversea;

  const handleShowPasswordModal = () => {
    onShowChangePasswordDialog();
  };

  if (loading || !data) {
    return <Skeleton />;
  }

  const isUser = data.me.type === PartnerTypeEnum.Personal;
  const businessSubTypeString = data.me.subTypeString ?? BusinessTypes.find((x) => x.value === data.me.subType)?.label;

  return (
    <>
      <FieldsBox>
        <FieldGroup>
          <FieldName>{t('user.email')}</FieldName>
          <FieldValue>{data.me.account}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t('user.password')}</FieldName>
          <FieldValue>
            <Button variant="text" sx={{ padding: 0 }} onClick={handleShowPasswordModal}>
              {t('user.changePassword')}
            </Button>
          </FieldValue>
        </FieldGroup>
        {i18n.language === LanguageCodeEnum.Ko && (
          <FieldGroup>
            <FieldName>{t('user.accountType')}</FieldName>
            <FieldValue>{isUser ? t('user.personal') : `사업자${businessSubTypeString ? `(${businessSubTypeString})` : ''}`}</FieldValue>
          </FieldGroup>
        )}
        {!isUser && (
          <FieldGroup>
            <FieldName>상호명</FieldName>
            <FieldValue>{data.me.companyName}</FieldValue>
          </FieldGroup>
        )}
        <FieldGroup>
          <FieldName>{isUser ? t('user.name') : '대표자명'}</FieldName>
          <FieldValue>{data.me.name}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t('user.creatorName')}</FieldName>
          <FieldValue>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="8px" width="100%">
              {penNameGroup.map((x) => (
                <Stack spacing={0.5} key={`penname_${x.label}`} width={'50%'}>
                  <Typography fontWeight={700} color="text.secondary" fontSize="14px">
                    {t(`signupPage.label.${x.label}`)}
                  </Typography>
                  <Typography variant="body2">
                    {data.me.brand &&
                      (() => {
                        if (data.me.brand.i18ns.length === 0) return '-';
                        const brandObj = data.me.brand.i18ns.find((z) => z.languageCode === x.language);
                        return brandObj?.name || '-';
                      })()}
                  </Typography>
                </Stack>
              ))}
            </Box>
          </FieldValue>
        </FieldGroup>
        {i18n.language === LanguageCodeEnum.Ko && (
          <FieldGroup>
            <FieldName>{t('user.contact')}</FieldName>
            <FieldValue>{data.me.contact ? data.me.contact : '-'}</FieldValue>
          </FieldGroup>
        )}
        <FieldGroup>
          <FieldName>{t('user.country')}</FieldName>
          <FieldValue>{countries.find((x) => x.code === data.me.settle.country).name}</FieldValue>
        </FieldGroup>
      </FieldsBox>
      <Stack alignItems={'end'} mt={1.5}>
        <Button variant="text" onClick={onClickShowDialog}>
          {t('user.editInfo')}
        </Button>
      </Stack>
    </>
  );
}
