import { Box, SelectChangeEvent } from '@mui/material';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GenreSelect from './GenreSelect';
import { GENRE_OPTIONS } from './Genre.constants';

export default function GenreRow({ disabled }: { disabled: boolean }) {
  const { t } = useTranslation();
  const { categoryGenre, setGenre } = useDocumentStore((state) => ({
    categoryGenre: state.categoryGenre,
    setGenre: state.setCategoryGenre,
  }));

  const genreOptions = GENRE_OPTIONS.map((genre) => ({ ...genre, name: t(genre.name) }));

  const handleChangeGenre = (event: SelectChangeEvent<string>) => {
    const selected = genreOptions?.find((x) => x.code === event.target.value);
    selected && setGenre([{ code: selected.code }]);
  };

  return (
    <Box mt={1.5} display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="12px" alignItems="center">
      <GenreSelect
        label={t('document.genre.setGenrePlaceholder')}
        value={categoryGenre?.[0]?.code}
        options={genreOptions}
        disabled={disabled}
        loading={false}
        onChange={handleChangeGenre}
      />
    </Box>
  );
}
