import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const PreviewAconProductModalContainer = styled(Modal)`
  .modal-dialog {
    height: 100%;
    top: auto !important;
    transform: none !important;
    max-width: 100% !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close {
    position: absolute;
    right: 25px;
    top: 20px;
  }

  .modal-header {
    width: 100%;
    padding: 0;
    border-bottom: 0;
    height: 55px;
    padding-bottom: 10px;
    overflow: hidden;
    float: left;
    .modal-title {
      font-size: 16px;
      padding: 20px;
      padding-top: 10px;
      width: calc(100% - 65px);
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .modal-content {
    width: 80vw;
    height: 80vh;
    min-height: auto;
    margin: 0 auto;
    padding: 0px;
  }
`;
