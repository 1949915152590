const REGEX_ONLY_NUMBER = /\D/g;

export function onlyNumber(str: string) {
  const number = Number(onlyNumberStr(str));

  if (isNaN(number)) throw new Error('숫자로 치환할 수 없습니다.');

  return number;
}

export function isEmail(email: string) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email);
}

export function onlyNumberStr(str: string) {
  return str.replaceAll(REGEX_ONLY_NUMBER, '');
}

export function isBusinessRrn(rrn?: string) {
  if (!rrn) {
    return false;
  }

  // 출처: https://ioerror.tistory.com/entry/%EC%9E%90%EC%A3%BC-%EC%82%AC%EC%9A%A9%ED%95%98%EB%8A%94-%EC%A0%95%EA%B7%9C%ED%91%9C%ED%98%84%EC%8B%9D-%EB%AA%A8%EC%9D%8C#6_%EC%82%AC%EC%97%85%EC%9E%90%EB%93%B1%EB%A1%9D%EB%B2%88%ED%98%B8_%EB%B2%95%EC%9D%B8%EB%93%B1%EB%A1%9D%EB%B2%88%ED%98%B8_%EC%A0%95%EA%B7%9C%ED%91%9C%ED%98%84%EC%8B%9D_7
  const regex = /([0-9]{3})-?([0-9]{2})-?([0-9]{5})/;

  return regex.test(rrn);
}
