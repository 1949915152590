export enum PartnerTypeEnum {
  Business = 'Business',
  Personal = 'Personal',
}

/** 구 사업자 유형 히스토리
 * - 0, 1은 구 유형 유지
 * - 2, 3은 삭제
 * - 4, 5는 신 유형에 추가
 * ```
 *   // 과면세 사업자
 *   TaxExempt = '2',
 *   // 간이 사업자
 *   SimpleBusiness = '3',
 * ```
 */
export enum BusinessTypeEnum {
  // 과세 사업자
  Taxable = '0',
  // 면세 사업자
  DutyFree = '1',
  // 간이과세자(세금계산서발행가능) :
  InvoiceIssuable = '4',
  // 간이과세자(세금계산서발행불가) :
  NonInvoiceable = '5',
}

export enum SignupChapterEnum {
  // 가입 유형
  UserType = 0,
  // 계정 정보
  Account = 1,
  // 파트너 정보
  Partner = 2,
  // 정산 정보
  Payment = 3,
  // 계약서 서명
  // Contract = 4,
  // Confirm
  Confirm = 5,
}

export const SignupChapters = [
  SignupChapterEnum.UserType,
  SignupChapterEnum.Account,
  SignupChapterEnum.Partner,
  SignupChapterEnum.Payment,
  // SignupChapterEnum.Contract,
  SignupChapterEnum.Confirm,
];

export type SignupChapterValid = {
  [key in SignupChapterEnum]: boolean;
};
